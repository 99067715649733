import { getUrlAPIv3 } from '../api';

const getImageProfile = async (userId: number): Promise<string> => {
    
    try {
        let url = `${getUrlAPIv3}/user/get_image_user/${userId}`;
        const response = await fetch ( url ) ;
        if (response.status === 200) {
            const data = await response.json ( );
            return data.photo;
        }
        return '';
    } catch ( error ) {
        console.log ( error ) ;
        return '' ;
    }
    
};

export { getImageProfile };