import { Box, Button, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import Cards from 'react-credit-cards-2';
import InputMask from 'react-input-mask';

import Notificacao from '../../../../../components/notification';
import { ModelCreditCard } from './modelCreditCard.model';

import 'react-credit-cards-2/dist/es/styles-compiled.css';

interface Props {
    onClose: Function,
    setData: Function,
    dados: ModelCreditCard | null,
}

interface PropsInputMask {
    mask: string,
    value: string,
    onChange: (e: any) => void,
    name: string,
    title: string,
    placeholder: string,
    width?: string,
}


const InputMaskFormatterCPF = (props: PropsInputMask) => (
    <InputMask
        mask={props.mask}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        title={props.title}
        placeholder={props.placeholder}
        width={props.width}
        style={{
            backgroundColor: '#fff',
            borderRadius: '0.2rem',
            border: '1px solid #c5c5c5',
            padding: '1.2rem',
        }}
    >
    </InputMask>
);

const ModalCreditCard = (props: Props) => {

    const [state, setState] = useState<ModelCreditCard>({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        focus: '',
        cpf: '',
    });

    useEffect(() => {
        if (props.dados !== null) {
            setState((prev) => ({ ...prev, ...props.dados }));
        }
    }, [props.dados]);

    const handleInputChange = (evt: any) => {
        const { name } = evt.target;

        try {
            let number = evt.target.value;
            let numberFormat = parseInt(number);
            let numberString = numberFormat ? numberFormat.toString() : '';
            setState((prev) => ({ ...prev, [name]: numberString }));
        } catch (error) {
            console.log('error: ', error);
        }
    }

    const handleInputFocus = (evt: any) => {
        setState((prev) => ({ ...prev, focus: evt.target.name }));
    }

    function isCreditCardNumberValid(cardNumber: string): boolean {
        // Remover espaços em branco e hifens do número do cartão
        const strippedNumber = cardNumber.replace(/[\s-]+/g, '');

        // Verificar se o número tem pelo menos 2 dígitos
        if (!/^\d{2,}$/.test(strippedNumber)) {
            return false;
        }

        // Converter o número em um array de dígitos
        const digits = strippedNumber.split('').map(Number);

        // Aplicar o algoritmo de Luhn
        let sum = 0;
        let shouldDouble = false;
        for (let i = digits.length - 1; i >= 0; i--) {
            let digit = digits[i];

            if (shouldDouble) {
                digit *= 2;
                if (digit > 9) {
                    digit -= 9;
                }
            }

            sum += digit;
            shouldDouble = !shouldDouble;
        }

        return sum % 10 === 0;
    }

    function ccvIsValid(ccv: string): boolean {
        // Remover espaços em branco e hifens do número do cartão
        const strippedNumber = ccv.replace(/[\s-]+/g, '');
        const digits = strippedNumber.replaceAll('_', '');
        // Verificar se o número tem pelo menos 2 dígitos
        if (!/^\d{3,}$/.test(digits)) {
            return false;
        }

        if (digits.length < 3) {
            return false;
        }

        return true;
    }

    function isCPFValid(cpf: string): boolean {
        // Remover espaços em branco, pontos e hifens do número do CPF
        const strippedCPF = cpf.replace(/[.\s-]+/g, '');

        // Verificar se o número tem 11 dígitos
        if (!/^\d{11}$/.test(strippedCPF)) {
            return false;
        }

        // Verificar se todos os dígitos são iguais
        if (/^(\d)\1{10}$/.test(strippedCPF)) {
            return false;
        }

        // Calcular os dígitos verificadores
        let sum = 0;
        let weight = 10;
        for (let i = 0; i < 9; i++) {
            sum += parseInt(strippedCPF.charAt(i)) * weight;
            weight--;
        }
        let firstDigit = 11 - (sum % 11);
        if (firstDigit >= 10) {
            firstDigit = 0;
        }

        sum = 0;
        weight = 11;
        for (let i = 0; i < 10; i++) {
            sum += parseInt(strippedCPF.charAt(i)) * weight;
            weight--;
        }
        let secondDigit = 11 - (sum % 11);
        if (secondDigit >= 10) {
            secondDigit = 0;
        }

        // Verificar se os dígitos verificadores são válidos
        if (parseInt(strippedCPF.charAt(9)) !== firstDigit || parseInt(strippedCPF.charAt(10)) !== secondDigit) {
            return false;
        }

        return true;
    }

    function isCreditCardDateValid(dateString: string): boolean {
        // Converter a data para um objeto Date
        const dateParts = dateString.split('/');
        const month = parseInt(dateParts[0], 10);
        const year = parseInt(dateParts[1], 10);
        const date = new Date(year, month - 1);

        // Verificar se a data é válida
        if (isNaN(date.getTime())) {
            return false;
        }

        // Comparar a data com a data atual
        const now = new Date();
        const maxDate = new Date(now.getFullYear() + 10, now.getMonth());
        if (date > maxDate) {
            return false;
        }

        if (date < now) {
            return false;
        }

        if (month < 1 || month > 12) {
            return false;
        }

        return true;
    }

    return (
        <Box sx={{
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <br />
            <Cards
                number={state.number}
                expiry={state.expiry}
                cvc={state.cvc}
                name={state.name}
                focused={undefined}
            />
            <br />
            <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '0.7rem',
            }}>
                <TextField
                    name="number"
                    placeholder="Card Number"
                    value={state.number}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    style={{
                        width: '100%',
                    }}
                />
                <Box sx={{
                    marginLeft: '0.5rem',
                }}>
                    <InputMaskFormatterCPF
                        mask='999.999.999-99'
                        name='cpf'
                        onChange={(value) => {
                            setState((prev) => ({ ...prev, cpf: value.target.value }));
                        }}
                        placeholder='CPF'
                        value={state.cpf}
                        title='CPF'
                    />
                </Box>
            </Box>
            <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '0.7rem',
            }}>
                <TextField
                    label="Nome do titular"
                    variant="outlined"
                    value={state.name}
                    sx={{
                        width: '100%',
                    }}
                    onChange={(value: any) => {
                        setState((prev) => ({ ...prev, name: value.target.value }));
                    }}
                />
                <Box sx={{
                    marginLeft: '0.5rem',
                }}>
                    <InputMaskFormatterCPF
                        mask='99/9999'
                        name='validade'
                        onChange={(value) => {
                            let newValue = value.target.value;
                            if (newValue.length === 2) {
                                newValue = newValue + '/';
                            }
                            setState((prev) => ({ ...prev, expiry: newValue }));
                        }}
                        placeholder='Data de validade'
                        value={state.expiry}
                        title='Data de validade'
                        width='100%'
                    />
                </Box>
            </Box>
            <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'start',
                textAlign: 'start',
                marginTop: '0.7rem',
            }}>
                <InputMaskFormatterCPF
                    mask='9999'
                    name='cvc'
                    onChange={(value) => {
                        setState((prev) => ({ ...prev, cvc: value.target.value }));
                    }}
                    placeholder='CVC'
                    value={state.cvc}
                    title='CVC'
                />
            </Box>
            <br />
            <Button
                variant="contained"
                sx={{
                    width: '100%',
                    marginBottom: '1rem',
                    backgroundColor: isCreditCardNumberValid(state.number) &&
                        isCPFValid(state.cpf) &&
                        ccvIsValid(state.cvc) &&
                        state.name.length >= 3 &&
                        isCreditCardDateValid(state.expiry) ? '#28b300' : '#A1A1A1',
                    color: '#fff',
                }}
                onClick={() => {
                    let valid = isCreditCardNumberValid(state.number);
                    if (!valid) {
                        Notificacao('error', 'Número do cartão inválido', 'danger');
                        return;
                    }
                    valid = isCPFValid(state.cpf);
                    if (!valid) {
                        Notificacao('error', 'CPF inválido', 'danger');
                        return;
                    }
                    valid = isCreditCardDateValid(state.expiry);
                    if (!valid) {
                        Notificacao('error', 'Data de validade inválida', 'danger');
                        return;
                    }
                    if (!ccvIsValid(state.cvc)) {
                        Notificacao('error', 'CVC inválido', 'danger');
                        return;
                    }
                    if (state.name.length < 3) {
                        Notificacao('error', 'Nome inválido', 'danger');
                        return;
                    }
                    props.setData(state);
                    props.onClose();
                }}
            >
                Continuar
            </Button>
        </Box>
    )
}

export default ModalCreditCard