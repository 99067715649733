import { getUrlAPIv2 } from '../api';
import { FormasPagamento } from '../model/fomaPagamentoEmpresa.model';

const getFormaPagamentoEmpresa = async (empresaID: number): Promise<FormasPagamento[] | null> => {
    
    let url = `${getUrlAPIv2}/empresas/infoCompanyAndPayment/${empresaID}`;
    try {
        const response = await fetch ( url ) ;
        if ( response.status === 200 ) {
            const data = await response.json ( );
            return data.data ;
        }
        return null
    } catch ( error ) {
        console.log ( error ) ;
        return null ;
    }
};

export { getFormaPagamentoEmpresa };