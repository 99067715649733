import { Box, Button, CardMedia, Typography } from "@mui/material";

import Notificacao from "../../../../../components/notification";
import { Root as Produto } from "../../../model/produto.model";

interface PropsProdutoMontado {
    produto: Produto;
    stateFunction: Function;
    empresaIsOpen: boolean;
    open: boolean;
    handleClose: Function;
    handleOpen: Function;
    setOpen: (value: boolean) => void;
    isAllDisabled: boolean;
    onMediaFallback: (event: any) => string;
    setProduto: (value: Produto) => void;
    onlyView: boolean;
    btnEscolherQuantidade: () => void;
    handleSelectItem: (value: Produto) => boolean;
    FALLBACK_IMAGE: string;
    handleCloseModal: Function;
    isOnCarrinho: boolean;
}

export function getProdutoMontado(props: PropsProdutoMontado): JSX.Element {
    return (
        <Box>
            {/* <Modal
                open={props.open}
                onClose={() => props.handleClose()}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <OpenImage
                    imagem={props.produto.foto_path === null ? props.FALLBACK_IMAGE : props.produto.foto}
                    onClose={() => props.handleClose()}
                />
            </Modal> */}
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'space-between',
                justifyContent: 'space-between',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <CardMedia
                        component="img"
                        image={
                            props.produto.foto === null ?
                                props.FALLBACK_IMAGE :
                                props.produto.foto.includes('devvokere.s3') || props.produto.foto.includes('vkadm.vokere') ?
                                    props.produto.foto :
                                    `https://devvokere.s3.us-east-2.amazonaws.com/${props.produto.foto}`
                        }
                        alt="green iguana"
                        onError={props.onMediaFallback}
                        sx={{
                            objectFit: 'contain',
                            backgroundColor: (props.produto.foto_path === null || props.produto.foto_path === '') ? 'linear-gradient(45deg, #2f3541 30%, #2f353e 90%)' : '#fff',
                            width: '80px',
                            height: '80px',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            props.setOpen(true)
                        }}
                    />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '15px',
                        justifyContent: 'start',
                        alignItems: 'start',
                    }}>
                        <Typography sx={{
                            fontWeight: 'bold',
                            fontSize: '0.9rem',
                            textAlign: 'justify',
                        }}>
                            {props.produto.nome}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: '0.8rem', textAlign: 'justify' }}>
                            {(props.produto.descricao === null || props.produto.descricao === 'null') ? `Sem descrição` : props.produto.descricao}
                        </Typography>
                    </div>
                </div>
            </div>


            <br></br>

            {
                props.isAllDisabled ?
                    <div>
                        <br></br>
                        <Typography variant="body2" sx={{ fontSize: '0.9rem', }}>
                            Produto sem especificações ativas
                        </Typography>
                    </div>
                    : props.produto.especificacoes && props.produto.especificacoes.map((item, index) => {
                        if (item.ativo === 0) {
                            return;
                        }
                        if (item.opcoes) {
                            if (item.opcoes.length > 0) {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}>
                                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                                                fontWeight: 'bold',
                                                fontSize: '0.9rem',
                                            }}>
                                                {item.nome.length < 1 ? 'Sem título' : item.nome}
                                            </Typography>
                                            <Typography id="modal-modal-title" component="h4" sx={{
                                                fontSize: '0.8rem',
                                                fontWeight: 'bold',
                                                minWidth: '90px',
                                            }}>
                                                [Min: {item.min_itens}, Max: {item.max_itens}]
                                            </Typography>
                                        </div>
                                        <Typography variant="body2" sx={{
                                            marginBottom: '30px',
                                            textAlign: 'justify',
                                        }}>
                                            {(props.produto.descricao === null || props.produto.descricao === '') ? `Sem descrição` : item.descricao}
                                        </Typography>
                                        {item.opcoes.map((opcao, indexopc) => {

                                            return (
                                                <Box
                                                    key={indexopc}
                                                    onClick={() => {
                                                        if (!props.empresaIsOpen) {
                                                            Notificacao('Atenção', 'A empresa está fechada no momento', 'danger');
                                                            return
                                                        }
                                                        let quantidadeSelecionada = 0;
                                                        const minAnMaxIsSame = item.max_itens === 1 && item.min_itens === 1;
                                                        for (let i = 0; i < item.opcoes!.length; i++) {
                                                            if (item.opcoes![i].selecionado) {
                                                                quantidadeSelecionada++;
                                                            }
                                                        }
                                                        if (quantidadeSelecionada < item.max_itens || opcao.selecionado || minAnMaxIsSame) {
                                                            const opcaoFlag = {
                                                                ...opcao,
                                                                selecionado: !opcao.selecionado,
                                                            };
                                                            const especificacaoFlag = {
                                                                ...item,
                                                                opcoes: item.opcoes!.map((item2) => {
                                                                    if (item2.id === opcao.id) {
                                                                        return opcaoFlag;
                                                                    } else {
                                                                        if (minAnMaxIsSame) {
                                                                            return {
                                                                                ...item2,
                                                                                selecionado: false,
                                                                            };
                                                                        }
                                                                    }
                                                                    return item2;
                                                                }),
                                                            };
                                                            const produtoFlag = {
                                                                ...props.produto,
                                                                especificacoes: props.produto.especificacoes!.map((item1) => {
                                                                    if (item1.id === item.id) {
                                                                        return especificacaoFlag;
                                                                    }
                                                                    return item1;
                                                                }),
                                                            };
                                                            props.setProduto(produtoFlag);
                                                        } else {
                                                            //window.alert(`Você pode selecionar no máximo ${item.max_itens} opções`);
                                                            Notificacao('Atenção', `Você pode selecionar no máximo ${item.max_itens} opções`, 'danger');
                                                        }
                                                    }}
                                                    sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        marginBottom: '8px',
                                                        paddingTop: '8px',
                                                        paddingBottom: '8px',
                                                        backgroundColor: opcao.selecionado === true ? '#e5e1e5' : '#f5f5f5',
                                                        borderLeft: `5px solid ${opcao.selecionado === true ? '#008000' : '#F9146B'}`,
                                                        '&:hover': {
                                                            backgroundColor: '#c1c1c1',
                                                        },

                                                    }}>

                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'start',
                                                        alignItems: 'start',
                                                        boxAlign: 'start',
                                                        textAlign: 'start',
                                                        marginLeft: '10px',
                                                    }}>
                                                        <Typography variant="body2"
                                                            sx={{
                                                                fontSize: '0.8rem',
                                                                fontWeight: 'bold',
                                                                justifyContent: 'start',
                                                                alignItems: 'start',
                                                                boxAlign: 'start',
                                                            }}
                                                        >
                                                            {opcao.nome}
                                                        </Typography>
                                                        <Typography variant="body2" sx={{ textAlign: 'justify', marginRight: '20px', marginTop: '5px' }}>
                                                            {opcao.descricao === null || opcao.descricao === '' ? `Sem descrição` : opcao.descricao}
                                                        </Typography>
                                                    </div>
                                                    <div style={{
                                                        padding: '5px',
                                                        backgroundColor: opcao.selecionado ? '#008000' : '#F9146B',
                                                        borderRadius: '5px',
                                                        marginRight: '10px',
                                                    }}>
                                                        <Typography variant="body2"
                                                            sx={{
                                                                fontSize: '0.9rem',
                                                                fontWeight: 'bold',
                                                                color: '#fff',
                                                            }}
                                                        >
                                                            {opcao.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                        </Typography>
                                                    </div>
                                                </Box>
                                            )
                                        })}
                                        <br></br>
                                    </div>
                                )
                            }
                        }
                    })
            }
            {
                props.isOnCarrinho ? <Button sx={{
                    marginTop: '25px',
                    padding: '10px',
                    justifyContent: 'center',
                    backgroundColor: '#F9146B',
                    alignItems: 'center',
                    borderRadius: 0,
                    width: '100%',
                    textAlign: 'center',
                    '&:hover': {
                        backgroundColor: '#008000',
                    },
                }}
                    onClick={() => {
                        if (props.isOnCarrinho) {
                            props.handleCloseModal();
                        } else {
                            window.location.href = '/login';
                        }
                    }}
                >
                    <Typography gutterBottom variant="h5" style={{
                        fontSize: '0.9rem',
                        color: '#fff',
                        textAlign: 'center',
                        justifyContent: 'center',
                    }}>
                        Fechar
                    </Typography>
                </Button> :
                    <>
                        {props.btnEscolherQuantidade()}
                        <Button sx={{
                            marginTop: '25px',
                            padding: '10px',
                            justifyContent: 'center',
                            backgroundColor: '#F9146B',
                            alignItems: 'center',
                            borderRadius: 0,
                            width: '100%',
                            textAlign: 'center',
                            '&:hover': {
                                backgroundColor: '#008000',
                            },
                        }}
                            onClick={() => {
                                if (!props.empresaIsOpen) {
                                    Notificacao('Atenção', 'A empresa está fechada no momento', 'danger');
                                }
                                const value = props.handleSelectItem(props.produto);
                                if (value) {
                                    props.handleCloseModal();
                                    props.handleClose();
                                }
                            }}
                        >
                            <Typography gutterBottom variant="h5" style={{
                                fontSize: '0.9rem',
                                color: '#fff',
                                textAlign: 'center',
                                justifyContent: 'center',
                            }}>
                                Adicionar no carrinho
                            </Typography>
                        </Button>
                    </>
            }
        </Box>
    )
}


interface PropsProdutoNaoMontado {
    produto: Produto;
    setProduto: (produto: Produto) => void;
    handleSelectItem: (produto: Produto) => boolean;
    btnEscolherQuantidade: () => JSX.Element;
    onlyView: boolean;
    empresaIsOpen: boolean;
    verifyIfBusinessProductIsInCart: (produto: Produto) => void;
    handleClose: Function;
    isOnCarrinho: boolean;
}

export function getProdutoNaoMontado(props: PropsProdutoNaoMontado): JSX.Element {
    return <>
        <div style={{
            display: 'flex',
            marginTop: '20px',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'start',
        }}>
            <CardMedia
                component="img"
                image={
                    !props.produto.foto || props.produto.foto.length < 5 || props.produto.foto === null ?
                        'https://vkadm.vokere.com/static/media/logo-horizontal-negativo.9e2f1a48.png' :
                        props.produto.foto.includes('devvokere.s3') || props.produto.foto.includes('vkadm.vokere') ?
                            props.produto.foto :
                            `https://devvokere.s3.us-east-2.amazonaws.com/${props.produto.foto}`
                }
                alt="green iguana"
                onError={() => 'https://vkadm.vokere.com/static/media/logo-horizontal-negativo.9e2f1a48.png'}
                sx={{
                    objectFit: 'scale-down',
                    backgroundColor: '#000',
                    height: "280px",
                    borderRadius: '5px',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            />
            <Typography variant="body2" sx={{
                fontSize: '17px',
                textAlign: 'justify',
                marginTop: '20px',
            }}>
                {(props.produto?.descricao === null || props.produto?.descricao === '' || props.produto?.descricao === 'null') ? `Sem descrição` : props.produto?.descricao}
            </Typography>
        </div>
        {
            props.isOnCarrinho ?
                <Button sx={{
                    marginTop: '25px',
                    padding: '10px',
                    justifyContent: 'center',
                    backgroundColor: '#F9146B',
                    alignItems: 'center',
                    borderRadius: 0,
                    width: '100%',
                    textAlign: 'center',
                    '&:hover': {
                        backgroundColor: '#008000',
                    },
                }}
                    onClick={() => {
                        if (props.isOnCarrinho) {
                            props.handleClose();
                        } else {
                            window.location.href = '/login';
                        }
                    }}
                >
                    <Typography gutterBottom variant="h5" style={{
                        fontSize: '0.9rem',
                        color: '#fff',
                        textAlign: 'center',
                        justifyContent: 'center',
                    }}>
                        Fechar
                    </Typography>
                </Button> :
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '25px',
                }}>
                    {props.btnEscolherQuantidade()}
                    <Button sx={{
                        marginTop: '25px',
                        padding: '10px',
                        justifyContent: 'center',
                        backgroundColor: '#F9146B',
                        alignItems: 'center',
                        borderRadius: 0,
                        width: '100%',
                        textAlign: 'center',
                        '&:hover': {
                            backgroundColor: '#008000',
                        },
                    }} onClick={() => {
                        if (!props.empresaIsOpen) {
                            Notificacao('Atenção', 'A empresa está fechada no momento', 'danger');
                            return
                        }
                        props.verifyIfBusinessProductIsInCart(props.produto);
                        props.handleClose();
                    }}>
                        <Typography gutterBottom variant="h5" component="div" style={{
                            fontSize: '17px',
                            textAlign: 'center',
                            justifyContent: 'center',
                            color: '#fff',
                        }}>
                            Adicionar no carrinho
                        </Typography>
                    </Button>
                </div>
        }
    </>
}