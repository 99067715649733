import { getUrlAPIv1 } from '../api';
import Cidade from '../model/cidades.model' ;

const getCities = async (): Promise<Cidade[] | null> => {
    
    try {
        let url = `${getUrlAPIv1}/cidades`;
        const response = await fetch ( url ) ;
        if ( response.status === 200 ) {
            const data = await response.json ( );
            return data.data ;
        }
        return null
    } catch ( error ) {
        console.log ( error ) ;
        return null ;
    }
    
};

export default getCities;