import { Store } from "react-notifications-component";

import "../../../node_modules/react-notifications-component/dist/theme.css";

type NotificacaoType = "success" | "danger" | "info" | "default" | "warning";

export default function Notificacao(title: string, msg: string, type: NotificacaoType) {
  return Store.addNotification({
    title: title,
    message: msg,
    type: type,
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
      showIcon: true,
      pauseOnHover: true,
    }
  });
}