//CSS
import styles from '../secao_form/style.module.css'
//MUI
import { Grid } from '@mui/material'
//REACT
import React from 'react'
import { Link } from 'react-router-dom'
//IMAGENS
const parceiro = require('../../../../assets/images/parceiro.png')

function Agradecimento() {
  return (
    <Grid
      sx={{
        justifyContent: 'space-around',
        padding: 30,
        margin: 10
      }}
    >
      <Grid>
        <img
          src={parceiro}
          alt="Parceiros apertando as mãos"
          className={styles.imagem_parceiro_agradecimento}
        />
      </Grid>
      <Grid className={styles.div_agradecimento}>
        <h1 className={styles.recebemos}>Recebemos seu formulario.</h1>
        <h2 className={styles.breve}>
          Em breve estaremos entrando em contato com você
        </h2>
      </Grid>
      <Link to="/Home">
        <Grid
          sx={{
            boxSizing: 'border-box',
            position: 'absolute',
            width: 150,
            height: 48,
            left: 831,
            top: 580,
            background: '#FFEC0D',
            borderRadius: 2
          }}
        >
          <p className={styles.redirecionar}>Voltar home.</p>
        </Grid>
      </Link>
    </Grid>
  )
}

export default Agradecimento
