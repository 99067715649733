import React from "react";
import ReactDOM from "react-dom/client";

import "./input.css";
import App from "./App";
import { CartProvider } from "./pages/empresas/context/cartcontext";
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <CartProvider>
      <Router>
        <App />
      </Router>
    </CartProvider>
  </React.StrictMode>
);
