import { Especificac, Opc, Root as ProdutoModel } from '../model/produto.model';

export function getPriceOfProduct(item: ProdutoModel): number {
    if (item.especificacoes && item.especificacoes.length > 0) {
        let preco = 0;
        for (let indexEsp = 0; indexEsp < item.especificacoes.length; indexEsp++) {
            const especificacao = item.especificacoes[indexEsp];
            for (let indexOpc = 0; indexOpc < especificacao.opcoes!.length; indexOpc++) {
                const opcao = especificacao.opcoes![indexOpc];
                if (opcao.selecionado) {
                    preco += opcao!.valor;
                }
            }
        }
        return preco;
    } else {
        return item.preco;
    }
}

const convertOptionsToJson = (options: Opc[]) => {

    let optionsFlag : any = [];

    options.map((option) => {
        let optionJson = {
            "id": option.id,
            "name": option.nome,
            "description": option.descricao,
            "value": option.valor,
            "especificacoesID": option.especificacoes_id,
            "active": option.ativo === 1 ? true : false,
            "img": option.image
          };
        optionsFlag.push(optionJson);
    });

    return optionsFlag;
}

const convertEspecificacoesToJson = (especificacoes: Especificac[]) => {

    let especificacoesFlag : any = [];

    especificacoes.map((especificacao) => {
        let especificacaoJson = {
            "id":especificacao.id,
            "nome": especificacao.nome,
            "descricao": especificacao.descricao ?? "",
            "minItens": especificacao.min_itens,
            "maxItens": especificacao.max_itens,
            "productId": especificacao.produtos_id,
            "active": especificacao.ativo,
            "options": convertOptionsToJson(especificacao.opcoes ?? [])
          };
        especificacoesFlag.push(especificacaoJson);
    });

    return especificacoesFlag;
}

export const convertProductToJson = (products: ProdutoModel[]) => {
    let produtoFlag : any = [];

    products.map((produto) => {
        let produtoJson = {
            "quantidade": produto.quantidade,
            "name": produto.nome,
            "id": produto.id,
            "rotulo": produto.rotulo,
            "descricao": produto.descricao,
            "precoTotal": getPriceOfProduct(produto),
            "preco": getPriceOfProduct(produto),
            "volume": produto.volume,
            "active": produto.ativo,
            "fabricante": produto.fabricante,
            "referencia": produto.referencia,
            "empresaID": produto.empresas_id,
            "unityMeanId": produto.unidadeMedida_id,
            "categoryProdId": produto.categoriaProdutos_id,
            "minTime": produto.tempo_min ?? "-1",
            "maxTime": produto.tempo_max ?? "-1",
            "foto": produto.foto !== null ? produto.foto.includes('vkadm.vokere.com') ? null : produto.foto : null ,
            "preco_promocional": null,
            "product_promotion_id": produto.product_promotion_id === 0 ? null : produto.product_promotion_id,
            "especificacoes": convertEspecificacoesToJson(produto.especificacoes ?? []),
            "observacao": '',
            "opcionais": '',
            "use_count": null,
            "max_use_per_user": null,
          };

          produtoFlag.push(produtoJson);
    });

    return produtoFlag;
}