import { getUrlAPIv2 } from '../api';
import { Daum as CategoriaModel } from '../model/categoria.model';

const getCategorias = async (cidade: string | undefined): Promise<CategoriaModel[] | null> => {
    
    try {
        let url = `${getUrlAPIv2}/categorias?city=${cidade}`;
        const response = await fetch ( url ) ;
        if ( response.status === 200 ) {
            const data = await response.json ( );
            return data.data ;
        }
        return null
    } catch ( error ) {
        console.log ( error ) ;
        return null ;
    }
    
};

export default getCategorias;