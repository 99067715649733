export const htmlAssuntoEmail: string = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Informações do Usuário</title>
    <style>
        body {
            font-family: 'Arial', sans-serif;
            background-color: #f4f4f4;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 100vh;
        }

        .user-info {
            background-color: #fff;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            text-align: center;
            max-width: 400px;
            width: 100%;
        }

        p {
            margin: 8px 0;
        }

        strong {
            color: #333;
        }
    </style>
</head>
<body>

    <div class="user-info">
        <h2 style="color: #333;">Informações do Usuário</h2>
        <p><strong>Nome:</strong> {{userName}}</p>
        <p><strong>E-mail:</strong> {{userEmail}}</p>
        <p><strong>Telefone:</strong> {{userTelefone}}</p>
        <p><strong>Nome da Empresa:</strong> {{userEmpresa}}</p>
        <p><strong>Nome da cidade:</strong> {{userCidade}}</p>
        <p><strong>Concordo que o Vokerê entre em contato comigo por telefone, email e whatsapp.</p>
    </div>

</body>
</html>

`