import { Box, Button, Typography } from "@mui/material"

interface Props {
    onClose: Function,
}

const ModalInfoApp = (props: Props) => {

    return (
        <Box sx={{
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Box>
                <Typography sx={{
                    textAlign: 'justify',
                    margin: '10px',
                    whiteSpace: "pre-wrap",
                }}>
                    {
                        `📱 Você está acessando o Vokerê em seu smartphone! 📱
📲 Baixe o nosso aplicativo agora e desfrute de recursos exclusivos, notificações instantâneas e muito mais! Basta clicar no botão abaixo para começar:`
                    }
                </Typography>
                <Button
                    href="https://onelink.to/6qh3rv"
                    variant="contained"
                    sx={{
                        color: '#FFF',
                        backgroundColor: ' #F9146B',
                        border: '1px solid #F9146B',
                        padding: '5px',
                        marginTop: '20px',
                        marginBottom: '15px',
                        minWidth: '250px',
                    }} onClick={() => {
                        window.open('https://onelink.to/6qh3rv');
                        props.onClose();
                    }}>
                    <Typography>
                        Baixar o aplicativo
                    </Typography>
                </Button>
            </Box>
            <Box>
                <Button
                    sx={{
                        color: '#F9146B',
                        border: '1px solid #F9146B',
                        marginBottom: '20px',
                        minWidth: '250px',
                        marginTop: '5px',
                    }} onClick={() => props.onClose()}>
                    <Typography>
                        Agora não
                    </Typography>
                </Button>
            </Box>
            <br />
        </Box>
    )
}

export default ModalInfoApp