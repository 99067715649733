import { Dialog } from '@mui/material'
import { styled } from '@mui/material/styles'

import { ContentModal } from './content'
import { FooterModal } from './footer'
import { ModalHeader } from './header'

interface Props {
    onClose: () => void;
    open: boolean;
    title: string;
    actions: React.ReactNode | null;
    children: React.ReactNode;
    isMobile: boolean;
    slug: string | null;
}

const Modal = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(1.2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}))

export function ModalCustom(props: Props) {
    return (
        <Modal 
            open={props.open} 
            onClose={props.onClose} 
            maxWidth={props.isMobile ? 'lg' : 'md'} 
            fullWidth
            sx={{
                marginRight: props.isMobile ? 0 : 2,
                marginLeft: props.isMobile ? 0 : 2,
            }}
        >
            <ModalHeader title={props.title} slug={props.slug} onClose={props.onClose} />
            <ContentModal isMobile={props.isMobile}>
                {props.children}
            </ContentModal>
            {
                props.actions ? <FooterModal>
                    {props.actions}
                </FooterModal> : null
            }
        </Modal>
    )
}