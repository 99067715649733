import { getUrlAPIv3 } from '../api';
import { Root as Banners, Product } from '../model/banners.model';
import { Root as Produto } from '../model/produto.model';

const getBanners = async (id: number): Promise<Banners[] | null> => {

    try {
        let url = `${getUrlAPIv3}/promotions/?user_id=${id}&city_id=${id}`;
        const response = await fetch(url);
        if (response.status === 200) {
            const data = await response.json();
            return data.data;
        }
        return null
    } catch (error) {
        console.log(error);
        return null;
    }

};

function getDate() {
    let data = new Date();
    let dia = String(data.getDate()).padStart(2, '0');
    let mes = String(data.getMonth() + 1).padStart(2, '0');
    let ano = data.getFullYear();
    let hour = data.getHours().toString();
    let minute = data.getMinutes().toString();
    let second = data.getSeconds().toString();
    if (hour.length < 2) {
        hour = '0' + hour
    }
    if (minute.length < 2) {
        minute = '0' + minute
    }
    if (second.length < 2) {
        second = '0' + second
    }

    return ano + '/' + mes + '/' + dia + ' ' + hour + ':' + minute + ':' + second;
}

const convertBannerProductToProduct = (prod: Product, isRetirada: boolean, promotionId: number, cidade_nome: string, slugEmpresa: string | null): Produto => {
    const produto = {
        id: prod.id,
        rotulo: prod.rotulo,
        nome: prod.rotulo,
        descricao: prod.descricao,
        quantidade: 1,
        precoByQuantidade: prod.preco,
        preco: prod.preco,
        volume: prod.volume,
        ativo: prod.ativo,
        fabricante: prod.fabricante,
        referencia: prod.referencia,
        empresas_id: prod.company_id,
        unidadeMedida_id: prod.unidademedida.id,
        unidadeMedida: prod.unidademedida,
        categoria: null,
        categoria_id: -1,
        items: [],
        especificacoes: prod.especificacoes,
        tempo_min: prod.tempo_min,
        tempo_max: prod.tempo_max,
        created_at: prod.created_at,
        updated_at: prod.updated_at,
        pivot: null,
        preco_format: '',
        orgdesc: '',
        foto: prod.image,
        foto_path: prod.image_path,
        order: 0,
        is_sensible: 0,
        product_promotion_id: promotionId,
        id_promocao: promotionId,
        observacao: null,
        opcionais: null,
        isRetirada: isRetirada,
        preco_promocional: prod.preco,
        preco_anterior: prod.preco_anterior,
        max_use: prod.max_use_per_user,
        cidade_nome: cidade_nome,
        hora_atual: getDate(),
        slugEmpresa: slugEmpresa,
        slug: '',
        categoriaProdutos_id: -1
    } as Produto;

    return produto;
}

export { getBanners, convertBannerProductToProduct };