import { getUrlAPIv1 } from '../api';
import { Root as EmpresaModel } from '../model/empresas.model';

type Empresa = [EmpresaModel[], number[]]

const getBusiness = async (id: Number): Promise<Empresa | null> => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ cidade_id: id })
    };
    let url = `${getUrlAPIv1}/empresas/cidade`;
    
    try {
        const response = await fetch (url , requestOptions ) ;
        if (response.status === 200) {
            const data = await response.json ( );
            let ids = <number[]>[]
            data.data.map((item: EmpresaModel) => {
                if (item.aberta === 1){
                    ids.push(item.id)
                }
            })
            return [data.data, ids] ;
        }
            return null
    } catch ( error ) {
        console.log ( error ) ;
        return null ;
    }
    
};

export default getBusiness;