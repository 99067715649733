import imagemCelulares from "../../assets/images/imgcllrs.png";
import mulherComCelular from "../../assets/images/mulher_com_celular.png";
import ondulatura from "../../assets/images/Vector.png";
import googlePlay from "../../assets/images/vecteezy_badge-google-play-and-app-store-button-download_24237966-removebg-preview.png";
import applestore from "../../assets/images/googleplay-removebg-preview.png";
import vokereImage from "../../assets/images/vokere imagem.jpg";
import mulherNegocio from "../../assets/images/copy-space-woman-with-arms-crossed.png";
import {
  ContainedButton,
  DisabledButton,
  OutlinedButton,
} from "../../components/V2/button";
import { motion, useInView } from "framer-motion";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

const Home = () => {
  const [downloads, setDownloads] = useState(0);
  const [cities, setCities] = useState(0);
  const [orders, setOrders] = useState(0);

  const section1Ref = useRef(null);
  const section1InView = useInView(section1Ref);

  const section2Ref = useRef(null);
  const section2InView = useInView(section2Ref);

  const section3Ref = useRef(null);
  const section3InView = useInView(section3Ref);

  const section4Ref = useRef(null);
  const section4InView = useInView(section4Ref);

  const section5Ref = useRef(null);
  const section5InView = useInView(section5Ref);

  const animateNumbers = (
    setState: Dispatch<SetStateAction<number>>,
    target: number,
    increment: number = 1
  ) => {
    let start = 0;
    const duration = 1000;
    const stepTime = Math.abs(Math.floor(duration / target));

    const animate = () => {
      start += increment;
      setState(start);
      if (start < target) {
        setTimeout(animate, stepTime);
      }
    };

    animate();
  };

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        animateNumbers(setDownloads, 100);
        animateNumbers(setCities, 13);
        animateNumbers(setOrders, 1000, 5);
      }
    });

    if (section2Ref.current) {
      observer.observe(section2Ref.current);
    }

    return () => {
      if (section2Ref.current) {
        observer.unobserve(section2Ref.current);
      }
    };
  }, [section2Ref]);
  
  return (
    <>
      <section ref={section1Ref} className="max-h-[100vh]  bg-[#F7E64E]">
        <div className="flex flex-col lg:flex-row items-center justify-between mx-auto p-4 max-w-screen-xl ">
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            animate={section1InView ? { x: 0, opacity: 1 } : {}}
            transition={{ duration: 1 }}
            className="flex flex-col justify-center w-[90%] lg:w-[60%] mt-6 lg:mt-0 lg:pr-[10%]">
            <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-[#262626] md:text-5xl lg:text-5xl ">
              O que você quiser,
              <br />
              <span>Onde você estiver!</span>
            </h1>
            <p className="text-lg font-normal text-[#403F3F] lg:text-xl">
              Somos um aplicativo de delivery desenvolvido com propósito de
              facilitar a vida de todos
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={section1InView ? { opacity: 1, scale: 1 } : {}}
            transition={{ duration: 1 }}
            className="w-[70%] lg:w-[40%]">
            <img src={imagemCelulares} alt="" className="p-5" />
          </motion.div>
        </div>
      </section>

      <section
        ref={section2Ref}
        className="relative flex flex-col gap-10 lg:gap-0 items-center">
        <img src={ondulatura} alt="" className="w-full object-fill" />
        <div className="lg:absolute lg:top-1/2 lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2 w-4/5 max-w-[1317.59px] py-10 rounded-3xl border-8 border-[#F7E64E] bg-white">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col flex-1 gap-10 lg:gap-0 lg:flex-row lg:justify-between">
              <div className="w-full lg:w-1/3 border-b pb-10 lg:border-b-0 lg:pb-0 lg:border-r border-[#968989]">
                <div className="font-manrope font-bold text-5xl text-gray-900 mb-5 text-center">
                  +{downloads}
                  <span className="text-[#F9146B]">K</span>
                </div>
                <span className="text-xl text-gray-500 text-center block">
                  Downloads
                </span>
              </div>

              <div className="w-full lg:w-1/3 border-b pb-10 lg:border-b-0 lg:pb-0 lg:border-r border-[#968989]">
                <div className="font-manrope font-bold text-5xl text-gray-900 mb-5 text-center">
                  {cities}
                </div>
                <span className="text-xl text-gray-500 text-center block">
                  Cidades
                </span>
              </div>

              <div className="w-full lg:w-1/3 pb-10 lg:pb-0">
                <div className="font-manrope font-bold text-5xl text-gray-900 mb-5 text-center">
                  +{orders}
                  <span className="text-[#F9146B]">K</span>
                </div>
                <span className="text-xl text-gray-500 text-center block">
                  Pedidos por Dia
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        ref={section3Ref}
        className="flex flex-row  md:max-h-screen lg:h-[700px] mt-14">
        <div className="flex flex-col-reverse gap-10 lg:gap-3 lg:flex-row mx-auto max-w-screen-xl">
          <div className="flex flex-col lg:justify-end lg:w-[50%]">
            <motion.img
              src={mulherComCelular}
              alt=""
              className="w-[60%] lg:w-[80%] align-bottom self-center"
              initial={{ opacity: 0, x: -50 }}
              animate={section3InView ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 1 }}
            />
          </div>
          <div className="flex justify-center items-center">
            <motion.div
              className="flex flex-col justify-center items-center w-[90%] p-4 h-[400px] bg-[#F3F4F6] rounded-2xl gap-5"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={section3InView ? { opacity: 1, scale: 1 } : {}}
              transition={{ duration: 1 }}>
              <h1 className="text-3xl xl:text-4xl text-center font-extrabold text-[#111928]">
                Baixe Nosso Aplicativo
              </h1>
              <p className="text-lg xl:text-xl text-center text-[#637381]">
                Baixe agora nosso aplicativo e faça seu pedido! Disponível para
                smartphones Android e iOS.
              </p>
              <div className="flex flex-col md:flex-row gap-4">
                <a
                  href="https://play.google.com/store/apps/details?id=br.com.hollic.vokere&hl=pt_BR&gl=US"
                  target="_blank"
                  rel="noreferrer">
                  <img src={googlePlay} alt="" className="w-48" />
                </a>
                <a
                  href="https://apps.apple.com/br/app/voker%C3%AA/id1476149761"
                  target="_blank"
                  rel="noreferrer">
                  <img src={applestore} alt="" className="w-48" />
                </a>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      <section ref={section4Ref} className="bg-[#F7E64E]">
        <div className="flex flex-row h-[400px] m-0 mx-auto max-w-screen-2xl">
          <div className="flex flex-col justify-center p-10">
            <motion.span
              className="mb-4 text-lg font-semibold text-[#F9146B]"
              initial={{ opacity: 0, y: -20 }}
              animate={section4InView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1 }}>
              Trabalhe Conosco
            </motion.span>
            <motion.h2
              className="text-4xl font-extrabold mb-4 text-[#0F172A]"
              initial={{ opacity: 0, y: -20 }}
              animate={section4InView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1, delay: 0.1 }}>
              Faça Parte do Nosso Time
            </motion.h2>
            <motion.p
              className="mb-6 mr-1/2 text-[#403F3F]"
              initial={{ opacity: 0, y: -20 }}
              animate={section4InView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1, delay: 0.2 }}>
              Contamos com uma equipe altamente capacitada para exercer com
              excelência cada função, venha fazer parte desta equipe.
            </motion.p>
            <motion.div
              className="flex flex-col lg:flex-row gap-4"
              initial={{ opacity: 0, y: 20 }}
              animate={section4InView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1, delay: 0.3 }}>
              <ContainedButton content="Entregador" to="/entregador" />
              <OutlinedButton content="Time Vokerê" to="/trabalhe-conosco" />
            </motion.div>
          </div>
          <div className="hidden lg:flex justify-end w-full h-full">
            <motion.img
              src={vokereImage}
              alt="Vokerê"
              className="h-[400px] min-w-full object-cover clip-content brightness-75"
              initial={{ opacity: 0 }}
              animate={section4InView ? { opacity: 1 } : {}}
              transition={{ duration: 0.5, delay: 0.3 }}
            />
          </div>
        </div>
      </section>

      <section
        ref={section5Ref}
        className="flex items-center justify-center my-10 lg:my-0 h-[800px]">
        <div className="flex flex-col-reverse lg:flex-row gap-10 lg:gap-0 items-center lg:mx-auto max-w-screen-xl">
          <div className="flex lg:justify-center lg:w-1/2">
            <motion.div
              className="flex justify-center items-end w-[300px] h-[350px] md:w-[600px] md:h-[450px] lg:w-[461.21px] lg:h-[508.17px] bg-gradient-to-br from-[#f7e64e] to-[#f1de01] rounded-[20px]"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={section5InView ? { opacity: 1, scale: 1 } : {}}
              transition={{ duration: 1 }}>
              <img src={mulherNegocio} alt="" className="h-[90%]" />
            </motion.div>
          </div>

          <div className="flex flex-col justify-center px-4 lg:w-1/2">
            <motion.div
              className="mt-10 lg:mt-0"
              initial={{ opacity: 0, y: -20 }}
              animate={section5InView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1, delay: 0.2 }}>
              <span className="mb-4 text-lg font-semibold text-[#E5CC2C]">
                Parcerias & Franquias
              </span>
              <h2 className="mb-5 text-3xl font-extrabold text-[#0F172A] sm:text-[40px]/[48px]">
                Juntos Somos Mais Fortes!
              </h2>
              <p className="mb-5 text-[#637381] text-body-color dark:text-dark-6">
                Junte-se à nossa rede de sucesso no mercado de delivery!
                Oferecemos oportunidades de parcerias e franquias para
                empreendedores que desejam crescer ao lado de uma marca sólida e
                inovadora.
              </p>
            </motion.div>
            <motion.div
              className="flex flex-col lg:flex-row w-full lg:items-center gap-4"
              initial={{ opacity: 0, y: 20 }}
              animate={section5InView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 1, delay: 0.3 }}>
              <ContainedButton content="Seja nosso Parceiro" to="/parceiro" />
              <DisabledButton content="Seja um Franqueado" />
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Home;
