import { getUrlAPIv1 } from '../api';
import { Root as Empresas } from '../model/empresas.model';

const getEmpresasByCategoria = async (cidade: string | undefined, idCategoria: number): Promise<Empresas[] | null> => {
    
    try {
        let url = `${getUrlAPIv1}/empresas/byCategoria/${idCategoria}/${cidade}`;
        const response = await fetch ( url ) ;
        if ( response.status === 200 ) {
            const data = await response.json ( );
            return data.data ;
        }
        return null
    } catch ( error ) {
        console.log ( error ) ;
        return null ;
    }
    
};

export default getEmpresasByCategoria;