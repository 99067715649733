import LinkIcon from '@mui/icons-material/Link';
import { Box, Button, Typography } from "@mui/material"
import { QRCodeSVG } from 'qrcode.react';
import { useState } from "react";
import CopyToClipboard from 'react-copy-to-clipboard';

import Notificacao from '../../../../../components/notification';

interface Props {
    onClose: Function,
    onReload: Function,
    qrcode: string,
}

const ModalMakePix = (props: Props) => {

    const [jaRealizeiOPagamento, setJaRealizeiOPagamento] = useState(false);

    return (
        <Box sx={{
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        }}>
            {
                jaRealizeiOPagamento ? <Box>
                    <Typography sx={{
                        textAlign: 'justify',
                        margin: '10px',
                        whiteSpace: "pre-wrap",
                    }}>
                        {
                            `Prezado Cliente,

Gostaríamos de informar que em nosso aplicativo disponibilizamos a opção de pagamento via pix, que oferece rapidez e segurança na hora de realizar suas transações financeiras. No entanto, é importante lembrar que em algumas situações, pode ocorrer um atraso no reconhecimento do pagamento pelo sistema de pagamentos, mesmo que a transação tenha sido realizada com sucesso.

Caso você tenha realizado um pagamento via pix e esteja enfrentando um atraso no reconhecimento da transação, pedimos que aguarde alguns minutos antes de entrar em contato com nossa equipe de suporte. Em muitos casos, a transação será reconhecida automaticamente pelo sistema em alguns minutos após a realização do pagamento.

Caso a transação ainda não seja reconhecida após esse período, recomendamos que entre em contato conosco para que possamos verificar e solucionar o problema o mais rápido possível.`
                        }
                    </Typography>
                    <Button sx={{
                        color: '#F9146B',
                        border: '1px solid #F9146B',
                        padding: '5px',
                        marginTop: '20px',
                        marginBottom: '15px',
                        width: '80%',
                    }} onClick={() => {
                        props.onReload();
                        props.onClose();
                    }}>
                        <Typography>
                            OK
                        </Typography>
                    </Button>
                </Box> :
                    <Box>
                        <QRCodeSVG value={props.qrcode} size={250} />
                        <CopyToClipboard text={props.qrcode} onCopy={() => {
                            Notificacao('Sucesso!', `Código pix copiado para área de transferência!`, 'success');
                        }}>
                            <Button sx={{
                                color: '#F9146B',
                                border: '1px solid #F9146B',
                                marginTop: '20px',
                                marginBottom: '15px',
                                width: '100%',
                            }}>
                                <Typography>
                                    Copiar código
                                </Typography>
                                <LinkIcon sx={{
                                    marginLeft: '10px',
                                }} />
                            </Button>
                        </CopyToClipboard>
                        <Button sx={{
                            color: '#F9146B',
                            border: '1px solid #F9146B',
                            marginBottom: '20px',
                            width: '100%',
                        }} onClick={() => {
                            setJaRealizeiOPagamento(true);
                        }}>
                            <Typography>
                                Já realizei o pagamento
                            </Typography>
                        </Button>
                    </Box>
            }
        </Box>
    )
}

export default ModalMakePix