import { DialogActions } from "@mui/material";

interface Props {
    children: React.ReactNode;
}

export function FooterModal(props: Props) {
    return (
        <DialogActions>
            {props.children}
        </DialogActions>
    )
}