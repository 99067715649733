import ModalInfoApp from './modal/infoOpenInApp/info'
import { Box, Button, CardMedia, CircularProgress, Container, CssBaseline, Grid, Link, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom'

import { ModalCustom } from '../../../components/modal';
import Notificacao from '../../../components/notification';
import styles from '../../sobre/style.module.css'
import { makeLogin } from '../controllers/makeLogin'
import { EnderecoCliente } from '../model/endereco.model';
import { Root as UserModel } from '../model/user.model'
import FALLBACK_IMAGE from '../onerror.png';
import ModalEnderecos from './modal/detalhesEndereco/modalEnderecos';

const Login = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [openModalEnderecos, setOpenModalEnderecos] = useState(false)

    const handleOpenModalEnderecos = () => setOpenModalEnderecos(true);
    const handleCloseModalEnderecos = () => setOpenModalEnderecos(false);
    const [handleOnReload, setHandleOnReload] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [entityId, setEntityId] = useState(-1);
    const [token, setToken] = useState('');
    const [openModalInfoApp, setOpenModalInfoApp] = useState(false);
    const handleCloseModalInfoApp = () => setOpenModalInfoApp(false);

    async function verifyIfUserIsLogged() {
        setIsLoading(true)
        try {
            await localStorage.setItem('infoProfile', '{}')
            const user = JSON.parse(localStorage.getItem('user') || '{}') as UserModel
            const address = JSON.parse(localStorage.getItem('address_user') || '{}') as EnderecoCliente
            if (user && user.user.entity_id && address && address.id) {
                const url = `/empresas/cidade/${address.cidades_id}/${address.cidade}`
                window.location.href = url
            }
        } catch (error) { }
        await setTimeout(function () {
            setIsLoading(false)
        }, 1000);
    }

    useEffect(() => {
        verifyIfUserIsLogged()
    }, [
        openModalEnderecos,
    ])

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/.test(userAgent);

        if (isMobile) {
            setOpenModalInfoApp(true);
        }
    }, []);


    return (
        <Container component="main" maxWidth="xs">
            {
                isLoading ? <Box>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginTop: '120px',
                    }}>
                        <CircularProgress sx={{
                            color: '#F9146B',
                        }} />
                        <br></br>
                        <Typography sx={{
                            fontSize: '20px',
                            marginTop: '30px',
                        }}>
                            Carregando...
                        </Typography>
                    </div>
                </Box> : <>
                    <CssBaseline />
                    <br></br>
                    <br></br>
                    <br></br>
                    <ModalCustom
                        open={openModalInfoApp}
                        title='Atenção'
                        onClose={handleCloseModalInfoApp}
                        actions={null}
                        isMobile={true}
                        slug={''}
                    >
                        <ModalInfoApp onClose={handleCloseModalInfoApp} />
                    </ModalCustom>
                    <ModalCustom
                        open={openModalEnderecos}
                        title='Endereços'
                        onClose={handleCloseModalEnderecos}
                        actions={null}
                        isMobile={true}
                        slug={''}
                    >
                        <ModalEnderecos
                            onClose={handleCloseModalEnderecos}
                            onReload={() => setHandleOnReload(!handleOnReload)}
                            entity_id={entityId}
                            token={token}
                        />
                    </ModalCustom>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <CardMedia>
                            <img
                                src={FALLBACK_IMAGE}
                                alt="Logo"
                                className={styles.logo}
                                height="100"
                            />
                        </CardMedia>
                        <br></br>
                        <Box>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Endereço de email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={(e) => { setEmail(e.target.value) }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Senha"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={(e) => { setPassword(e.target.value) }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={async () => {
                                    const login = await makeLogin(email, password)
                                    if (login) {
                                        const user = login as UserModel;
                                        setEntityId(user.user.entity_id);
                                        setToken(user.token);
                                        localStorage.setItem('user', JSON.stringify(user))
                                        Notificacao('Sucesso!', `Login realizado com sucesso!`, 'success');
                                        setOpenModalEnderecos(true);
                                        handleOpenModalEnderecos();
                                    }
                                }}
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    backgroundColor: '#F9146B',
                                    '&:hover': { backgroundColor: '#ff4a8f' },
                                }}
                            >
                                Entrar
                            </Button>
                            <Grid container>
                                <Grid item>
                                    <Link href="/criar-conta" variant="body2">
                                        {"Não tem uma conta? Crie uma aqui"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <br></br>
                    <div>
                        <ReactRouterLink to="/empresas/">
                            <Button sx={{
                                padding: '10px',
                                border: '1px solid #F9146B',
                                color: '#F9146B',
                                width: '100%',
                            }}>
                                Entrar como convidado
                            </Button>
                        </ReactRouterLink>
                    </div>
                    <br></br>
                    <br></br>
                </>
            }
        </Container>
    )
}

export default Login
