import parse from 'html-react-parser';

export default function Page() {

  let html = `
  <html>

  <head>
  <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
  <meta name=Generator content="Microsoft Word 15 (filtered)">
  <style>
  <!--
   /* Font Definitions */
   @font-face
    {font-family:"Cambria Math";
    panose-1:2 4 5 3 5 4 6 3 2 4;}
  @font-face
    {font-family:"Segoe UI";
    panose-1:2 11 5 2 4 2 4 2 2 3;}
   /* Style Definitions */
   p.MsoNormal, li.MsoNormal, div.MsoNormal
    {margin:0in;
    line-height:115%;
    font-size:11.0pt;
    font-family:"Arial",sans-serif;}
  h1
    {margin-top:20.0pt;
    margin-right:0in;
    margin-bottom:6.0pt;
    margin-left:0in;
    line-height:115%;
    page-break-after:avoid;
    font-size:20.0pt;
    font-family:"Arial",sans-serif;
    font-weight:normal;}
  .MsoChpDefault
    {font-family:"Arial",sans-serif;}
  .MsoPapDefault
    {line-height:115%;}
  @page WordSection1
    {size:595.45pt 841.7pt;
    margin:1.0in 1.0in 1.0in 1.0in;}
  div.WordSection1
    {page:WordSection1;}
   /* List Definitions */
   ol
    {margin-bottom:0in;}
  ul
    {margin-bottom:0in;}
  -->
  </style>
  
  </head>
  
  <body lang=PT-BR style='word-wrap:break-word'>
  
  <div class=WordSection1>
  
  <p class=MsoNormal align=center style='margin-top:12.0pt;margin-right:0in;
  margin-bottom:12.0pt;margin-left:0in;text-align:center;line-height:150%'><b><u><span
  style='font-size:14.0pt;line-height:150%;font-family:"Times New Roman",serif'>POLÍTICA
  DE PRIVACIDADE </span></u></b></p>
  
  <p class=MsoNormal align=center style='margin-top:12.0pt;margin-right:0in;
  margin-bottom:12.0pt;margin-left:0in;text-align:center;line-height:150%'><b><u><span
  style='font-size:14.0pt;line-height:150%;font-family:"Times New Roman",serif'>Plataforma
  VOKERÊ</span></u></b></p>
  
  <h1 style='margin-top:10.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
  .5in;text-indent:-.25in;line-height:150%'><a name="_4jbuxrwwdpxt"></a><b><span
  style='font-size:11.0pt;line-height:150%'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
  </span></span></b><b><span style='font-size:11.0pt;line-height:150%;font-family:
  "Times New Roman",serif'>INTRODUÇÃO</span></b></h1>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  10.0pt;margin-left:0in;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Por
  meio desta Política de Privacidade, demonstramos nosso compromisso com a
  proteção da sua privacidade ao contratar nossos serviços e ao usar nossa
  PLATAFORMA online, formada por nosso aplicativo. A partir do acesso ao
  aplicativo VOKERÊ, você nos dá o direito de uso sobre seus dados e informações
  compartilhadas. O armazenamento e TRATAMENTO desses, em nossos sistemas, é
  essencial para que sua experiência em nossa PLATAFORMA online seja
  personalizada.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  10.0pt;margin-left:0in;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>No
  presente documento, explicamos de maneira acessível como seus dados serão
  coletados, usados, compartilhados e armazenados no sistema do aplicativo.
  Portanto, a aceitação da Política será efetuada ao acessar nosso aplicativo. A
  partir disso, você confirma ciência sobre o TRATAMENTO dos dados e das
  informações fornecidas.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  10.0pt;margin-left:0in;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Dessa
  forma, é importante compreender os seguintes conceitos:</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:0in;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif;
  background:white'>CONSENTIMENTO </span></b><span style='font-size:12.0pt;
  line-height:150%;font-family:"Times New Roman",serif;background:white'>É a
  manifestação livre, informada e inequívoca pela qual o USUÁRIO concorda com a
  utilização dos seus DADOS PESSOAIS ou anonimizados.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:0in;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif;
  background:white'>CONTEÚDO</span></b><span style='font-size:12.0pt;line-height:
  150%;font-family:"Times New Roman",serif;background:white'> qualquer
  informação, dados, comunicações, software, fotos, vídeos, gráficos, música,
  sons e outros materiais e serviços que podem ser visualizados pelos USUÁRIOS na
  PLATAFORMA. Isso inclui mensagens, conversas, bate-papo e outros CONTEÚDOS
  originais.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:0in;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>DADOS
  PESSOAIS </span></b><span style='font-size:12.0pt;line-height:150%;font-family:
  "Times New Roman",serif'>são<b> </b>informações relacionadas à pessoa natural
  identificada ou identificável.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:0in;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>DADOS
  PESSOAIS SENSÍVEIS</span></b><span style='font-size:12.0pt;line-height:150%;
  font-family:"Times New Roman",serif'> são DADOS PESSOAIS sobre origem racial ou
  étnica, convicção religiosa, opinião política, filiação a sindicato ou a
  organização de caráter religioso, filosófico ou político, dado referente à
  saúde ou à vida sexual, dado genético ou biométrico, quando vinculados a uma
  pessoa natural.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:0in;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>PLATAFORMA</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'> 
  <span style='background:white'>aplicativo web e móvel administrado e com os
  direitos exclusivos de utilização no Brasil pela VOKERÊ, através do qual i) são
  exibidos diferentes produtos de consumo de forma publicitária (dos Prestadores
  de Serviço) para que possam servir de referência ao Consumidor; ii) facilita a
  aproximação entre os Consumidores e os Prestadores de Serviço; iii) serve como
  meio de envio de comunicações entre o Consumidor e a VOKERÊ; iv) se aplicável,
  oferta Cartão e Conta ao Consumidor, por meio dos quais este pode movimentar
  recursos financeiros e realizar transações de pagamento; e v) pode receber os
  pagamentos das compras diretamente do consumidor (por conta e ordem de
  terceiros), com repasse do dinheiro ao Prestador de Serviço.  A VOKERÊ não
  realiza a compra de produtos por conta própria, não armazena produtos e
  tampouco é vendedora de produtos. É uma </span>PLATAFORMA<span
  style='background:white'> de tecnologia somente para intermediação da Venda,
  cobrando pelos serviços realizados<b>. </b>Também denominada neste Instrumento
  como “PLATAFORMA”<b>, </b>podendo ser acessada por <i>smartphones</i> ou <i>tablets.</i></span></span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:0in;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif;
  background:white'>SERVIÇO ou SERVIÇOS:</span></b><span style='font-size:12.0pt;
  line-height:150%;font-family:"Times New Roman",serif;background:white'>
  Serviços oferecidos por meio da PLATAFORMA.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:0in;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>TERMOS
  DE USO</span></b><span style='font-size:12.0pt;line-height:150%;font-family:
  "Times New Roman",serif'> <span style='background:white'>Constituem os termos
  legais que regulamentam a relação entre a VOKERÊ e os USUÁRIOS. </span></span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:0in;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif;
  background:white'>TRATAMENTO</span></b><span style='font-size:12.0pt;
  line-height:150%;font-family:"Times New Roman",serif;background:white'> é
  qualquer tipo de atividade baseada em DADOS PESSOAIS, incluindo, por exemplo,
  as seguintes atividades: coleta, registro, armazenamento, organização consulta,
  uso, divulgação, compartilhamento, transmissão, classificação, reprodução,
  processamento e avaliação.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  10.0pt;margin-left:0in;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif;
  background:white'>USUÁRIO DA PLATAFORMA VOKERÊ</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif;
  background:white'>: são as pessoas físicas cadastradas na </span><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>PLATAFORMA<span
  style='background:white'> de Internet denominada <b>VOKERÊ </b>também
  denominados <b>“USUÁRIO”</b>,<b> “USUÁRIOS” ou “VOCÊ”.</b></span></span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  10.0pt;margin-left:0in;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif;
  background:white'>VOKERÊ </span></b><span style='font-size:12.0pt;line-height:
  150%;font-family:"Times New Roman",serif;background:white'>é a empresa
  proprietária e possuidora de todos os direitos autorais referente à </span><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>PLATAFORMA<span
  style='background:white'> de Internet denominada VOKERÊ.</span></span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:0in;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Em
  caso de dúvidas sobre o presente documento, entre em contato com nossa equipe
  pelo endereço de e-mail: faleconosco@vokere.com</span></p>
  
  <h1 style='margin-top:10.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
  .5in;text-align:justify;text-indent:-.25in;line-height:150%'><b><span
  style='font-size:11.0pt;line-height:150%'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
  </span></span></b><b><span style='font-size:12.0pt;line-height:150%;font-family:
  "Times New Roman",serif'>COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO</span></b></h1>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  10.0pt;margin-left:0in;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>No
  intuito de realizar os objetivos já determinados por este documento, nós
  coletamos os seus dados a partir de informações:</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>2.1.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Fornecidas
  espontaneamente por você ao registrar-se e ao inserir seus <span
  style='background:white'>DADOS PESSOAIS</span> durante o uso de nosso
  aplicativo.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>2.2.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Coletadas
  pela maneira como você interage com nossa PLATAFORMA ao utilizar o aplicativo.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>2.3.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Providas
  pelo aparelho ou dispositivo que você dispuser para acessar e navegar pela
  PLATAFORMA, bem como pela sua localização no momento em que acessar nosso
  aplicativo.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>2.4.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Disponibilizadas
  em suas redes sociais, caso o contato estabelecido seja feito por meio de
  alguma rede ou plataforma exterior à nossa.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>2.5.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Informadas
  em momentos de contato com nossa PLATAFORMA e demais canais de comunicação.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>2.6.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Oriundas
  de outras plataformas que você tenha estabelecido contato e fornecido
  informações.</span></p>
  
  <p class=MsoNormal>&nbsp;</p>
  
  <h1 style='margin-top:10.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
  .5in;text-align:justify;text-indent:-.25in;line-height:150%'><b><span
  style='font-size:11.0pt;line-height:150%'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
  </span></span></b><b><span style='font-size:12.0pt;line-height:150%;font-family:
  "Times New Roman",serif'>QUAIS INFORMAÇÕES RECOLHEMOS</span></b></h1>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:0in;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Os
  <span style='background:white'>DADOS PESSOAIS</span> do USUÁRIO que serão
  recolhidos pelo aplicativo são:</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.3pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>3.1.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Dados de cadastro: nome, sobrenome, CPF ou CNPJ, data de nascimento, cartões
  para pagamento;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.3pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>3.2.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Dados de redes sociais ou compartilhados de outra plataforma;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.3pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>3.3.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Dados para contato: telefone, endereço de e-mail, endereço físico, entre outros
  semelhantes;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.3pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>3.4.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Dados de localização;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.3pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>3.5.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Login de acesso.</span></p>
  
  <h1 style='margin-top:10.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
  .5in;text-align:justify;text-indent:-.25in;line-height:150%'><b><span
  style='font-size:11.0pt;line-height:150%'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
  </span></span></b><b><span style='font-size:12.0pt;line-height:150%;font-family:
  "Times New Roman",serif'>PARA QUE FINALIDADES USAMOS SUAS INFORMAÇÕES PESSOAIS</span></b></h1>
  
  <p class=MsoNormal style='margin-top:10.0pt;margin-right:0in;margin-bottom:
  10.0pt;margin-left:0in;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Os
  dados coletados por nós da VOKERÊ e fornecidos por você serão utilizados para
  melhorias e aprimoramentos a serem feitos em nosso aplicativo, sempre tendo em
  vista que você tenha a melhor experiência possível com os nossos serviços.
  Assim, destacam-se as seguintes atividades a serem realizadas pela VOKERÊ no
  TRATAMENTO desses dados:</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>4.1.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Personalização
  do CONTEÚDO do aplicativo;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>4.2.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Notificação sobre novos serviços e novos suportes, a fim de informar sobre
  produtos, serviços, promoções, notícias, atualizações e outros assuntos que
  você possa ter interesse;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>4.3.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Estabelecimento
  de canal ativo de comunicação com nossos USUÁRIOS;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>4.4.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Realização de publicidade personalizada conforme os seus dados coletados;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>4.5.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Cumprimento de obrigações legais;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>4.6.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Autenticação do acesso à PLATAFORMA;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>4.7.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Identificação de atividades na PLATAFORMA impróprias ou ilícitas;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>4.8.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Pagamentos
  dentro da PLATAFORMA;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>&nbsp;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:0in;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>O
  TRATAMENTO de DADOS PESSOAIS SENSÍVEIS respeitará sempre seu CONSENTIMENTO, bem
  como a observância das seguintes hipóteses:</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.3pt;text-align:justify;text-indent:-.15pt;line-height:
  150%'><b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>4.10.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Cumprimento
  de obrigação legal ou regulatória;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.3pt;text-align:justify;text-indent:-.15pt;line-height:
  150%'><b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>4.11.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Compartilhamento
  com a Administração Pública;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.3pt;text-align:justify;text-indent:-.15pt;line-height:
  150%'><b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>4.12.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Estudos
  por órgãos de pesquisa;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.3pt;text-align:justify;text-indent:-.15pt;line-height:
  150%'><b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>4.13.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Exercício
  regular de direito;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.3pt;text-align:justify;text-indent:-.15pt;line-height:
  150%'><b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>4.14.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Proteção
  à vida;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.3pt;text-align:justify;text-indent:-.15pt;line-height:
  150%'><b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>4.15.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Tutela
  à saúde;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.3pt;text-align:justify;text-indent:-.15pt;line-height:
  150%'><b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>4.16.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Prevenção
  à fraude.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:0in;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Fica
  estabelecido que, caso você não respeite e nem cumpra com o que for acordado
  por meio dessa política, poderá ser arbitrariamente excluído dos domínios de
  nosso aplicativo. </span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:0in;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>O
  aplicativo será constantemente monitorado a fim de garantir que seja cumprido
  tanto o estabelecido nesta Política quanto o previsto pelas leis aplicáveis.</span></p>
  
  <h1 style='margin-top:10.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
  .5in;text-align:justify;text-indent:-.25in;line-height:150%'><a
  name="_its9gjjae5rc"></a><b><span style='font-size:11.0pt;line-height:150%'>5.<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span></b><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>COMPARTILHAMENTO
  DE DADOS</span></b></h1>
  
  <p class=MsoNormal style='margin-top:10.0pt;margin-right:0in;margin-bottom:
  10.0pt;margin-left:0in;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Os
  DADOS PESSOAIS fornecidos à nossa PLATAFORMA não serão compartilhados, salvo
  nas exceções elencadas abaixo:</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>5.1.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Mediante consentimento prévio e expresso do titular, por meio de cláusula
  destacada;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>5.2.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Os dados concedidos pelo USUÁRIO<b> serão compartilhados com:</b></span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:64.0pt;text-align:justify;text-indent:8.0pt;line-height:
  150%'><b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>5.2.1.
  Base de dados em plataforma prestadora de serviços na nuvem;</span></b></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:100.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>5.2.1.1.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Constarão, na referida base de dados, os dados fornecidos tais como CPF ou CNPJ
  concedidos pelo USUÁRIO e outros, sempre mediante prévia autorização, e não
  sendo em nenhum momento disponibilizados a outrem que não mencionados neste
  documento.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>5.3.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Nas hipóteses previstas no presente documento e/ou nos TERMOS DE USO;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>5.4.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Mediante ordem judicial ou requisição do Ministério Público, de autoridade
  policial ou administrativa, consoante ao art. 15, § 3°, da Lei n. 12.965, de
  2014, e os arts. 11 e 15, do Decreto 8.771, de 2016, ou, ainda, caso seja
  necessário para cumprir qualquer outra lei ou ato normativo estatal;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>5.5.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Por
  motivo de segurança de suas informações e prevenção contra fraudes, podendo vir
  a acontecer apenas mediante boa-fé da empresa;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>5.6.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Investigar, impedir ou tomar medidas relacionadas a atividades ilegais
  suspeitas ou reais ou para cooperar com órgãos públicos ou para proteger a
  segurança nacional;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>5.7.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Para
  o cumprimento desta Política e/ou dos TERMOS DE USO;</span></p>
  
  <p class=MsoNormal style='margin-top:10.0pt;margin-right:0in;margin-bottom:
  10.0pt;margin-left:0in;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Notificaremos
  os nossos USUÁRIOS sobre eventuais demandas legais que resultem na divulgação
  de dados, a menos que tal notificação seja vedada por lei ou proibida por
  mandado judicial ou, ainda, se a requisição for emergencial.</span></p>
  
  <p class=MsoNormal style='margin-top:10.0pt;margin-right:0in;margin-bottom:
  10.0pt;margin-left:0in;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Haverá
  o <b>compartilhamento de </b><span style='background:white'>DADOS PESSOAIS</span><b>
  com países estrangeiros </b>apenas mediante os termos da Lei nº 13.709/2018.
  Você, titular dos dados, ao concordar com a transferência, esta finalidade
  acontecerá:</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>5.8.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Mediante
  a coleta do CONSENTIMENTO prévio e expresso do titular, por meio de cláusula
  destacada e com informação prévia sobre o caráter internacional da operação;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>5.9.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Por
  meio de requisição de autoridade nacional pública, por necessidade de
  cooperação jurídica internacional;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>5.10.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Com
  países que observem normas de proteção de <span style='background:white'>DADOS
  PESSOAIS</span>;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>5.11.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Mediante
  as observações das cláusulas <b>4.10.</b> a <b>4.16.</b></span></p>
  
  <p class=MsoNormal>&nbsp;</p>
  
  <h1 style='margin-top:10.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
  21.25pt;text-align:justify;text-indent:-.25in;line-height:150%'><b><span
  style='font-size:11.0pt;line-height:150%'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
  </span></span></b><b><span style='font-size:11.0pt;line-height:150%;font-family:
  "Times New Roman",serif'>SEGURANÇA DAS INFORMAÇÕES    </span></b><span
  style='font-size:11.0pt;line-height:150%;font-family:"Times New Roman",serif'>            </span></h1>
  
  <p class=MsoNormal style='margin-top:10.0pt;margin-right:0in;margin-bottom:
  10.0pt;margin-left:0in;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>O
  VOKERÊ preza pela segurança de seus dados e informações ao utilizar nossa
  plataforma. De todo modo, não há como garantir proteção total de forma a
  impedir acessos não autorizados, principalmente, se houver compartilhamento
  indevido das credenciais necessárias para acessar nossa plataforma. Assim, o
  usuário deve manter a senha de acesso em segurança, sendo vedado o compartilhamento
  com terceiros. Elencam-se os dispositivos sobre a segurança dos dados e
  informações coletados:</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>6.1.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'> 
  Você, como titular de dados, é o único responsável pela veracidade e
  integridade de todas as informações prestadas, devendo manter atualizados todos
  os seus dados.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>6.2.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'> 
  Ao utilizar nosso aplicativo, você concorda com a coleta e com o armazenamento
  dos dados em nossa plataforma de nuvem. </span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>6.3.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  O VOKERÊ se compromete a manter confidenciais os dados fornecidos.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>6.4.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Os dados e informações serão acessados somente por meio de canais de
  comunicação previamente autorizados.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>6.5.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'> O
  uso dos dados estará de acordo com a presente Política de Privacidade.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>6.6.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>O
  VOKERÊ se utiliza de todos os possíveis esforços presentes no mercado para
  proteger nossos sistemas e suas informações, prevenindo a ocorrência de
  eventuais danos em virtude do tratamento desses dados.</span></p>
  
  <h1 style='margin-top:10.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
  .5in;text-align:justify;text-indent:-.25in;line-height:150%'><b><span
  style='font-size:11.0pt;line-height:150%'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
  </span></span></b><b><span style='font-size:11.0pt;line-height:150%;font-family:
  "Times New Roman",serif'>DIREITOS DO TITULAR DOS DADOS PESSOAIS </span></b></h1>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:0in;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Como
  titular dos dados pessoais você poderá:</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>7.1.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Solicitar
  acesso a seus dados pessoais e confirmação da existência de tratamento destes,
  bem como de compartilhamento;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>7.2.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Solicitar
  a correção de dados pessoais imprecisos, incompletos e desatualizados;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>7.3.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Requisitar uma cópia de seus dados armazenados a qualquer momento;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>7.4.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Requerer
  a anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou
  tratados de modo irregular ao disposto em lei; </span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>7.5.
  </span></b><span style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Solicitar
  portabilidade dos dados a outro fornecedor de serviço ou produto, mediante
  requisição expressa;</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>7.6.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Solicitar a eliminação de quaisquer dados pessoais e saber as possíveis
  consequências desse ato.</span></p>
  
  <h1 style='margin-top:10.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
  0in;text-align:justify;line-height:150%'><a name="_shx6anja2ytx"></a><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>7.7.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  O usuário tem direito de retirar o seu consentimento a qualquer momento, para
  tanto deve entrar em contato por meio do e-mail faleconosco@vokere.com.</span><span
  style='font-size:11.0pt;line-height:150%;font-family:"Times New Roman",serif'>                      </span></h1>
  
  <h1 style='margin-top:10.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
  .5in;text-align:justify;text-indent:-.25in;line-height:150%'><b><span
  style='font-size:11.0pt;line-height:150%'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
  </span></span></b><b><span style='font-size:11.0pt;line-height:150%;font-family:
  "Times New Roman",serif'>DURAÇÃO DO TRATAMENTO DOS DADOS        </span></b></h1>
  
  <h1 style='margin-top:10.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
  0in;text-align:justify;line-height:150%'><a name="_2zf6tcys4f0i"></a><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Os
  dados só serão mantidos em nossos sistemas enquanto forem necessários ou
  relevantes para as finalidades desta Política, ou em casos de períodos
  determinados por lei, ou até quando cumprirem os interesses legítimos da nossa
  plataforma.</span><span style='font-size:11.0pt;line-height:150%;font-family:
  "Times New Roman",serif'>                           </span></h1>
  
  <h1 style='margin-top:10.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
  .5in;text-align:justify;text-indent:-.25in;line-height:150%'><b><span
  style='font-size:11.0pt;line-height:150%'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
  </span></span></b><b><span style='font-size:11.0pt;line-height:150%;font-family:
  "Times New Roman",serif'>ATUALIZAÇÃO DA POLÍTICA DE PRIVACIDADE</span></b></h1>
  
  <p class=MsoNormal style='margin-top:10.0pt;margin-right:0in;margin-bottom:
  10.0pt;margin-left:0in;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Atualização
  da presente política apenas ocorrerá com o intuito de aderir a novos
  dispositivos legais, de acatar exigências de órgãos públicos ou de adequar-se a
  novas mudanças operacionais ou de serviços.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>9.1.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Eventuais alterações serão contatadas a você, mediante comunicação em nossa
  plataforma, ou em quaisquer outros mecanismos que facilitem para os usuários o
  recebimento das informações.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>9.2.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Haverá um mecanismo que colete a sua concordância com as mudanças.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>9.3.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Todas as atualizações terão a finalidade de aumentar a segurança do tratamento
  dos dados pessoais colhidos e nunca acontecerão para diminuir a sua proteção.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>Quaisquer
  dúvidas, reclamações, sugestões acerca deste documento devem nos ser expressas
  por meio de nossos canais oficiais de comunicação divulgados na plataforma.</span><span
  style='font-family:"Times New Roman",serif'>                   </span></p>
  
  <h1 style='margin-top:10.0pt;margin-right:0in;margin-bottom:12.0pt;margin-left:
  .5in;text-align:justify;text-indent:-.25in;line-height:150%'><a
  name="_pps7lc73sokd"></a><b><span style='font-size:11.0pt;line-height:150%'>10.<span
  style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
  style='font-size:11.0pt;line-height:150%;font-family:"Times New Roman",serif'>LEGISLAÇÃO
  APLICÁVEL</span></b></h1>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  10.0pt;margin-left:0in;text-align:justify;line-height:150%'><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif;
  color:#221F1F;background:white'>Este documento é regido e deve ser interpretado
  de acordo com as leis da República Federativa do Brasil, em especial a lei nº
  13.709, de 14 de agosto de 2018, a Lei Geral de Proteção de Dados Pessoais -
  LGPD. Destacamos também nosso expresso respeito pelas normas do Código Civil,
  Código de Defesa do Consumidor e Marco Civil da Internet (lei nº 12.965/2014).</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>10.1.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Essas leis devem ser executadas por este documento independentemente de
  conflito com dispositivos legais de outros países.</span></p>
  
  <p class=MsoNormal style='margin-top:12.0pt;margin-right:0in;margin-bottom:
  12.0pt;margin-left:28.0pt;text-align:justify;line-height:150%'><b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>10.2.</span></b><span
  style='font-size:12.0pt;line-height:150%;font-family:"Times New Roman",serif'>
  Para todos os assuntos referentes à interpretação, ao cumprimento ou a qualquer
  outro questionamento relacionado a este documento e aos termos de uso, as
  partes concordam em se submeter ao Foro da Comarca de Balsas-MA.</span></p>
  
  </div>
  
  </body>
  
  </html>
  
  `;
    return (
        <div>
            {parse(html)}
        </div>
    )
}