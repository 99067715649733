import { Box, Button, CardMedia, CircularProgress, Container, CssBaseline, TextField, Typography } from '@mui/material'
import { useState } from 'react';
import InputMask from 'react-input-mask';

import Notificacao from '../../../components/notification';
import styles from '../../sobre/style.module.css'
import { cadastrarUsuario, sendTwoFactor } from '../controllers/cadastrarUsuario';
import FALLBACK_IMAGE from '../onerror.png';

const CriarConta = () => {

    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [telefone, setTelefone] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmCode, setShowConfirmCode] = useState(false);
    const [confirmCode, setConfirmCode] = useState('');

    return (
        <Container component="main" maxWidth="xs">
            {
                <>
                    <CssBaseline />
                    <br></br>
                    <br></br>
                    <br></br>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <CardMedia>
                            <img
                                src={FALLBACK_IMAGE}
                                alt="Logo"
                                className={styles.logo}
                                height="100"
                            />
                        </CardMedia>
                        <br></br>
                        <Box sx={{
                            width: '100%',
                        }}>
                            <Box>
                                <Typography sx={{
                                    marginTop: '10px',
                                }}>
                                    {/* Email */}
                                </Typography>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={(e) => {setEmail(e.target.value) }}
                                    sx={{
                                        marginTop: '5px',
                                    }}
                                    label="Email"
                                    disabled={showConfirmCode}
                                />
                            </Box>
                            <Box>
                                <Typography sx={{
                                    marginTop: '10px',
                                }}>
                                    {/* Nome */}
                                </Typography>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    name="name"
                                    autoComplete="name"
                                    autoFocus
                                    onChange={(e) => {setNome(e.target.value) }}
                                    sx={{
                                        marginTop: '5px',
                                    }}
                                    label="Nome"
                                    disabled={showConfirmCode}
                                />
                            </Box>
                            <Box>
                                <Typography sx={{
                                    marginTop: '10px',
                                }}>
                                    {/* Telefone */}
                                </Typography>
                                <InputMask
                                    mask="(99) 9 9999-9999"
                                    value={telefone}
                                    onChange={(e) => {setTelefone(e.target.value) }}
                                    placeholder='Telefone *'
                                    style={{
                                        width: '100%',
                                        height: '56px',
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                        borderRadius: '4px',
                                        border: '1px solid #ced4da',
                                        padding: '10px',
                                        fontSize: '16px',
                                        color: '#495057',
                                        backgroundColor: '#fff',
                                        backgroundClip: 'padding-box',
                                        transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
                                    }}
                                    disabled={showConfirmCode}
                                />
                            </Box>
                            <Box>
                                <Typography sx={{
                                    marginTop: '10px',
                                }}>
                                    {/* Senha */}
                                </Typography>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    sx={{
                                        marginTop: '5px',
                                    }}
                                    label="Senha"
                                    disabled={showConfirmCode}
                                />
                            </Box>
                            <Box>
                                <Typography sx={{
                                    marginTop: '0px',
                                }}>
                                    {/* Confirmar senha */}
                                </Typography>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={(e) => { setConfirmPassword(e.target.value)}}
                                    label="Confirmar senha"
                                    disabled={showConfirmCode}
                                />
                            </Box>
                            {
                                showConfirmCode ? <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: '20px',
                                }}>
                                    <Typography sx={{
                                        color: 'red',
                                    }}>
                                        Digite o código de confirmação que foi enviado para o seu email.
                                    </Typography>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="number"
                                        label="Código de confirmação"
                                        type="number"
                                        id="number"
                                        autoComplete="current-password"
                                        onChange={(e) => { setConfirmCode(e.target.value) }}
                                    />
                                </Box> : null
                            }
                            <br></br>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    onClick={async () => {

                                        function handleOnChange(email: string): boolean {
                                            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                                            if (re.test(email)) {
                                                return true;
                                            }
                                            else {
                                                return false;
                                            }
                                        }

                                        if (password !== confirmPassword && !showConfirmCode) {
                                            Notificacao('Error!', `As senhas não são iguais.`, 'danger');
                                            return
                                        }

                                        if (password.length < 6 && !showConfirmCode) {
                                            Notificacao('Error!', `A senha deve ter no mínimo 6 caracteres.`, 'danger');
                                            return
                                        }

                                        if (nome.length < 3 && !showConfirmCode) {
                                            Notificacao('Error!', `O nome deve ter no mínimo 3 caracteres.`, 'danger');
                                            return
                                        }

                                        if (telefone.length < 11 && !showConfirmCode) {
                                            Notificacao('Error!', `O telefone deve ter no mínimo 11 caracteres.`, 'danger');
                                            return
                                        }

                                        if (handleOnChange(email) === false && !showConfirmCode) {
                                            Notificacao('Error!', `O email é inválido.`, 'danger');
                                            return
                                        }

                                        function getRandomInt(min: number, max: number) {
                                            min = Math.ceil(min);
                                            max = Math.floor(max);
                                            return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
                                        }
                                        if (!showConfirmCode) {
                                            setIsLoading(true)
                                            const codigo = getRandomInt(100000, 999999)
                                            await localStorage.setItem('infoProfile', JSON.stringify({
                                                email: email,
                                                telefone: telefone,
                                                nome: nome,
                                                password: password,
                                                codigo: codigo
                                            }))
                                            const response = await sendTwoFactor(email, codigo)
                                            if (response) {
                                                Notificacao('Sucesso!', `Código envidado com sucesso! Verifique seu email.`, 'success');
                                                setShowConfirmCode(true)
                                            } else {
                                                Notificacao('Error!', `Falaha ao enviar código de verificação, tente novamente.`, 'danger');
                                            }
                                            setIsLoading(false)
                                            return
                                        } else {
                                            const infoProfile = JSON.parse(await localStorage.getItem('infoProfile') || '{}')

                                            if (String(infoProfile.codigo) !== confirmCode) {
                                                Notificacao('Error!', `Código de verificação inválido.`, 'danger');
                                                return
                                            }

                                            setIsLoading(true)
                                            const response = await cadastrarUsuario(
                                                infoProfile.email, 
                                                infoProfile.telefone, 
                                                infoProfile.nome, 
                                                infoProfile.password
                                            )
                                            setIsLoading(false)
                                            if (response) {
                                                Notificacao('Sucesso!', `Usuário criado com sucesso!`, 'success');
                                                window.location.href = '/login'
                                            }
                                        }
                                    }}
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        backgroundColor: '#F9146B',
                                        '&:hover': { backgroundColor: '#ff4a8f' },
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                    }}
                                >
                                    {!showConfirmCode ? 'Continuar' : 'Cadastrar usuário'}
                                    {
                                    isLoading ? <CircularProgress sx={{
                                        color: '#FFF',
                                        marginLeft: '20px',
                                    }}/> : null
                                }
                                </Button>
                        </Box>
                    </Box>
                    <br></br>
                    <br></br>
                    <br></br>
                </>
            }
        </Container>
    )
}

export default CriarConta
