import { getUrlAPIv3 } from '../api';
import { Root as EmpresaModel } from '../model/empresas.model';
import { NewEmpresaModel } from '../model/newEmpresas';

const getEmpresaDetail = async (idEmpresa: Number): Promise<EmpresaModel | null> => {

    let url = `${getUrlAPIv3}/empresa/${idEmpresa}`;

    try {
        const response = await fetch(url);
        if (response.status === 200) {
            const data = await response.json();
            return data;
        }
        return null
    } catch (error) {
        console.log(error);
        return null;
    }
};

const getNewEmpresaDetail = async (slugID: string): Promise<NewEmpresaModel | null> => {

    let url = `${getUrlAPIv3}/empresa-web/${slugID}`;

    try {
        const response = await fetch(url);
        if (response.status === 200) {
            const data = await response.json();
            return data;
        }
        return null
    } catch (error) {
        console.log(error);
        return null;
    }
};

export { getEmpresaDetail, getNewEmpresaDetail };