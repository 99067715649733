//MUI
import { Grid } from '@mui/material'

import styles from '../sobre/style.module.css'
import Page from './termosHTML'

const Termos = () => {
  return (
    <div>
      <Grid className={styles.body}>
        <div style={{
          padding: '0% 10%',
        }}>
          <Page/>
        </div>
      </Grid>
    </div>
  )
}

export default Termos
