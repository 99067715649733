import { NavLink } from "react-router-dom";
import logo from "../../../assets/images/logo1.png";

const Footer = ({ marginTop }: { marginTop: boolean }) => {
  const anoAtual = new Date().getFullYear();

  return (
    <footer className={`w-full bg-[#F3F3F3] p-8 ${!marginTop && "mt-10"}`}>
      <div className="flex flex-col md:flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 bg-[#F3F3F3] text-center md:justify-between max-w-screen-xl mx-auto">
        <a
          href="https://euvokere.com.br/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={logo} className="h-8" alt="Vokerê Logo" />
        </a>

        <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
          <li>
            <a
              href="https://www.facebook.com/vokereofc/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-slate-700 hover:text-slate-500 focus:text-slate-500 text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2rem"
                height="1.2rem"
                viewBox="0 0 24 24">
                <path
                  fill="black"
                  d="M9.602 21.026v-7.274H6.818a.545.545 0 0 1-.545-.545V10.33a.545.545 0 0 1 .545-.545h2.773V7a4.547 4.547 0 0 1 4.86-4.989h2.32a.556.556 0 0 1 .557.546v2.436a.557.557 0 0 1-.557.545h-1.45c-1.566 0-1.867.742-1.867 1.833v2.413h3.723a.533.533 0 0 1 .546.603l-.337 2.888a.545.545 0 0 1-.545.476h-3.364v7.274a.96.96 0 0 1-.975.974h-1.937a.96.96 0 0 1-.963-.974"
                />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/vokereofc/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-slate-700 hover:text-slate-500 focus:text-slate-500 text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2rem"
                height="1.2rem"
                viewBox="0 0 64 64">
                <path
                  fill="black"
                  d="M32 17.6c-7.9 0-14.4 6.4-14.4 14.4c0 7.9 6.4 14.4 14.4 14.4c7.9 0 14.3-6.4 14.3-14.4c0-7.9-6.4-14.4-14.3-14.4m0 24.3c-5.4 0-9.9-4.4-9.9-9.9c0-5.4 4.4-9.9 9.9-9.9c5.4 0 9.8 4.4 9.8 9.9c0 5.4-4.4 9.9-9.8 9.9m15-30.3c-2 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5s3.5-1.6 3.5-3.5s-1.5-3.5-3.5-3.5"
                />
                <path
                  fill="black"
                  d="M46.9 1.8H17.1C8.6 1.8 1.8 8.6 1.8 17.1V47c0 8.4 6.9 15.3 15.3 15.3H47c8.4 0 15.3-6.9 15.3-15.3V17.1c0-8.5-6.9-15.3-15.4-15.3m10.9 45.1c0 6-4.8 10.8-10.8 10.8H17.1c-6 0-10.8-4.8-10.8-10.8V17.1c0-6 4.9-10.8 10.8-10.8h29.8c6 0 10.8 4.9 10.8 10.8v29.8z"
                />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://api.whatsapp.com/send?phone=5599984151682&text=Preciso%20de%20ajuda!"
              target="_blank"
              rel="noopener noreferrer"
              className="text-slate-700 hover:text-slate-500 focus:text-slate-500 text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2rem"
                height="1.2rem"
                viewBox="0 0 24 24">
                <path
                  fill="black"
                  d="M19.05 4.91A9.82 9.82 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01m-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18l-3.12.82l.83-3.04l-.2-.31a8.26 8.26 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24c2.2 0 4.27.86 5.82 2.42a8.18 8.18 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23m4.52-6.16c-.25-.12-1.47-.72-1.69-.81c-.23-.08-.39-.12-.56.12c-.17.25-.64.81-.78.97c-.14.17-.29.19-.54.06c-.25-.12-1.05-.39-1.99-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.14-.25-.02-.38.11-.51c.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31c-.22.25-.86.85-.86 2.07s.89 2.4 1.01 2.56c.12.17 1.75 2.67 4.23 3.74c.59.26 1.05.41 1.41.52c.59.19 1.13.16 1.56.1c.48-.07 1.47-.6 1.67-1.18c.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28"
                />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/vokere/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-slate-700 hover:text-slate-500 focus:text-slate-500 text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2rem"
                height="1.2rem"
                viewBox="0 0 24 24">
                <path
                  fill="black"
                  d="M20.47 2H3.53a1.45 1.45 0 0 0-1.47 1.43v17.14A1.45 1.45 0 0 0 3.53 22h16.94a1.45 1.45 0 0 0 1.47-1.43V3.43A1.45 1.45 0 0 0 20.47 2M8.09 18.74h-3v-9h3ZM6.59 8.48a1.56 1.56 0 1 1 0-3.12a1.57 1.57 0 1 1 0 3.12m12.32 10.26h-3v-4.83c0-1.21-.43-2-1.52-2A1.65 1.65 0 0 0 12.85 13a2 2 0 0 0-.1.73v5h-3v-9h3V11a3 3 0 0 1 2.71-1.5c2 0 3.45 1.29 3.45 4.06Z"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>
      <p className="block mb-4 gap-8 text-sm text-center text-slate-500 md:mb-0 border-t border-slate-200 mt-4 pt-4">
        Copyright © {anoAtual}&nbsp;
        <a href="https://euvokere.com.br/" target="_blank" rel="noreferrer">
          Vokerê
        </a>{" "}
        |{" "}
        <NavLink
          to="/termos"
          target="_blank"
          rel="noreferrer"
          className="underline">
          Termos de Serviço
        </NavLink>
      </p>
    </footer>
  );
};

export default Footer;
