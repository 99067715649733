import Notificacao from '../../../components/notification';
import { getUrlAPIv1, getUrlAPIv3 } from '../api';

const sendTwoFactor = async (email: string, code: number): Promise<boolean> => {
    try {
        const url = `${getUrlAPIv3}/user/send_mail_twofactor`;

        const response = await fetch ( url, {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify({"email": email, "code": code}),
        }) ;
        if (response.status === 200) {
            return true;
        }
        return false;
    } catch ( error ) {
        return false;
    }
};

const cadastrarUsuario = async (email: string, telefone: string, nome: string, senha: string): Promise<boolean> => {
    try {
        const url = `${getUrlAPIv1}/register`;
        const response = await fetch ( url, {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "email": email,
                "telefone": telefone,
                "password": senha,
                "name": nome,
                "phoneToken": ""
              }),
        }) ;
        if (response.status === 200) {
            return true;
        }
        if (response.status === 201){
            Notificacao('Error!', `Esse e-mail já está cadastrado em nosso banco de dados.`, 'danger');
            return false;
        }
        if (response.status === 500){
            Notificacao('Error!', 'Comunicação com o servidor falhou, tente novamente!', 'danger');
            return false;
        }
        return false;
    } catch ( error ) {
        Notificacao('Error!', 'Dados inválidos, por favor, verifique as informações.', 'danger');
        return false;
    }
};

export {sendTwoFactor, cadastrarUsuario};