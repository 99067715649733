import { Box, Typography } from "@mui/material"

import { Root as Pedido, Produto } from '../../../model/pedido.model';

interface Props {
    pedido: Pedido,
    onClose: Function,
}

const ModalDetalhesPedido = (props: Props) => {

    function rowComponentJSX(chave: string, valor: string, index: number): JSX.Element {
        return <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            textAlign: 'start',
            alignItems: 'center',
            backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#FFFFFF',
            padding: '5px',
        }}>
            <div>
                <Typography sx={{
                    fontSize: '0.9rem',
                    fontWeight: 'bold',
                }}>
                    {chave}
                </Typography>
            </div>
            <div>
                <Typography sx={{
                    fontSize: '0.8rem',
                    marginLeft: '10px',
                }}>
                    {valor}
                </Typography>
            </div>
        </div>
    }

    return (
        <Box sx={{
            padding: 0,
        }}>
            <Typography variant="h6" component="h2" sx={{
                textAlign: 'center',
                marginBottom: '15px',
            }}>
                Produto:
            </Typography>
            {
                props.pedido.produtos.map((produto: Produto, index: number) => {
                    return (
                        <Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                justifyContent: 'start',
                                width: '95%',
                                borderLeftColor: '#F9146B',
                                borderLeftWidth: '5px',
                                borderLeftStyle: 'solid',
                                paddingLeft: '10px',
                            }}>
                                <Typography sx={{
                                    fontSize: '0.9rem',
                                    fontWeight: 'bold',
                                }}>
                                    {produto.pivot.quantidade} X {produto.nome} - {produto.pivot.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </Typography>
                                <Typography sx={{
                                    fontSize: '0.8rem',
                                    textAlign: 'justify',
                                }}>
                                    {produto.descricao}
                                    {produto.pivot.observacao}
                                </Typography>
                            </Box>
                            <Typography sx={{
                                fontSize: '0.8rem',
                                borderLeftColor: '#FAE600',
                                borderLeftWidth: '5px',
                                borderLeftStyle: 'solid',
                                paddingLeft: '10px',
                                paddingTop: '5px',
                                paddingBottom: '15px',
                            }}>
                                {produto.pivot.descricao}
                            </Typography>
                        </Box>
                    )
                })
            }
            <Typography variant="h6" component="h2" sx={{
                textAlign: 'center',
                marginBottom: '15px',
                marginTop: '5px',
            }}>
                Endereço:
            </Typography>
            {
                props.pedido.endereco === null ?
                    <Typography sx={{
                        fontSize: '1rem',
                        textAlign: 'center',
                    }}>
                        O cliente optou por retirar o pedido no local
                    </Typography> :
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'start',
                        width: '100%',
                    }}>
                        {rowComponentJSX('Rua:', props.pedido.endereco.logradouro ? props.pedido.endereco.logradouro : '', 0)}
                        {rowComponentJSX('Número:', props.pedido.endereco.numero ? props.pedido.endereco.numero : '', 1)}
                        {rowComponentJSX('Bairro:', props.pedido.endereco.bairro ? props.pedido.endereco.bairro : '', 2)}
                        {rowComponentJSX('Cidade:', props.pedido.endereco.cidade ? props.pedido.endereco.cidade : '', 3)}
                        {rowComponentJSX('CEP:', props.pedido.endereco.cep ? props.pedido.endereco.cep : '', 4)}
                        {rowComponentJSX('Complemento:', props.pedido.endereco.complemento ? props.pedido.endereco.complemento : '', 5)}
                    </Box>
            }
            <br></br>
        </Box>
    )
}

export default ModalDetalhesPedido