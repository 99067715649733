import Notificacao from '../../../components/notification';
import { getUrlAPIv1, getUrlAPIv4 } from '../api';
import { Root as Pedido } from '../model/pedido.model';

const getPedidosFinalizados = async (clienteId: number): Promise<Pedido[] | null> => {
    
    let url = `${getUrlAPIv1}/clientes/${clienteId}/pedidos/finalizados`;
    try {
        const response = await fetch ( url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "index" : 1,
                "count": 20
            })
        }) ;
        if (response.status === 200) {
            const data = await response.json ( );
            return data.data ;
        }
        return null;
    } catch ( error ) {
        console.log ( error ) ;
        return null ;
    }
};

const getPedidosAbertos = async (clienteId: number): Promise<Pedido[] | null> => {
    
    let url = `${getUrlAPIv1}/clientes/${clienteId}/pedidos/abertos`;
    try {
        const response = await fetch ( url ) ;
        if (response.status === 200) {
            const data = await response.json ( );
            return data ;
        }
        return null
    } catch ( error ) {
        console.log ( error ) ;
        return null ;
    }
};

const getPedidosCancelados = async (clienteId: number): Promise<Pedido[] | null> => {
    
    let url = `${getUrlAPIv1}/clientes/${clienteId}/pedidos/cancelados`;
    try {
        const response = await fetch ( url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "index" : 1,
                "count": 20
            })
        }) ;
        if (response.status === 200) {
            const data = await response.json ( );
            return data.data ;
        }
        return null
    } catch ( error ) {
        console.log ( error ) ;
        return null ;
    }
};

const cancelarPedido = async (pedidoId: number, token: string): Promise<boolean | null> => {
    try {
        const url = `${getUrlAPIv4}/pedidos/${pedidoId}`;
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });
        if (response.status === 200) {
            return true;
        }
        else if (response.status === 401) {
            const mensagem = 'Sua sessão expirou, faça login novamente!';
            Notificacao('Error', mensagem, 'danger');
            localStorage.removeItem('user')
            localStorage.removeItem('address_user')
            window.location.href = '/login';
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export {getPedidosFinalizados, getPedidosAbertos, getPedidosCancelados, cancelarPedido};