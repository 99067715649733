import { Close } from "@mui/icons-material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, DialogTitle, IconButton, Typography } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";

import { urlWebSite } from "../../pages/empresas/api";
import Notificacao from "../notification";

interface Props {
    title: string;
    slug: string | null;
    onClose: () => void;
}

export function ModalHeader(props: Props) {
    return (
        <DialogTitle sx={{
            m: 0,
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }}>
            {
                props.slug === '' || props.slug === null ?
                    <Typography variant="h6">
                        {props.title}
                    </Typography> :
                    <CopyToClipboard text={`${urlWebSite}/produto/${props.slug}`} onCopy={() => {
                        Notificacao('Sucesso!', `Link do produto copiado para área de transferência!`, 'success');
                    }}>
                        <Box sx={{
                            display: "flex",
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#F9146B',
                            }
                        }}>
                            <Typography variant="h6">
                                {props.title}
                            </Typography>
                            <ContentCopyIcon sx={{
                                marginLeft: '10px',
                                color: 'grey',
                            }} />
                        </Box>
                    </CopyToClipboard>
            }
            <IconButton onClick={() => props.onClose()}>
                <Close />
            </IconButton>
        </DialogTitle>
    )
}
