import { useEffect, useState } from "react";
import {
  getAllActiveCities,
  sendEmailToPartner,
} from "./repository/partnerRepository";
import { htmlAssuntoEmail } from "./components/AssuntoEmailParceiro/htmlAssuntoEmail";
import Notificacao from "../../components/notification";
import { FieldInputProps, useFormik } from "formik";
import * as Yup from "yup";

const Parceiro = () => {
  const [onReload, setOnReload] = useState(false);
  const [enviado, setEnviado] = useState(false);
  const [botaoElemento, setBotaoElemento] = useState<JSX.Element | null>(null);

  const validationSchema = Yup.object({
    nome: Yup.string()
      .min(3, "O nome deve ter pelo menos 3 caracteres.")
      .required("O campo 'nome' é obrigatório."),

    email: Yup.string()
      .email("Digite um email válido.")
      .required("O campo 'email' é obrigatório."),

    telefone: Yup.string()
      .matches(
        /^\d{10,15}$/,
        "O número deve ter entre 10 a 15 dígitos e conter apenas números."
      )
      .required("O campo 'telefone' é obrigatório."),

    empresa: Yup.string()
      .min(3, "O nome da empresa deve ter pelo menos 3 caracteres.")
      .required("O campo 'empresa' é obrigatório."),

    cidade: Yup.string()
      .required("Por favor, selecione uma cidade.")
      .notOneOf(
        ["Selecione a Cidade"],
        "Por favor, selecione uma cidade válida."
      ),
  });

  const formik = useFormik({
    initialValues: {
      nome: "",
      email: "",
      telefone: "",
      empresa: "",
      cidade: "Selecione a Cidade",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setEnviado(true);

      const fieldsForm = {
        userName: values.nome,
        userEmail: values.email,
        userTelefone: values.telefone,
        userEmpresa: values.empresa,
        userCidade: values.cidade,
      };

      const htmlAtualizado = replaceFieldsInHtml(htmlAssuntoEmail, fieldsForm);

      const cityId = await getAllActiveCities().then((res) => {
        return res.data.data.find(
          (cidade: { nome: string; id: number }) =>
            cidade.nome.toLowerCase() === values.cidade.toLowerCase()
        )?.id;
      });

      const data = {
        assunto:
          "OLÁ, GOSTARIA DE ME TORNAR UM PARCEIRO DO VOKERÊ PARA IMPULSIONAR AS VENDAS DA MINHA EMPRESA.",
        mensagem: htmlAtualizado,
        cityId: cityId,
      };

      const response = await sendEmailToPartner(data);
      if (response) {
        Notificacao("Sucesso!", `Email enviado com sucesso!`, "success");
        setOnReload(!onReload);
        setEnviado(false);
        resetForm();
      } else {
        setEnviado(false);
        Notificacao("Erro", `Erro ao enviar email, tente novamente!`, "danger");
      }
    },
  });

  function replaceFieldsInHtml(
    html: string,
    dados: { [chave: string]: string }
  ): string {
    return Object.keys(dados).reduce((htmlAtualizado, chave) => {
      const marcador = `{{${chave}}}`;
      return htmlAtualizado.replace(new RegExp(marcador, "g"), dados[chave]);
    }, html);
  }

  useEffect(() => {
    if (enviado) {
      setBotaoElemento(
        <button
          disabled
          className="w-full rounded border border-primary bg-gray-300 p-3 text-gray-700 cursor-not-allowed">
          Enviar
        </button>
      );
    } else {
      setBotaoElemento(
        <button
          type="submit"
          className="w-full rounded border border-primary bg-[#F9146B] p-3 text-white transition hover:bg-opacity-90">
          Enviar
        </button>
      );
    }
  }, [enviado]);

  return (
    <>
      <section className="relative mx-auto max-w-screen-xl z-10 overflow-hidden bg-white py-20 ">
        <div className="  container mx-auto max-w-screen-xl">
          <div className="flex flex-col lg:flex-row gap-5 lg:justify-between ">
            <div className="w-full px-4 m-">
              <div className="mb-12 lg:mb-0 mx-auto max-w-screen-xl">
                <span className="mb-4 block text-[#E5CC2C]  font-semibold text-primary">
                  Parceiria
                </span>
                <h2 className="mb-6 text-[32px] font-bold uppercase text-dark  sm:text-[40px] lg:text-[36px] xl:text-[40px]">
                  Quando você vende todo mundo ganha
                </h2>
                <p className="mb-9 text-base text-justify  leading-relaxed text-[#637381]">
                  A procura e o uso dos aplicativos de delivery tiveram um
                  crescimento expressivo nos ultimos anos e o Vokerê traz para o
                  seu negócio o meio mais eficaz de quebrar barreiras
                  geográficas, através dele, o seu negócio não fica limitado ao
                  entorno, podendo atrair públicos que normalmente não chegariam
                  até você.
                </p>

                <div className="flex gap-5 items-center mb-8">
                  <div
                    className="flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
                    style={{ backgroundColor: "rgba(249, 20, 107, 0.19)" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2rem"
                      height="2rem"
                      viewBox="0 0 24 24">
                      <g
                        fill="none"
                        stroke="#F9146B"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2">
                        <path d="M8 13V4.5a1.5 1.5 0 0 1 3 0V12m0-.5v-2a1.5 1.5 0 0 1 3 0V12m0-1.5a1.5 1.5 0 0 1 3 0V12" />
                        <path d="M17 11.5a1.5 1.5 0 0 1 3 0V16a6 6 0 0 1-6 6h-2h.208a6 6 0 0 1-5.012-2.7L7 19q-.468-.718-3.286-5.728a1.5 1.5 0 0 1 .536-2.022a1.87 1.87 0 0 1 2.28.28L8 13M5 3L4 2m0 5H3m11-4l1-1m0 4h1" />
                      </g>
                    </svg>
                  </div>
                  <div className="flex flex-col flex-1">
                    <span className="font-bold text-black">Praticidade</span>
                    <p className="text-[#637381] text-justify font-medium">
                      Todos os produtos em um app simples e intuitivo.
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex gap-5 items-center mb-8">
                <div
                  className="flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
                  style={{ backgroundColor: "rgba(249, 20, 107, 0.19)" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2rem"
                    height="2rem"
                    viewBox="0 0 24 24">
                    <g
                      fill="none"
                      stroke="#F9146B"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2">
                      <path d="M22 12.2a10 10 0 1 0-19.4 3.2c.2.5.8 1.1 1.3 1.3l13.2 5.1c.5.2 1.2 0 1.6-.3l2.6-2.6c.4-.4.7-1.2.7-1.7Z" />
                      <path d="m21.8 18l-10.5-4a2 2.06 0 0 1 .7-4h9.8" />
                    </g>
                  </svg>
                </div>
                <div className="flex flex-col flex-1">
                  <span className="font-bold text-black">Comodidade</span>
                  <p className="text-[#637381] text-justify font-medium">
                    O produto chega ao cliente, sem que ele precise se
                    locomover.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full px-4">
              <div className="relative rounded-lg bg-white p-8 shadow-lg mr-5 dark:bg-dark-2 sm:p-12">
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-6">
                    <ContactInputBox
                      type="text"
                      name="nome"
                      placeholder="Nome Completo"
                      onChange={formik.handleChange}
                      value={formik.values.nome}
                      error={formik.touched.nome && formik.errors.nome}
                    />
                    {formik.touched.nome && formik.errors.nome && (
                      <div className="text-[#dc2626]">{formik.errors.nome}</div>
                    )}
                  </div>

                  <div className="mb-6">
                    <ContactInputBox
                      type="text"
                      name="email"
                      placeholder="Endereço de Email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      error={formik.touched.email && formik.errors.email}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="text-[#dc2626]">
                        {formik.errors.email}
                      </div>
                    )}
                  </div>
                  <div className="mb-6">
                    <ContactInputBox
                      type="tel"
                      name="telefone"
                      placeholder="Nº de Whatsapp"
                      onChange={formik.handleChange}
                      value={formik.values.telefone}
                      error={formik.touched.telefone && formik.errors.telefone}
                    />
                    {formik.touched.telefone && formik.errors.telefone && (
                      <div className="text-[#dc2626]">
                        {formik.errors.telefone}
                      </div>
                    )}
                  </div>
                  <div className="mb-6">
                    <ContactInputBox
                      type="text"
                      name="empresa"
                      placeholder="Nome da Empresa"
                      onChange={formik.handleChange}
                      value={formik.values.empresa}
                      error={formik.touched.empresa && formik.errors.empresa}
                    />
                    {formik.touched.empresa && formik.errors.empresa && (
                      <div className="text-[#dc2626]">
                        {formik.errors.empresa}
                      </div>
                    )}
                  </div>
                  <div className="mb-6">
                    <ContactSelectBox
                      props={formik.getFieldProps("cidade")}
                      placeholder="Selecione a Cidade"
                      error={formik.touched.cidade && formik.errors.cidade}
                    />
                    {formik.touched.cidade && formik.errors.cidade && (
                      <div className="text-[#dc2626]">
                        {formik.errors.cidade}
                      </div>
                    )}
                  </div>
                  <div>{botaoElemento}</div>
                </form>
                <div>
                  <span className="absolute -right-9 -top-10 z-[-1]">
                    <svg
                      width={100}
                      height={100}
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z"
                        fill="#F9146B"
                      />
                    </svg>
                  </span>
                  <span className="absolute -right-10 top-[90px] z-[-1]">
                    <svg
                      width={34}
                      height={134}
                      viewBox="0 0 34 134"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <circle
                        cx="31.9993"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 31.9993 132)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 31.9993 117.333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 31.9993 102.667)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 31.9993 88)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 31.9993 73.3333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 31.9993 45)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 31.9993 16)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 31.9993 59)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 31.9993 30.6666)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 31.9993 1.66665)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 17.3333 132)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 17.3333 117.333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 17.3333 102.667)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 17.3333 88)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 17.3333 73.3333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 17.3333 45)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 17.3333 16)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 17.3333 59)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 17.3333 30.6666)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 17.3333 1.66665)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 2.66536 132)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 2.66536 117.333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 2.66536 102.667)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 2.66536 88)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 2.66536 73.3333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 2.66536 45)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 2.66536 16)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 2.66536 59)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 2.66536 30.6666)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 2.66536 1.66665)"
                        fill="#F9146B"
                      />
                    </svg>
                  </span>
                  <span className="absolute -bottom-7 -left-7 z-[-1]">
                    <svg
                      width={107}
                      height={134}
                      viewBox="0 0 107 134"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <circle
                        cx="104.999"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 104.999 132)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="104.999"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 104.999 117.333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="104.999"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 104.999 102.667)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="104.999"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 104.999 88)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="104.999"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 104.999 73.3333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="104.999"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 104.999 45)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="104.999"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 104.999 16)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="104.999"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 104.999 59)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="104.999"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 104.999 30.6666)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="104.999"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 104.999 1.66665)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="90.3333"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 90.3333 132)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="90.3333"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 90.3333 117.333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="90.3333"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 90.3333 102.667)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="90.3333"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 90.3333 88)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="90.3333"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 90.3333 73.3333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="90.3333"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 90.3333 45)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="90.3333"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 90.3333 16)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="90.3333"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 90.3333 59)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="90.3333"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 90.3333 30.6666)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="90.3333"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 90.3333 1.66665)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="75.6654"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 75.6654 132)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 31.9993 132)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="75.6654"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 75.6654 117.333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 31.9993 117.333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="75.6654"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 75.6654 102.667)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 31.9993 102.667)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="75.6654"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 75.6654 88)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 31.9993 88)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="75.6654"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 75.6654 73.3333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 31.9993 73.3333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="75.6654"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 75.6654 45)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 31.9993 45)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="75.6654"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 75.6654 16)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 31.9993 16)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="75.6654"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 75.6654 59)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 31.9993 59)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="75.6654"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 75.6654 30.6666)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 31.9993 30.6666)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="75.6654"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 75.6654 1.66665)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="31.9993"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 31.9993 1.66665)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="60.9993"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 60.9993 132)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 17.3333 132)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="60.9993"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 60.9993 117.333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 17.3333 117.333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="60.9993"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 60.9993 102.667)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 17.3333 102.667)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="60.9993"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 60.9993 88)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 17.3333 88)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="60.9993"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 60.9993 73.3333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 17.3333 73.3333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="60.9993"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 60.9993 45)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 17.3333 45)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="60.9993"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 60.9993 16)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 17.3333 16)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="60.9993"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 60.9993 59)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 17.3333 59)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="60.9993"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 60.9993 30.6666)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 17.3333 30.6666)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="60.9993"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 60.9993 1.66665)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="17.3333"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 17.3333 1.66665)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="46.3333"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 46.3333 132)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy={132}
                        r="1.66667"
                        transform="rotate(180 2.66536 132)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="46.3333"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 46.3333 117.333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy="117.333"
                        r="1.66667"
                        transform="rotate(180 2.66536 117.333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="46.3333"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 46.3333 102.667)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy="102.667"
                        r="1.66667"
                        transform="rotate(180 2.66536 102.667)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="46.3333"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 46.3333 88)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy={88}
                        r="1.66667"
                        transform="rotate(180 2.66536 88)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="46.3333"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 46.3333 73.3333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy="73.3333"
                        r="1.66667"
                        transform="rotate(180 2.66536 73.3333)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="46.3333"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 46.3333 45)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy={45}
                        r="1.66667"
                        transform="rotate(180 2.66536 45)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="46.3333"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 46.3333 16)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy={16}
                        r="1.66667"
                        transform="rotate(180 2.66536 16)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="46.3333"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 46.3333 59)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy={59}
                        r="1.66667"
                        transform="rotate(180 2.66536 59)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="46.3333"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 46.3333 30.6666)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy="30.6666"
                        r="1.66667"
                        transform="rotate(180 2.66536 30.6666)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="46.3333"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 46.3333 1.66665)"
                        fill="#F9146B"
                      />
                      <circle
                        cx="2.66536"
                        cy="1.66665"
                        r="1.66667"
                        transform="rotate(180 2.66536 1.66665)"
                        fill="#F9146B"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Parceiro;

interface IContactInputBoxProps {
  type: string;
  placeholder: string;
  name: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string | false | undefined;
}

const ContactInputBox = ({
  type,
  placeholder,
  name,
  value,
  onChange,
  error,
}: IContactInputBoxProps) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      className={`w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary ${
        error ? "border-[#dc2626]" : ""
      }`}
    />
  );
};

interface IEstado {
  id: number;
  sigla: string;
  nome: string;
}

interface ICidade {
  id: number;
  nome: string;
  ativo: number;
  estado_id: number;
  estado: IEstado;
  sigla: string;
}

interface IContactSelectBox {
  placeholder: string;
  props: FieldInputProps<any>;
  error?: string | false | undefined;
}

const ContactSelectBox = ({ placeholder, props, error }: IContactSelectBox) => {
  const [cidades, setCidades] = useState(Array<ICidade>);

  useEffect(() => {
    getAllActiveCities().then((res) => {
      setCidades(res.data.data);
    });
  }, []);

  return (
    <>
      <select
        {...props}
        className={`w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary cursor-pointer ${
          error ? "border-[#dc2626]" : ""
        }`}>
        <option disabled>{placeholder}</option>
        {cidades.map((cidade) => (
          <option key={cidade.id}>{cidade.nome}</option>
        ))}
      </select>
    </>
  );
};
