import { API } from "../../../services/api";

type EmailType = {
    assunto: string,
    mensagem: string
}

export async function sendEmailToPartner(data: EmailType): Promise<any>{
    return await API.post('/api/v3/sendEmailToPartnerBySite', data)
    .then(res => {
        return res
    })
    .catch(error => {
        console.log('error:', error)
        return error
    })
}

export function getAllActiveCities(): Promise<any>{
    return API.get('/api/v1/cidades_ativas')
    .then(res => {
        return res
    })
    .catch(error => {
        console.log('error:', error)
        return error
    })
}