import { ThemeProvider } from "@mui/material";
import { ReactNotifications } from "react-notifications-component";
/**/ import { Route, Routes } from "react-router-dom";

import { Tema } from "./assets/themes/tema";
import Footer from "./components/V2/footer/index";
import ScrollToTop from "./components/header/ScrollToTop";
import Empresas from "./pages/empresas/empresas";
import CarrinhoPage from "./pages/empresas/subpage/carrinho";
import CategoriaPage from "./pages/empresas/subpage/categorias";
import CriarConta from "./pages/empresas/subpage/criarConta";
import EmpresaDetail from "./pages/empresas/subpage/empresaDetail";
import EmpresasFromCity from "./pages/empresas/subpage/empresas";
import Login from "./pages/empresas/subpage/login";
import PedidosPage from "./pages/empresas/subpage/pedidos";
import ProdutoDetail from "./pages/empresas/subpage/produtoDetail";
import Entregador from "./pages/entregador/entregador";
//import Franqueado from './pages/franqueado/franqueado'
import Home from "./pages/home/home";
import Agradecimento from "./pages/parceiro/components/secao_form/agradecimento";
import Parceiro from "./pages/parceiro/parceiro";
import Sobre from "./pages/sobre/sobre";
import Termos from "./pages/termos/termos";
import { TrabalheConosco } from "./pages/trabalhe-conosco/trabalhe-conosco";
import Navbar from "./components/V2/navbar";
import { useEffect, useState } from "react";
import NotFound from "./pages/404";

function App() {
  const [hasMarginTop, setHasMarginTop] = useState(false);

  // Veficação se a página atual tem Margin ao fim da página, caso não, é adicionado no componente Footer
  useEffect(() => {
    const mainElement = document.querySelector("main");

    if (
      mainElement &&
      Array.from(mainElement.classList).some((cls) => cls.startsWith("mb-"))
    ) {
      setHasMarginTop(true);
    }
  }, []);

  return (
    <div>
      {/*<Home />

      {' '}*/}
      <ThemeProvider theme={Tema}>
        <ReactNotifications isMobile={true} />

        <ScrollToTop children={undefined} />
        <div className="flex flex-col min-h-screen">
          <Navbar />
          <main className="flex-1">
            <Routes>
              {/**/}
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/entregador" element={<Entregador />} />
              {/*
                  <Route path="/Franqueado" element={<Franqueado />} />
              */}
              <Route path="/parceiro" element={<Parceiro />} />
              <Route path="/sobre" element={<Sobre />} />
              <Route path="/trabalhe-conosco" element={<TrabalheConosco />} />
              <Route path="/agradecimento" element={<Agradecimento />} />
              <Route path="/termos" element={<Termos />} />
              <Route path="/empresas" element={<Empresas />} />
              <Route
                path="/empresas/cidade/:id/:cidade"
                element={<EmpresasFromCity />}
              />
              <Route path="/empresa/:slugEmpresa" element={<EmpresaDetail />} />
              <Route
                path="/empresas/:cidade/:idCategoria"
                element={<CategoriaPage />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="/pedidos" element={<PedidosPage />} />
              <Route path="/carrinho" element={<CarrinhoPage />} />
              <Route path="/criar-conta" element={<CriarConta />} />
              {/* <Route
                path="/formTrabalheConosco"
                element={<PageFormularioTrabalheConosco />}
              /> */}
              <Route path="/produto/:idProduto" element={<ProdutoDetail />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
          <Footer marginTop={hasMarginTop} />
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
