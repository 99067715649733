import Notificacao from '../../../components/notification';
import { getUrlAPIv3, getUrlAPIv4 } from '../api';
import { Address, PayloadAddress } from '../model/address.model';

const getAddress = async (id: number): Promise<Address | null> => {

    try {
        let url = `${getUrlAPIv3}/endereco/${id}`;
        const response = await fetch(url);
        if (response.status === 200) {
            const data = await response.json();
            return data;
        }
        return null
    } catch (error) {
        console.log(error);
        return null;
    }

};

const deleteAddress = async (id: number, token: string): Promise<boolean> => {

    try {
        const url = `${getUrlAPIv4}/enderecos/${id}`;
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });
        if (response.status === 200) {
            return true;
        }
        else if (response.status === 401) {
            const mensagem = 'Sua sessão expirou, faça login novamente!';
            Notificacao('Error', mensagem, 'danger');
            localStorage.removeItem('user')
            localStorage.removeItem('address_user')
            window.location.href = '/login';
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }

};

const cadastrarAddress = async (endereco: PayloadAddress, token: string): Promise<boolean> => {

    try {
        const url = `${getUrlAPIv4}/enderecos`;
        const payload = {
            "nome": endereco.nome.toString(),
            "logradouro": endereco.logradouro.toString(),
            "numero": endereco.numero.toString(),
            "bairro": endereco.bairro.toString(),
            "cep": endereco.cep.toString(),
            "complemento": endereco.complemento.toString(),
            "estado": endereco.estado.toString(),
            "cidade": endereco.cidade.toString(),
            "referencia": endereco.referencia.toString(),
            "latitude": endereco.latitude.toString(),
            "longitude": endereco.longitude.toString(),
            "tipo": 1,
            "nome_empresa": "",
            "clientes_id": endereco.clientes_id.toString(),
        };
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(payload),
        });
        if (response.status === 200) {
            return true;
        }
        else if (response.status === 401) {
            const mensagem = 'Sua sessão expirou, faça login novamente!';
            Notificacao('Error', mensagem, 'danger');
            localStorage.removeItem('user')
            localStorage.removeItem('address_user')
            window.location.href = '/login';
        }
        return false;
    } catch (error) {
        console.log(error);
        return false;
    }

};


export { getAddress, deleteAddress, cadastrarAddress };