import React from "react";
import { NavLink } from "react-router-dom";

export const ContainedButton = ({ className, content, to }: any) => {
  return (
    <NavLink
      to={to}
      className={`flex items-center justify-center bg-[#F9146B]  text-white text-sm px-4 py-2 text-center rounded-full font-medium transition-all duration-300 transform hover:bg-[#d3135b] hover:scale-105 ${className}`}>
      {content}
    </NavLink>
  );
};

export const OutlinedButton = ({ className, content, to }: any) => {
  return (
    <NavLink
      to={to}
      className={`flex items-center justify-center border border-[#F9146B] px-4 py-2 font-medium rounded-full text-[#F9146B] transition-all duration-300 transform hover:bg-[#F9146B] hover:text-white hover:scale-105 ${className}`}>
      {content}
    </NavLink>
  );
};

export const DisabledButton = ({ content }: any) => {
  return (
    <button
      className="border border-gray-300 px-4 py-2 font-medium rounded-full  cursor-not-allowed opacity-50"
      disabled>
      {content}
    </button>
  );
};
