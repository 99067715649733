import { DialogContent } from "@mui/material";

interface Props {
    children: React.ReactNode;
    isMobile: boolean;
}

export function ContentModal(props: Props) {
    return (
        <DialogContent dividers sx={{
            padding: 0,
        }}>
            {props.children}
        </DialogContent>
    )
}