import { getUrlAPIv3 } from '../api';
import { EnderecoCliente } from '../model/endereco.model' ;

const getEndereco = async (
        clienteId: number, 
        empresaId: number,
        latitude: number,
        longitude: number,
    ): Promise<EnderecoCliente[] | null> => {

    let url = `${getUrlAPIv3}/user/enderecos_taxas`;

    const payload = {
        "cliente_id": clienteId, 
        "empresa_id": empresaId, 
        "latitude": latitude, 
        "longitude": longitude,
    }
    
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        if (response.status === 200) {
            const data = await response.json();
            console.log(data);
            return data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
    
};

export default getEndereco;