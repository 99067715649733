import Notificacao from "../../../components/notification";
import { getUrlAPIv4 } from "../api";
import { Root as MakePedido } from "../model/makePedido.model";

export const makePedido = async (pedido: MakePedido, token: string): Promise<boolean> => {
    const url = `${getUrlAPIv4}/pedidos`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(pedido),
    });
    const data = await response.json();
    if (response.status === 200) {
        const mensagem = data.message;
        if (mensagem && mensagem.includes('sucesso')) {
            Notificacao('Sucesso!', mensagem, 'success');
            return true;
        }

        if (mensagem) {
            Notificacao('Atenção!', mensagem, 'default');
        }
        return false;
    } 
    else if (response.status === 500) {
        const mensagem = data.message;
        if (mensagem) {
            Notificacao('Error', mensagem, 'danger')
        }
        return false;
    }
    else if (response.status === 401) {
        const mensagem = 'Sua sessão expirou, faça login novamente!';
        Notificacao('Error', mensagem, 'danger');
        localStorage.removeItem('user')
        localStorage.removeItem('address_user')
        window.location.href = '/login';
    }
    else {
        const mensagem = data.error;
        Notificacao('Error', mensagem, 'danger');
    }
    return false;
} 
