import React from "react";
import { ContainedButton } from "../../components/V2/button";

const NotFound = () => {
  return (
    <section className="flex justify-center items-center bg-white h-[700px]">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-[#F9146B]">
            404
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl ">
            Página não Encontrada
          </p>
          <p className="mb-6 text-lg font-light text-gray-500 400">
            Desculpe, não conseguimos encontrar essa página. Você encontrará
            muito para explorar na página inicial.{" "}
          </p>
          <div className="flex justify-center items-center">
            <ContainedButton content={"Voltar para Home"} to={"/home"} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
