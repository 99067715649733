import { getUrlAPIv2 } from '../api';
import { Root as Produto } from '../model/produto.model';

const getIndividualProd = async (produtoID: number): Promise<Produto | null> => {
    
    let url = `${getUrlAPIv2}/produtos/${produtoID}`;
    try {
        const response = await fetch ( url ) ;
        if ( response.status === 200 ) {
            const data = await response.json ( );
            return data.data ;
        }
        return null
    } catch ( error ) {
        console.log ( error ) ;
        return null ;
    }
};

export default getIndividualProd;