import { getUrlAPIv3 } from "../api";
import { ProdBySlug } from "../model/prodBySlug";

const getProductsBySlug = async (slugEmpresa: string): Promise<ProdBySlug | null> => {
    
    let url = `${getUrlAPIv3}/produto-web/${slugEmpresa}`;
    try {
        const response = await fetch ( url ) ;
        if (response.status === 200) {
            const data = await response.json ( );
            return data
        }
        return null;
    } catch ( error ) {
        console.log ( error ) ;
        return null ;
    }
};

export { getProductsBySlug };