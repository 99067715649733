import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography } from "@mui/material"
import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import { useEffect, useState } from "react";
import Geocode from "react-geocode";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';

import Notificacao from '../../../../../components/notification';
import { cadastrarAddress, deleteAddress } from '../../../controllers/CRUDAddress';
import getBairros, { Bairro } from '../../../controllers/getBairros';
import getCities from '../../../controllers/getCities';
import getEndereco from "../../../controllers/getEnderecoCliente";
import { PayloadAddress } from '../../../model/address.model';
import Cidade from '../../../model/cidades.model';
import { EnderecoCliente } from "../../../model/endereco.model";
import OpenMap from '../../../utils/index';

interface Props {
    onClose: Function,
    onReload: Function,
    entity_id: number,
    token: string | null,
}

Geocode.setApiKey('AIzaSyBS6gB8PSBnlXDAkm9DgQ7OxozP6hE6JVg');
Geocode.enableDebug();

const ModalEnderecos = (props: Props) => {

    const [open, setOpen] = useState<boolean>(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const [idEnderecoForDelete, setIdEnderecoForDelete] = useState<number>(-1);

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const [enderecos, setEnderecos] = useState<EnderecoCliente[] | null>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [cities, setCities] = useState<Cidade[] | null>([]);
    const [createNewAddress, setCreateNewAddress] = useState<boolean>(false);
    const [idCidadeSelecionada, setIdCidadeSelecionada] = useState<string>('');
    const [nomeEstadoSelecionado, setNomeEstadoSelecionado] = useState<string>(''); // [nomeEstadoSelecionado, setNomeEstadoSelecionado
    const [bairrosCarregados, setBairrosCarregados] = useState<Bairro[]>([]);
    const [isLoadingBairros, setIsLoadingBairros] = useState<boolean>(false);
    const [bairroSelecionado, setBairroSelecionado] = useState<string>('');
    const [mensagemForSnackbar, setMensagemForSnackbar] = useState<string>('');
    const flag = props.entity_id > 0 ? true : false;
    const [reloadAddress, setReloadAddress] = useState<boolean>(false);

    const [size, setSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
    });
    const updateSize = () =>
        setSize({
            x: window.innerWidth,
            y: window.innerHeight
        });
    useEffect(() => (window.onresize = updateSize), []);

    //const [addessText, setAddessText] = useState<string>('');

    interface Endereco {
        logradouro: string,
        numero: string,
        cep: string,
        bairro: string,
        cidade: string,
        complemento: string,
        titulo: string,
    }

    const [endereco, setEndereco] = useState<Endereco>({
        logradouro: '',
        numero: '',
        cep: '',
        bairro: '',
        cidade: '',
        complemento: '',
        titulo: '',
    })

    async function getCidades(): Promise<Cidade[] | null> {
        setIsLoading(true);
        const city = await getCities();
        setCities(city);
        setIsLoading(false);
        return city;
    }

    function getBairroSelecionadoByID(id: string): string {
        const bairro = bairrosCarregados.filter(bairro => bairro.id === parseInt(id));
        return bairro[0].nome;
    }

    const [latAndLng, setLatAndLng] = useState({
        lat: -7.525277265064072,
        lng: -46.04593280551803
    });

    async function getLocation(): Promise<void> {
        navigator.geolocation.getCurrentPosition(async function (position) {
            setLatAndLng({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            })
            await updateLatAndLng(
                position.coords.latitude.toString(),
                position.coords.longitude.toString()
            )
        });
    }

    async function loadBairros(idCidadeStr: string): Promise<Bairro[] | null> {
        setIsLoadingBairros(true);
        let idCidade = parseInt(idCidadeStr);
        let bairros = await getBairros(idCidade);
        if (bairros) {
            setBairrosCarregados(bairros);
            setIsLoadingBairros(false);
            return bairros
        }
        setIsLoadingBairros(false);
        return null
    }

    async function atualizarGeralALocatizacao(latitude: number, longitude: number) {
        setLatAndLng({
            lat: latitude,
            lng: longitude
        })
        await updateLatAndLng(
            longitude.toString(),
            longitude.toString()
        )
    }

    useEffect(() => {
        if (idCidadeSelecionada) {
            if (cities) {
                for (let c in cities) {
                    if (cities[c].id === parseInt(idCidadeSelecionada)) {
                        loadBairros(idCidadeSelecionada)
                        Geocode.fromAddress(
                            cities[c].nome,
                            'AIzaSyBS6gB8PSBnlXDAkm9DgQ7OxozP6hE6JVg',
                            'pt_BR',
                            'BR',
                        ).then(
                            (response) => {
                                const { lat, lng } = response.results[0].geometry.location;
                                atualizarGeralALocatizacao(lat, lng);
                            },
                            (error) => {
                                console.error(error);
                            }
                        );
                        break;
                    }
                }
            }
        }
    }, [idCidadeSelecionada])


    async function getEnderecoCliente(clienteId: number) {
        setIsLoading(true)
        await getCidades();
        if (flag) {
            let enderecoCliente = await getEndereco(clienteId, 0, 0, 0);
            setEnderecos(enderecoCliente)
        }
        setIsLoading(false)
    }

    function itCidadeToNome(id: string): string {
        let nome = '';
        if (cities) {
            for (let c in cities) {
                if (cities[c].id === parseInt(id)) {
                    nome = cities[c].nome;
                    break;
                }
            }
        }
        return nome;
    }

    useEffect(() => {
        getEnderecoCliente(props.entity_id)
        getLocation()
    }, [reloadAddress])

    async function updateLatAndLng(latitude: string | null, loginde: string | null) {
        await Geocode.fromLatLng(
            latitude ?? latAndLng.lat.toString(),
            loginde ?? latAndLng.lng.toString()
        ).then(
            async response => {
                const address = await response.results[0].address_components;

                let logradouro = '';
                let numero = '';
                let bairro = '';
                let cidade = '';
                let cep = '';

                if (address.length > 5) {
                    if (address[1] && address[1].long_name) {
                        logradouro = address[1].long_name;
                    }
                    if (address[0] && address[0].long_name) {
                        numero = address[0].long_name;
                    }
                    if (address[2] && address[2].long_name) {
                        bairro = address[2].long_name;
                    }
                    if (address[3] && address[3].long_name) {
                        cidade = address[3].long_name;
                    }
                    if (address[6] && address[6].long_name) {
                        cep = address[6].long_name;
                    }
                } else {
                    if (address[1] && address[1].long_name) {
                        logradouro = address[1].long_name;
                    }
                    if (address[0] && address[0].long_name) {
                        numero = address[0].long_name;
                    }
                    if (address[2] && address[2].long_name) {
                        cidade = address[2].long_name;
                    }
                    if (address[5] && address[5].long_name) {
                        cep = address[5].long_name;
                    }
                }


                let enderecoFlag: Endereco = {
                    logradouro: logradouro,
                    numero: numero,
                    cep: cep,
                    bairro: bairro,
                    cidade: cidade,
                    complemento: endereco?.complemento ?? '',
                    titulo: endereco?.titulo,
                };

                setEndereco(enderecoFlag);
            },
            error => {
                console.error(error);
            }
        );
    }


    async function selecionarUmBairroEUmaCidade() {
        if (cities) {
            for (let c in cities) {
                if (cities[c].nome === endereco?.cidade) {
                    let idCidade = cities[c].id;
                    setIdCidadeSelecionada(idCidade.toString());
                    setNomeEstadoSelecionado(cities[c].estado.nome);
                    let bairrosCarregadosFlag = await loadBairros(idCidade.toString());
                    let encontrouOBairro = false;
                    if (bairrosCarregadosFlag) {
                        for (let b in bairrosCarregadosFlag) {
                            if (bairrosCarregadosFlag[b].nome === endereco?.bairro) {
                                setBairroSelecionado(bairrosCarregadosFlag[b].id.toString());
                                encontrouOBairro = true;
                                break
                            }
                        }
                    }
                    if (encontrouOBairro === false) {
                        setBairroSelecionado('');
                    }
                    break
                }
            }
        }
    }

    useEffect(() => {
        selecionarUmBairroEUmaCidade();
    }, [endereco])

    const textFontSmall = {
        textAlign: 'center',
        fontSize: '0.8rem',
    };

    const textFontSmallBold = {
        textAlign: 'center',
        fontSize: '0.8rem',
        fontWeight: 'bold',
    };

    const styleCardContent = {
        marginBottom: '10px',
        paddingBottom: '15px',
        borderRadius: '10px',
        textAlign: 'center',
        marginLeft: '6px',
        marginRight: '6px',
    };


    function getJSXforAddress(key: string, value: string) {
        return <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'start',
            width: '90%',
            marginLeft: '10px',
            marginRight: '10px',
        }}>
            <Typography variant="h6" sx={textFontSmallBold}>
                {key}:
            </Typography>
            <div style={{
                width: '8px',
            }}></div>
            <Typography variant="h6" sx={textFontSmall}
                style={{
                    maxLines: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
            >
                {value}
            </Typography>
        </div>
    }

    let rowInFieldCreateAddress = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px',
    }

    function _generateAddress(enderecos: EnderecoCliente[]): JSX.Element[] {
        let jsx: JSX.Element[] = [];
        for (let i in enderecos) {
            const endereco = enderecos[i];
            let jsxEndereco = (
                <Grid item xs={12} sm={6} md={4} lg={4} key={i}>
                    <Card elevation={6} sx={styleCardContent}>
                        <Typography variant="h6" sx={{
                            textAlign: 'center',
                            fontWeight: 'w800',
                            fontSize: '18px',
                            marginBottom: '10px',
                            marginTop: '10px',
                        }}>
                            {enderecos[i].nome}
                        </Typography>
                        {getJSXforAddress('Logradouro', endereco.logradouro)}
                        <Typography variant="h6" sx={textFontSmall}>
                            {getJSXforAddress('Número', endereco.numero)}
                        </Typography>
                        <Typography variant="h6" sx={textFontSmall}>
                            {getJSXforAddress('Bairro', endereco.bairro)}
                        </Typography>
                        <Typography variant="h6" sx={textFontSmall}>
                            {getJSXforAddress('Cidade', endereco.cidade)}
                        </Typography>
                        <Button sx={{
                            color: '#00c300',
                            fontSize: '0.8rem',
                            marginTop: '10px',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            border: '1px solid #00c300',
                            padding: '5px',
                            width: '90%',
                        }}
                            onClick={() => {
                                localStorage.setItem('address_user', JSON.stringify(endereco))
                                props.onClose()
                                props.onReload()
                            }}
                        >
                            Utilizar endereço
                            <CheckBoxIcon />
                        </Button>
                        <Button sx={{
                            color: 'red',
                            fontSize: '0.8rem',
                            marginTop: '13px',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            border: '1px solid red',
                            padding: '5px',
                            width: '90%',
                        }}
                            onClick={() => {
                                setIdEnderecoForDelete(endereco.id)
                                handleClickOpenDialog()
                            }}
                        >
                            Excluir endereço
                            <DeleteIcon />
                        </Button>
                    </Card>
                </Grid>
            );
            jsx.push(jsxEndereco);
        }
        return jsx
    }

    return (
        <Box sx={{
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Dialog
                open={openDialog}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Deseja excluir o endereço?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Ao aceitar você excluirá o endereço permanentemente.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Não</Button>
                    <Button onClick={async () => {
                        setIsLoading(true);
                        const result = await deleteAddress(idEnderecoForDelete, props.token ?? '');
                        if (result) {
                            Notificacao('Sucesso!', `Endereço excluido com sucesso!`, 'success');
                            if (enderecos!.length === 1) {
                                localStorage.setItem('address_user', '{}')
                            }
                            setReloadAddress(!reloadAddress)
                        } else {
                            Notificacao('Error', `Erro ao excluir o endereço`, 'danger');
                        }
                        handleCloseDialog();
                        setIsLoading(false);
                    }}>Sim</Button>
                </DialogActions>
            </Dialog>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '5px',
                width: '100%',
            }}>
                {
                    createNewAddress || flag === false ? null :
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            alignItems: 'center',
                            textAlign: 'center',
                        }}>
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '98%',
                                marginBottom: '5px',
                                alignItems: 'center',
                            }}>
                                <Typography sx={{
                                    textAlign: 'center',
                                    fontSize: size.x > 700 ? '1rem' : '0.9rem',
                                }}>
                                    Selecione um endereço:
                                </Typography>
                                <Button sx={{
                                    fontSize: size.x > 700 ? '0.9rem' : '0.7rem',
                                    width: '90',
                                    backgroundColor: '#F9146B',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#c11154',
                                    },
                                }} onClick={() => {
                                    setBairroSelecionado('');
                                    setIdCidadeSelecionada('');
                                    setNomeEstadoSelecionado('');
                                    setEndereco({
                                        logradouro: '',
                                        numero: '',
                                        cep: '',
                                        bairro: '',
                                        cidade: '',
                                        complemento: '',
                                        titulo: '',
                                    });
                                    setCreateNewAddress(true);
                                }}>
                                    Cadastrar novo endereço
                                </Button>

                            </Box>
                            <Divider sx={{
                                width: '100%',
                                marginTop: '5px',
                            }} />
                        </div>
                }
            </div>
            <br></br>
            {
                createNewAddress ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        textAlignLast: 'center',
                        width: size.x > 790 ? '100%' : '96%',
                    }}>
                        <Box sx={{
                            width: '100%',
                            marginBottom: '15px',
                        }}>

                            <GooglePlacesAutocomplete

                                //apiKey?: string;                               // default: ''
                                apiOptions={{ language: "pt_BR", region: "br" }}                      // default: { }
                                autocompletionRequest={{
                                    radius: 20000,
                                    types: ["address"],
                                    location: { lat: latAndLng.lat, lng: latAndLng.lng },
                                    componentRestrictions: {
                                        country: ['br'],
                                    }
                                }}
                                debounce={800}                             // default: 300
                                minLengthAutocomplete={4}               // default: 0
                                onLoadFailed={(error) => (
                                    console.error("Could not inject Google script", error)
                                )}       // default: console.error

                                selectProps={{
                                    id: 'google-places-autocomplete',
                                    //defaultInputValue: addessText,
                                    placeholder: "Busque um endereço...",
                                    onChange: async (e: any) => {

                                        await geocodeByPlaceId(e.value.place_id)
                                            .then(async (results) => {

                                                const lat = await results[0].geometry.location.lat();
                                                const lng = await results[0].geometry.location.lng();

                                                setLatAndLng({
                                                    lat: lat,
                                                    lng: lng,
                                                });

                                                let logradouro = '';
                                                let numero = '';
                                                let bairro = '';
                                                let cidade = '';
                                                let cep = '';
                                                let address = await results[0].address_components;

                                                if (address.length > 5) {
                                                    if (address[1] && address[1].long_name) {
                                                        logradouro = address[1].long_name;
                                                    }
                                                    if (address[0] && address[0].long_name) {
                                                        numero = address[0].long_name;
                                                    }
                                                    if (address[2] && address[2].long_name) {
                                                        bairro = address[2].long_name;
                                                    }
                                                    if (address[3] && address[3].long_name) {
                                                        cidade = address[3].long_name;
                                                    }
                                                    if (address[6] && address[6].long_name) {
                                                        cep = address[6].long_name;
                                                    }
                                                } else {
                                                    if (address[1] && address[1].long_name) {
                                                        logradouro = address[1].long_name;
                                                    }
                                                    if (address[0] && address[0].long_name) {
                                                        numero = address[0].long_name;
                                                    }
                                                    if (address[2] && address[2].long_name) {
                                                        cidade = address[2].long_name;
                                                    }
                                                    if (address[5] && address[5].long_name) {
                                                        cep = address[5].long_name;
                                                    }
                                                }

                                                let enderecoFlag: Endereco = {
                                                    logradouro: logradouro,
                                                    numero: numero,
                                                    cep: cep,
                                                    bairro: bairro,
                                                    cidade: cidade,
                                                    complemento: endereco?.complemento ?? '',
                                                    titulo: '',
                                                };

                                                setEndereco(enderecoFlag);

                                            }).catch(error => console.error(error));
                                    },
                                }}

                            //withSessionToken?: boolean;                    // default: false
                            />
                            <TextField
                                id="titulo"
                                variant="outlined"
                                size="small"
                                label="Nome do endereço"
                                style={{
                                    width: '100%',
                                    marginRight: '10px',
                                    fontSize: size.x > 700 ? '0.8rem' : '0.7rem',
                                    marginTop: '20px',
                                    zIndex: 0,
                                }}
                                value={endereco!.titulo}
                                onChange={(e) => {
                                    let valor = e.target.value.length > 0 ? e.target.value : '';
                                    let enderecoFlag = {
                                        logradouro: endereco!.logradouro,
                                        numero: endereco!.numero,
                                        cep: endereco!.cep,
                                        bairro: endereco!.bairro,
                                        cidade: endereco!.cidade,
                                        complemento: endereco?.complemento ?? '',
                                        titulo: valor,
                                    }
                                    setEndereco({
                                        ...enderecoFlag
                                    });
                                }}
                            />
                        </Box>
                        <Box sx={rowInFieldCreateAddress}>
                            <TextField
                                id="rua"
                                variant="outlined"
                                size="small"
                                label="Logradouro"
                                style={{
                                    width: '100%',
                                    marginRight: '10px',
                                    fontSize: size.x > 700 ? '0.8rem' : '0.7rem',
                                    zIndex: 0,
                                }}
                                value={endereco!.logradouro}
                                onChange={(e) => {
                                    let valor = e.target.value.length > 0 ? e.target.value : '';
                                    let enderecoFlag = {
                                        logradouro: valor,
                                        numero: endereco!.numero,
                                        cep: endereco!.cep,
                                        bairro: endereco!.bairro,
                                        cidade: endereco!.cidade,
                                        complemento: endereco?.complemento ?? '',
                                        titulo: endereco!.titulo,
                                    }
                                    setEndereco({
                                        ...enderecoFlag
                                    });
                                }}
                            />
                            <TextField
                                id="numero"
                                variant="outlined"
                                size="small"
                                style={{
                                    width: '100%',
                                    marginLeft: '10px',
                                    fontSize: size.x > 700 ? '0.8rem' : '0.7rem',
                                    zIndex: 0,
                                }}
                                value={endereco!.numero}
                                label="Número"
                                onChange={(e) => {
                                    let valor = e.target.value.length > 0 ? e.target.value : '';
                                    let enderecoFlag = {
                                        logradouro: endereco!.logradouro,
                                        numero: valor,
                                        cep: endereco!.cep,
                                        bairro: endereco!.bairro,
                                        cidade: endereco!.cidade,
                                        complemento: endereco?.complemento,
                                        titulo: endereco!.titulo,
                                    }
                                    setEndereco({
                                        ...enderecoFlag
                                    });
                                }}
                            />
                        </Box>
                        <Box sx={rowInFieldCreateAddress}>
                            <TextField
                                id="complemento"
                                variant="outlined"
                                size="small"
                                style={{
                                    width: '100%',
                                    marginRight: '10px',
                                    fontSize: size.x > 700 ? '0.8rem' : '0.7rem',
                                    zIndex: 0,
                                }}
                                value={endereco!.complemento}
                                label="Complemento"
                                onChange={(e) => {
                                    let valor = e.target.value.length > 0 ? e.target.value : '';
                                    let enderecoFlag = {
                                        logradouro: endereco!.logradouro,
                                        numero: endereco!.numero,
                                        cep: endereco!.cep,
                                        bairro: endereco!.bairro,
                                        cidade: endereco!.cidade,
                                        complemento: valor,
                                        titulo: endereco!.titulo,
                                    }
                                    setEndereco({
                                        ...enderecoFlag
                                    });
                                }}
                            />
                            <TextField
                                id="cep"
                                variant="outlined"
                                size="small"
                                style={{
                                    width: '100%',
                                    marginLeft: '10px',
                                    fontSize: size.x > 700 ? '0.8rem' : '0.7rem',
                                    zIndex: 0,
                                }}
                                value={endereco!.cep}
                                label="CEP"
                                onChange={(e) => {
                                    let valor = '';

                                    if (e.target.value.length > 0) {
                                        if (e.target.value.length <= 9) {
                                            valor = e.target.value;
                                        } else {
                                            valor = e.target.value.substring(0, 9);
                                        }
                                    } else {
                                        valor = ' ';
                                    }

                                    if (valor.substring(0, 2) === 'CE') {
                                        valor = ' ';
                                    } else {
                                        const removeCharactersAndSpecialCharacter = (value: string) => {
                                            return value.replace(/[^0-9]/g, "");
                                        };
                                        valor = removeCharactersAndSpecialCharacter(valor);
                                        if (valor === "") {
                                            valor = ' ';
                                        }
                                    }
                                    if (valor.length > 5) {
                                        valor = valor.substring(0, 5) + '-' + valor.substring(5, 8);
                                    }
                                    let enderecoFlag = {
                                        logradouro: endereco!.logradouro,
                                        numero: endereco!.numero,
                                        cep: valor,
                                        bairro: endereco!.bairro,
                                        cidade: endereco!.cidade,
                                        complemento: endereco?.complemento,
                                        titulo: endereco!.titulo,
                                    }
                                    setEndereco({
                                        ...enderecoFlag
                                    });
                                }}
                            />
                        </Box>
                        <FormControl fullWidth sx={{
                            width: '100%',
                            marginTop: '10px',
                            fontSize: size.x > 700 ? '0.8rem' : '0.7rem',
                            zIndex: 0,
                        }}>
                            <InputLabel id="demo-simple-select-label">Cidade</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={idCidadeSelecionada}
                                onChange={async (e) => {
                                    if (e.target.value) {
                                        setIdCidadeSelecionada(
                                            e.target.value.toString()
                                        );
                                        //await loadBairros(e.target.value.toString());
                                    }
                                }}
                            >
                                {
                                    cities === null ?
                                        <MenuItem value={10}>Erro ao carregar cidades</MenuItem>
                                        : cities.map((cidade) => {
                                            return <MenuItem value={cidade.id}>{cidade.nome}</MenuItem>
                                        })
                                }
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{
                            width: '100%',
                            marginTop: '15px',
                            fontSize: size.x > 700 ? '0.8rem' : '0.7rem',
                            zIndex: 0,
                        }}>
                            {
                                isLoadingBairros ?
                                    <LinearProgress sx={{
                                        width: '100%',
                                    }} />
                                    :
                                    <FormControl fullWidth sx={{
                                        width: '100%',
                                    }}>
                                        <InputLabel id="demo-simple-select-label">Bairro</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={bairroSelecionado}
                                            onChange={(e) => {
                                                if (e.target.value) {
                                                    setBairroSelecionado(e.target.value.toString());
                                                }
                                            }}
                                        >
                                            {
                                                bairrosCarregados === null ?
                                                    <MenuItem value={10}>Erro ao carregar bairros</MenuItem>
                                                    : bairrosCarregados.map((bairro) => {
                                                        return <MenuItem value={bairro.id} onClick={() => {
                                                            setBairroSelecionado(bairro.id.toString());
                                                        }}>{bairro.nome}</MenuItem>
                                                    })
                                            }
                                        </Select>
                                    </FormControl>
                            }
                        </FormControl>
                        <br></br>
                        {
                            isLoadingBairros ?
                                <LinearProgress sx={{
                                    width: '100%',
                                }} />
                                : <OpenMap
                                    largura={size.x > 790 ? size.x * 0.66 : size.x}
                                    center={latAndLng}
                                    setCenter={setLatAndLng}
                                />
                        }
                        <Button sx={{
                            backgroundColor: '#F9146B',
                            width: '100%',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#c11154',
                            },
                            marginTop: '25px',
                            marginBottom: '10px',
                        }} onClick={async () => {
                            function sendMensageForSnackbar(mensagem: string) {
                                setMensagemForSnackbar(mensagem);
                                handleClick();
                            }

                            if (bairroSelecionado.length === 0) {
                                sendMensageForSnackbar('Pro favor, informe o bairro');
                            }
                            else if (endereco?.logradouro.length < 2) {
                                sendMensageForSnackbar('Pro favor, informe o nome da rua');;
                            }
                            else if (endereco?.numero.length < 1) {
                                sendMensageForSnackbar('Pro favor, informe o número do local')
                            }
                            else if (endereco?.cep.length < 9) {
                                sendMensageForSnackbar('Pro favor, informe o CEP')
                            }
                            else if (idCidadeSelecionada.length === 0) {
                                sendMensageForSnackbar('Pro favor, informe a cidade');
                            }
                            else if (endereco.titulo.length < 3) {
                                sendMensageForSnackbar('Pro favor, informe o nome deste endereço');
                            }
                            else {
                                if (endereco) {
                                    setIsLoading(true);
                                    const response = await cadastrarAddress({
                                        "nome": endereco.titulo,
                                        "logradouro": endereco.logradouro.toString(),
                                        "numero": endereco.numero.toString(),
                                        "bairro": getBairroSelecionadoByID(bairroSelecionado),
                                        "cep": endereco.cep.toString(),
                                        "complemento": '',
                                        "estado": nomeEstadoSelecionado,
                                        "cidade": itCidadeToNome(idCidadeSelecionada),
                                        "referencia": endereco.complemento.toString(),
                                        "latitude": latAndLng.lat.toString(),
                                        "longitude": latAndLng.lng.toString(),
                                        "tipo": 1,
                                        "nome_empresa": "",
                                        "clientes_id": props.entity_id.toString(),
                                    } as PayloadAddress,
                                        props.token ?? ''
                                    );
                                    if (response) {
                                        sendMensageForSnackbar('Endereço salvo com sucesso');
                                        setCreateNewAddress(false);
                                        setIsLoading(false);
                                        setReloadAddress(!reloadAddress)
                                    } else {
                                        sendMensageForSnackbar('Erro ao salvar endereço');
                                    }
                                }
                            }

                        }}>
                            Salvar endereço
                        </Button>
                        <Snackbar
                            open={open}
                            autoHideDuration={6000}
                            onClose={handleClose}
                            message={mensagemForSnackbar}
                            action={action}
                        />
                    </div> : isLoading ? <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}>
                        <Typography gutterBottom component="h2" style={{
                            textAlign: 'center',
                            marginTop: '10px',
                            marginBottom: '20px',
                            fontSize: '1.3rem',
                        }}>
                            Carregando endereços...
                        </Typography>
                        <CircularProgress sx={{
                            color: '#F9146B',
                            marginBottom: '30px',
                        }} />
                    </Box> : <Box sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                    }}>
                        {
                            flag && enderecos != null ? <Grid container spacing={0}>
                                {
                                    enderecos.length === 0 ? <Box sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'start',
                                        alignItems: 'start',
                                        alignContent: 'start',
                                        marginLeft: '10px',
                                    }}>
                                        <Typography variant="h6" sx={{
                                            marginTop: '10px',
                                        }}>
                                            Você ainda não possui nenhum endereço cadastrado
                                        </Typography>
                                        <Typography sx={{
                                            marginTop: '5px',
                                            marginBottom: '30px',
                                            fontSize: '1rem',
                                        }}>
                                            Esta etapa é importante para que possamos entregar seus pedidos
                                        </Typography>
                                    </Box> : enderecos ? _generateAddress(enderecos) : null
                                }
                            </Grid> : cities === null ? <Typography>
                                Erro ao carregar cidades
                            </Typography> : <Grid container spacing={0}>
                                {
                                    cities.map((city, index) => {
                                        return (
                                            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                                <Card elevation={6} sx={styleCardContent}>
                                                    <Typography variant="h6" sx={{
                                                        textAlign: 'center',
                                                        fontWeight: 'w800',
                                                        fontSize: '18px',
                                                        marginBottom: '10px',
                                                        marginTop: '10px',
                                                    }}>
                                                        {city.nome.length > 20 ? city.nome.substring(0, 20) + '...' : city.nome}
                                                    </Typography>
                                                    {getJSXforAddress('Nome', city.nome)}
                                                    <Typography variant="h6" sx={textFontSmall}>
                                                        {getJSXforAddress('Estado', city.sigla)}
                                                    </Typography>
                                                    <Button sx={{
                                                        color: '#00c300',
                                                        fontSize: '14px',
                                                        marginTop: '10px',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        border: '1px solid #00c300',
                                                        padding: '5px',
                                                        width: '90%',
                                                    }}
                                                        onClick={() => {
                                                            const url = `/empresas/cidade/${city.id}/${city.nome}`
                                                            window.location.href = url
                                                        }}
                                                    >
                                                        Utilizar endereço
                                                        <CheckBoxIcon />
                                                    </Button>
                                                </Card>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        }
                        <Snackbar
                            open={open}
                            autoHideDuration={6000}
                            onClose={handleClose}
                            message={mensagemForSnackbar}
                            action={action}
                        />
                    </Box>
            }
        </Box>
    )
}

export default ModalEnderecos