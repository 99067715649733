import { useState } from "react";
import entregador from "../../assets/images/contacless-delivery-service-quarantine-man-delivers-food-shopping-bags-insulation-emotions-deliveryman-isolated-yellow.png";

const Entregador = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <div className="flex flex-row justify-center items-center pt-10 pb-16 lg:pt-24 lg:pb-14">
      <div className="mx-auto max-w-screen-xl flex flex-col-reverse lg:flex-row lg:gap-20">
        {/* Bloco de Texto */}
        <div className="w-full px-4  lg:w-1/2">
          <div className="flex flex-col gap-3 lg:gap-1 mt-10 lg:mt-0">
            <span className="block mb-4 text-lg font-semibold text-[#E5CC2C]">
              Entregador
            </span>
            <h2 className="mb-5 text-3xl font-bold text-black sm:text-[40px]/[48px]">
              Seja um Entregador Vokerê
            </h2>
            <p className="mb-5 text-[#637381] text-justify text-body-color dark:text-dark-6">
              Ganhe dinheiro fazendo entregas na sua região,{" "}
              <b className="text-[#F9146B]">usando seu veículo</b> e{" "}
              <b className="text-[#F9146B]">trabalhando no seu próprio ritmo</b>
              . Além disso, você{" "}
              <b className="text-[#F9146B]">recebe pagamentos quinzenais</b>,
              garantindo sempre uma renda constante. Veja os requisitos
              necessários para ser um entregador do Vokerê:
            </p>

            {/* Requisitos */}
            <div className="flex gap-5 items-center mb-8">
              <div
                className="flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
                style={{ backgroundColor: "rgba(249, 20, 107, 0.19)" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2rem"
                  height="2rem"
                  viewBox="0 0 14 14">
                  <path
                    fill="#F9146B"
                    fillRule="evenodd"
                    d="M4 6.5H1a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1m4-4.25a.75.75 0 0 0 0 1.5h.75v6.961a2.501 2.501 0 0 1 4.244 1.958C13.621 12.449 14 11.806 14 11c0-1.927-1.58-3.621-3.75-3.743v-.958c.22.128.477.201.75.201h1a.5.5 0 0 0 .5-.5V4a.5.5 0 0 0-.5-.5h-1c-.273 0-.53.073-.75.2V3a.75.75 0 0 0-.75-.75zm.01 10.5a2.5 2.5 0 0 1 .321-1.5H8.25a.75.75 0 0 1-.75-.75V10a2.75 2.75 0 0 0-2.75-2.75h-2A2.75 2.75 0 0 0 0 10v2c0 .414.336.75.75.75h.26a2.501 2.501 0 1 1 4.977 0zm-6.012-.251a1.501 1.501 0 1 0 3.002 0a1.501 1.501 0 0 0-3.002 0m7 0a1.501 1.501 0 1 0 3.002 0a1.501 1.501 0 0 0-3.002 0"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <p className="flex-1 text-[#637381] text-justify font-medium">
                Possuir um veículo próprio que esteja disponível para realizar
                entregas.
              </p>
            </div>

            <div className="flex gap-5 items-center mb-8">
              <div
                className="flex h-[70px] w-[70px] items-center justify-center rounded-2xl"
                style={{ backgroundColor: "rgba(249, 20, 107, 0.19)" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2rem"
                  height="2rem"
                  viewBox="0 0 24 24">
                  <path
                    fill="#F9146B"
                    d="M2 3h20c1.05 0 2 .95 2 2v14c0 1.05-.95 2-2 2H2c-1.05 0-2-.95-2-2V5c0-1.05.95-2 2-2m12 3v1h8V6zm0 2v1h8V8zm0 2v1h7v-1zm-6 3.91C6 13.91 2 15 2 17v1h12v-1c0-2-4-3.09-6-3.09M8 6a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3"
                  />
                </svg>
              </div>
              <p className="flex-1 text-[#637381] text-justify font-medium">
                Estar em posse de uma Carteira Nacional de Habilitação (CNH)
                válida e dentro do prazo de validade.
              </p>
            </div>
            <div className="w-full border border-b-2 "></div>
            <div>
              <button
                type="button"
                onClick={toggleModal}
                className="text-white bg-[#F9146B]  font-medium rounded-full text-sm justify-center px-4 py-2.5 text-center inline-flex items-center mt-5 w-full lg:w-auto">
                Começar a Entregar
              </button>
            </div>
          </div>
        </div>

        {/* Bloco de Imagem */}
        <div className="lg:w-1/2 flex justify-center items-center">
          <div className="flex justify-center items-end w-[350px] h-[350px] md:w-[600px] md:h-[450px] lg:w-[461.21px] lg:h-[508.17px] bg-gradient-to-br from-[#f7e64e] to-[#f1de01] rounded-[20px]">
            <img
              src={entregador}
              alt="Mulher de negócios"
              className="h-[90%]"
            />
          </div>
        </div>
      </div>

      <>
        {isModalOpen && (
          <>
            {/* Backdrop */}
            <div
              className="fixed inset-0 bg-black bg-opacity-50 z-40"
              onClick={toggleModal}
            />

            <div
              id="crypto-modal"
              tabIndex={-1}
              aria-hidden="true"
              className="fixed inset-0 z-50 flex justify-center items-center w-full h-full overflow-y-auto">
              <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow ">
                  <div className="flex items-center justify-between p-4 border-b ">
                    <h3 className="text-lg font-semibold text-gray-900">
                      Selecione a Cidade
                    </h3>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-[#F9146B] rounded-lg h-8 w-8 flex items-center justify-center "
                      onClick={toggleModal}>
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14">
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="p-4">
                    <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                      Por favor, escolha a cidade que você deseja para dar
                      início às suas entregas.
                    </p>
                    <ul className="my-4 space-y-3 max-h-[400px] overflow-y-scroll">
                      <li>
                        <a
                          href="https://api.whatsapp.com/send?phone=5599991547847"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center p-3 text-base font-bold text-gray-600 rounded-lg bg-gray-50 hover:bg-gray-100 ">
                          Açailandia - MA
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://api.whatsapp.com/send?phone=5562998463641"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center p-3 text-base font-bold text-gray-600 rounded-lg bg-gray-50 hover:bg-gray-100 ">
                          Aparecida de Goiania - GO
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://api.whatsapp.com/send?phone=5563991094817"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center p-3 text-base font-bold text-gray-600 rounded-lg bg-gray-50 hover:bg-gray-100 ">
                          Araguaina - TO
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://api.whatsapp.com/send?phone=5599991045490&text=Quero%20Ser%20Entregador"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center p-3 text-base font-bold text-gray-600 rounded-lg bg-gray-50 hover:bg-gray-100 ">
                          Balsas - MA
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://api.whatsapp.com/send?phone=5563992921098"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center p-3 text-base font-bold text-gray-600 rounded-lg bg-gray-50 hover:bg-gray-100 ">
                          Colinas - TO
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://api.whatsapp.com/send?phone=5589994366243"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center p-3 text-base font-bold text-gray-600 rounded-lg bg-gray-50 hover:bg-gray-100 ">
                          Floriano - PI
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://api.whatsapp.com/send?phone=5599985096702"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center p-3 text-base font-bold text-gray-600 rounded-lg bg-gray-50 hover:bg-gray-100 ">
                          Imperatriz - MA
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://api.whatsapp.com/send?phone=5577999090404"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center p-3 text-base font-bold text-gray-600 rounded-lg bg-gray-50 hover:bg-gray-100 ">
                          Luiz Eduardo Magalhães - BA
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://api.whatsapp.com/send?phone=5594991832716"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center p-3 text-base font-bold text-gray-600 rounded-lg bg-gray-50 hover:bg-gray-100 ">
                          Maraba - PA
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://api.whatsapp.com/send?phone=5563992963915"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center p-3 text-base font-bold text-gray-600 rounded-lg bg-gray-50 hover:bg-gray-100 ">
                          Palmas - TO
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://api.whatsapp.com/send?phone=5594974006794"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center p-3 text-base font-bold text-gray-600 rounded-lg bg-gray-50 hover:bg-gray-100 ">
                          Paraupebas - PA
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://api.whatsapp.com/send?phone=5594992945184"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center p-3 text-base font-bold text-gray-600 rounded-lg bg-gray-50 hover:bg-gray-100 ">
                          Redenção - PA
                        </a>
                      </li>

                      <li>
                        <div className="flex items-center gap-3 p-3 text-base font-bold text-gray-600 rounded-lg bg-gray-50 cursor-not-allowed ">
                          Salvador - BA
                          <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                            <span className="w-2 h-2 me-1 bg-red-500 rounded-full"></span>
                            Indisponível
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default Entregador;
