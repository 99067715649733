import { useState } from "react";
import { ContainedButton } from "../button";
import logo from "../../../assets/images/logo1.png";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-[#F7E64E]">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a
          href="https://euvokere.com.br/"
          className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={logo} className="h-8" alt="Vokerê Logo" />
        </a>

        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          <ContainedButton content="Fazer Pedido" to="/login" />
          <button
            onClick={toggleMenu}
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-[#9d920a] rounded-lg md:hidden hover:bg-[#cabf20a6] focus:outline-none focus:ring-2 focus:ring-[#9d920a]"
            aria-controls="navbar-cta"
            aria-expanded={isMenuOpen}>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        <div
          className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${
            isMenuOpen ? "block" : "hidden"
          }`}
          id="navbar-cta">
          {/* Lista de navegação para Computador */}
          <ul
            className={`${
              isMenuOpen ? "flex" : "hidden"
            } flex-col items-center md:flex-row justify-center gap-4 lg:gap-8 text-[#9d920a] font-medium hidden md:flex md:order-1`}>
            {[
              "Home",
              "Entregador",
              "Parceiro",
              "Trabalhe Conosco",
              "Sobre",
            ].map((text, index) => (
              <li key={index}>
                <NavLink
                  to={`/${text.toLowerCase().replace(/\s+/g, "-")}`}
                  className={({ isActive }) =>
                    isActive
                      ? "text-[#625A06] font-extrabold relative after:content-[''] after:absolute after:left-1/2 after:bottom-[-2px] after:h-[2px] after:w-full after:bg-[#625A06] after:transform after:scale-x-100 after:translate-x-[-50%] after:transition-transform after:duration-300"
                      : "relative after:content-[''] after:absolute after:left-1/2 after:bottom-[-2px] after:h-[2px] after:w-full after:bg-[#625A06] after:transform after:scale-x-0 after:translate-x-[-50%] after:transition-transform after:duration-300 hover:text-[#625A06] hover:after:scale-x-100 transition-colors duration-300"
                  }
                  end>
                  {text}
                </NavLink>
              </li>
            ))}
          </ul>

          {/* Lista de navegação para Celular */}
          <ul className="flex flex-col gap-1 font-medium p-4 md:p-0 mt-4 rounded-lg bg-[#9d920a] md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:bg-white md:hidden">
            {[
              "Home",
              "Entregador",
              "Parceiro",
              "Trabalhe Conosco",
              "Sobre",
            ].map((text, index) => (
              <li key={index}>
                <NavLink
                  to={`/${text.toLowerCase().replace(/\s+/g, "-")}`}
                  className={({ isActive }) =>
                    isActive
                      ? "block py-2 px-3 md:p-0 text-white bg-[#F9146B] rounded"
                      : "block py-2 px-3 md:p-0 text-white rounded hover:bg-[#f9146cb4]"
                  }
                  onClick={toggleMenu}>
                  {text}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
