import { NavLink } from "react-router-dom";
import funcionario from "../../assets/images/time-vokere.png";
import { DisabledButton } from "../../components/V2/button";

export const TrabalheConosco = () => {
  return (
    <div className="flex flex-row justify-center items-center  pt-10 pb-16 lg:pt-24 lg:pb-14">
      <div className="mx-auto max-w-screen-xl flex flex-col-reverse lg:flex-row lg:gap-20">
        {/* Bloco de Texto */}
        <div className="flex flex-col w-full px-4 lg:w-1/2">
          <div className="mt-10 lg:mt-0">
            <span className="block mb-4 text-lg font-semibold text-[#E5CC2C]">
              Trabalhe Conosco
            </span>
            <h2 className="mb-5 text-3xl font-bold text-black sm:text-[40px]/[48px]">
              Faça Parte do Time Vokerê
            </h2>
            <div className="py-5">
              <p className="mb-5 text-[#637381] text-justify text-body-color dark:text-dark-6">
                O Vokerê é uma empresa em rápido crescimento e estamos sempre em
                busca de novos talentos para se juntar à nossa equipe, aqui
                oferecemos oportunidades de crescimento e desenvolvimento de
                carreira, além de um ambiente de trabalho descontraído e
                colaborativo. Venha fazer parte do nosso time!
              </p>
              <p className="mb-5 text-[#637381] text-justify text-body-color dark:text-dark-6">
                Se você é apaixonado por tecnologia, comida e serviços de
                entrega, nós queremos ouvir de você. Estamos em busca de pessoas
                comprometidas e dinâmicas para trabalhar em diversas áreas, como
                atendimento ao cliente, logística, tecnologia e marketing.
              </p>
              <p className="mb-5 text-[#637381] text-justify text-body-color dark:text-dark-6">
                Se você está interessado em se juntar à nossa equipe, envie seu
                currículo para nosso banco de talentos e novas oportunidades
                você será contato por um de nossos canais de atendimento.
              </p>
            </div>
          </div>

          {/* <NavLink
            to={"/formTrabalheConosco"}
            className="flex justify-center bg-[#F9146B] rounded-full text-white px-4 py-2.5 font-medium ">
            Cadastrar Currículo
          </NavLink>
         */}

          <DisabledButton content={"Trabalhe Conosco"} />
        </div>

        {/* Bloco de Imagem */}
        <div className="lg:w-1/2 flex justify-center items-center">
          <div className="flex justify-center items-end w-[350px] h-[350px] md:w-[600px] md:h-[450px] lg:w-[461.21px] lg:h-[508.17px] bg-gradient-to-br from-[#f7e64e] to-[#f1de01] rounded-[20px]">
            <img
              src={funcionario}
              alt="Imagem fictícia de um atendente do Vokerê"
              className="h-[90%]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
