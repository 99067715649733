import { Box } from "@mui/material";
import { useMemo, useRef } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";

import "./styles.css";

interface Props {
  largura: number;
  center: {
    lat: number;
    lng: number;
  };
  setCenter: (center: { lat: number; lng: number }) => void;
}

export default function App(props: Props) {
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker: any = markerRef.current;
        if (marker != null) {
          props.setCenter(marker.getLatLng());
        }
      }
    }),
    []
  );


  return (
      <Box position='relative' width='100%' height='100%'>
        <MapContainer center={[props.center.lat, props.center.lng]} zoom={14} scrollWheelZoom={false} style={{
          height: '50vh',
        }}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            position={props.center}
            draggable
            eventHandlers={eventHandlers}
            ref={markerRef}
          />
        </MapContainer>
      </Box>
  );
}
 