import Notificacao from "../../../components/notification";
import { getUrlAPIv1 } from "../api";
import { Root as UserModel } from "../model/user.model";

export const makeLogin = async (email: string, password: string) : Promise<UserModel | null>  => {
    const url = `${getUrlAPIv1}/login`;
    const payload = {
        "email": email,
        "password": password,
        'phoneToken': null
      };
    const response = await fetch(url, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    });
    const data = await response.json();
    if (response.status === 200){
        return data;
    } else {
        const mensagem = data.error;
        Notificacao('Error', mensagem, 'danger');
    }
    return null;
} 
