import { Box, Button, CircularProgress, Typography } from "@mui/material"
import { useEffect, useState } from "react";

import { ModalCustom } from '../../../../../components/modal';
import Notificacao from '../../../../../components/notification';
import { useCart } from '../../../context/cartcontext';
import getIndividualProd from '../../../controllers/getIndividualProd';
import { Especificac, Root as ProdutoModel } from '../../../model/produto.model';
import FALLBACK_IMAGE from '../../../onerror.png';
import OpenImage from '../abrirImagem/modalOpenImage';
import { getProdutoMontado, getProdutoNaoMontado } from './produto';

interface Props {
    produto: ProdutoModel | null;
    idProduto: number | null;
    stateFunction: Function;
    onlyView: boolean;
    empresaIsOpen: boolean;
    isRetirada: boolean;
    handleClose: Function;
    isOnCarrinho: boolean;
    cidade_nome: string;
    isPromotion: boolean;
    slugEmpresa: string;
}

const ModalProdutos = (props: Props) => {

    const { cart, setCartAndSave } = useCart()

    const onMediaFallback = (event: any) => event.target.src = FALLBACK_IMAGE;
    const [isAllDisabled, setIsAllDisabled] = useState(false);
    const [produto, setProduto] = useState<ProdutoModel | null>(props.produto);
    const [isLoadingProduto, setIsLoadingProduto] = useState(true);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    async function loadProduto() {
        setIsLoadingProduto(true);
        if (props.produto) {
            setProduto(props.produto);
        } else {
            if (props.idProduto) {
                const response = await getIndividualProd(props.idProduto);
                setProduto(response);
            }
        }
        setIsLoadingProduto(false);
    }


    useEffect(() => {
        loadProduto()
    }, []);


    const [size, setSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
    });
    const updateSize = () =>
        setSize({
            x: window.innerWidth,
            y: window.innerHeight
        });
    useEffect(() => (window.onresize = updateSize), []);

    useEffect(() => {
        let especificacaoFlag = 0;
        if (produto && produto.especificacoes) {
            produto.especificacoes.map((especificacao) => {
                if (especificacao.ativo === 0) {
                    especificacaoFlag++;
                }
            });
            if (especificacaoFlag === produto.especificacoes.length) {
                setIsAllDisabled(true);
            }
        }
    }, []);


    function verifyIfBusinessProductIsInCart(product: ProdutoModel): boolean {

        function getDate() {
            let data = new Date();
            let dia = String(data.getDate()).padStart(2, '0');
            let mes = String(data.getMonth() + 1).padStart(2, '0');
            let ano = data.getFullYear();
            let hour = data.getHours().toString();
            let minute = data.getMinutes().toString();
            let second = data.getSeconds().toString();
            if (hour.length < 2) {
                hour = '0' + hour
            }
            if (minute.length < 2) {
                minute = '0' + minute
            }
            if (second.length < 2) {
                second = '0' + second
            }

            return ano + '/' + mes + '/' + dia + ' ' + hour + ':' + minute + ':' + second;
        }

        const produtoFlag = carregarGambiarraQueOBrunoFezQueConverteOsProdutosEmString(product);

        let result = false;
        if (cart.length > 0) {
            if (cart[0].empresas_id === produtoFlag.empresas_id) {
                result = true;
            }
            else {
                result = false;
            }
        } else {
            result = true;
        }

        if (props.isPromotion) {
            Notificacao('Atenção!', `Você não pode adicionar um produto de promoção no carrinho, essa função ainda não está disponível. Baixe o APP para aproveitar todos os recursos.`, 'danger');
            return false;
        }

        if (result) {
            setCartAndSave([...cart, {
                ...produtoFlag,
                product_promotion_id: produtoFlag.product_promotion_id ?? 0,
                id_promocao: produtoFlag.id_promocao ?? 0,
                quantidade: produtoFlag.quantidade ?? 1,
                observacao: produtoFlag.observacao ?? '',
                opcionais: produtoFlag.opcionais ?? '',
                isRetirada: produtoFlag.isRetirada,
                cidade_nome: props.cidade_nome,
                hora_atual: getDate(),
                slugEmpresa: props.slugEmpresa,
            } as ProdutoModel]);
            Notificacao('Sucesso!', `Produto adicionado no carrinho`, 'success');
            return true;
        } else {
            Notificacao('Atenção!', `Você já possui um produto de outra empresa no carrinho.`, 'danger');
            return true;
        }
    }

    function carregarGambiarraQueOBrunoFezQueConverteOsProdutosEmString(listEspecificacoesProdNew: ProdutoModel): ProdutoModel {
        let _valorProd = 0.0;

        if (listEspecificacoesProdNew.especificacoes && listEspecificacoesProdNew.especificacoes.length === 0) {
            _valorProd = listEspecificacoesProdNew.preco;
        }
        if (listEspecificacoesProdNew.especificacoes && listEspecificacoesProdNew.especificacoes.length > 0) {
            listEspecificacoesProdNew.descricao = "";
        }

        if (listEspecificacoesProdNew.especificacoes) {
            for (let indexEsp in listEspecificacoesProdNew.especificacoes) {
                if (!listEspecificacoesProdNew.especificacoes[indexEsp].opcoes) {
                    break;
                }
                for (let indexOpc in listEspecificacoesProdNew.especificacoes[indexEsp].opcoes!) {
                    _valorProd += listEspecificacoesProdNew.especificacoes[indexEsp].opcoes![indexOpc].valor
                    listEspecificacoesProdNew.descricao += `${listEspecificacoesProdNew.especificacoes[indexEsp].opcoes![indexOpc].nome}, `;
                }
                listEspecificacoesProdNew.descricao =
                    listEspecificacoesProdNew.descricao + ", ";

                listEspecificacoesProdNew.descricao = listEspecificacoesProdNew
                    .descricao
                    .substring(0, listEspecificacoesProdNew.descricao.length - 2);
            }
        }

        if (listEspecificacoesProdNew.especificacoes && listEspecificacoesProdNew.especificacoes.length > 0) {
            listEspecificacoesProdNew.descricao = listEspecificacoesProdNew
                .descricao
                .substring(0, listEspecificacoesProdNew.descricao.length - 2);
            //   if (listEspecificacoesProdNew.preco_promocional != null) {
            //     listEspecificacoesProdNew.preco_promocional =
            //     listEspecificacoesProdNew.quantidade *
            //     listEspecificacoesProdNew.preco_anterior!;
            //   }
        }

        // if (listEspecificacoesProdNew.preco_promocional != null) {
        //     listEspecificacoesProdNew.preco_promocional =
        //     listEspecificacoesProdNew.quantidade *
        //     listEspecificacoesProdNew.preco_anterior!;
        // }

        // listEspecificacoesProdNew.precoTotal = _valorProd;
        // listEspecificacoesProdNew.preco = _valorProd;
        // listEspecificacoesProdNew.quantidade = getx.getQuantidade();
        // listEspecificacoesProdNew.observacao = controllerObcervacao.text;

        return listEspecificacoesProdNew;
    }


    function handleSelectItem(product: ProdutoModel): boolean {
        let especificacoesSelecionadas = [] as Especificac[];
        if (product.especificacoes) {
            for (let especificacaoIndex = 0; especificacaoIndex < product.especificacoes.length; especificacaoIndex++) {
                let quantidadeSelecionada = 0;
                for (let opcaoIndex = 0; opcaoIndex < product.especificacoes[especificacaoIndex].opcoes!.length; opcaoIndex++) {
                    if (product.especificacoes[especificacaoIndex].opcoes![opcaoIndex].selecionado === true) {
                        quantidadeSelecionada++;
                    }
                }
                const minAnMaxAreSame = product.especificacoes[especificacaoIndex].max_itens === 1 && product.especificacoes[especificacaoIndex].min_itens === 1;
                if (minAnMaxAreSame) {
                    if (quantidadeSelecionada === 0) {
                        const nomeEspecificacao = product.especificacoes[especificacaoIndex].nome;
                        Notificacao('Atenção', `Você deve selecionar no mínimo ${product.especificacoes[especificacaoIndex].max_itens} opções para a especificação ${nomeEspecificacao}`, 'danger');
                        return false;
                    }
                }
                const minItens = product.especificacoes[especificacaoIndex].min_itens;
                if (quantidadeSelecionada < minItens) {
                    const nomeEspecificacao = product.especificacoes[especificacaoIndex].nome;
                    //window.alert(`Você deve selecionar no mínimo ${minItens} opções para a especificação ${nomeEspecificacao}`);
                    Notificacao('Atenção', `Você deve selecionar no mínimo ${minItens} opções para a especificação ${nomeEspecificacao}`, 'danger');
                    return false;
                }
                especificacoesSelecionadas = [...especificacoesSelecionadas, {
                    id: product.especificacoes[especificacaoIndex].id,
                    nome: product.especificacoes[especificacaoIndex].nome,
                    min_itens: product.especificacoes[especificacaoIndex].min_itens,
                    max_itens: product.especificacoes[especificacaoIndex].max_itens,
                    ativo: product.especificacoes[especificacaoIndex].ativo,
                    produtos_id: product.especificacoes[especificacaoIndex].produtos_id,
                    descricao: product.especificacoes[especificacaoIndex].descricao,
                    opcoes: product.especificacoes[especificacaoIndex].opcoes!.filter((opcao) => opcao.selecionado === true),
                } as Especificac];

            }
        }
        const produtoFlag = {
            ...product,
            especificacoes: especificacoesSelecionadas
        } as any;

        const value = verifyIfBusinessProductIsInCart(produtoFlag);
        return value;
    }

    function getPriceOfProduct(): number {

        if (!produto) {
            return 0;
        }

        if (produto?.especificacoes && produto?.especificacoes.length > 0) {
            let preco = 0;
            for (let indexEsp = 0; indexEsp < produto?.especificacoes.length; indexEsp++) {
                const especificacao = produto?.especificacoes[indexEsp];
                if (especificacao && especificacao?.opcoes) {
                    for (let indexOpc = 0; indexOpc < especificacao?.opcoes.length; indexOpc++) {
                        const opcao = especificacao?.opcoes?.[indexOpc];
                        if (opcao?.selecionado) {
                            preco += opcao?.valor;
                        }
                    }
                }
            }
            return preco;
        } else {
            return produto!.preco;
        }
    }

    function btnEscolherQuantidade(): JSX.Element {

        function getPrice(): JSX.Element {
            return <Typography sx={{
                fontSize: '1.5rem',
                marginLeft: '10px',
            }}>
                {
                    (getPriceOfProduct() * (produto?.quantidade ? produto?.quantidade : 1)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                }
            </Typography>;
        }

        return <Box sx={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            {
                size.x < 700 ?
                    <div style={{
                        marginBottom: '10px',
                    }}>
                        {getPrice()}
                    </div> : null
            }
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                margin: 0,
                padding: 0,
            }}>
                {
                    size.x > 700 ? getPrice() : null
                }
                <Box sx={{
                    width: size.x > 700 ? 'auto' : '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingLeft: size.x > 700 ? '10px' : '8px',
                    paddingRight: size.x > 700 ? '10px' : '8px',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    backgroundColor: '#f0F0F0',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (produto!.quantidade > 1) {
                                const quantidade = produto!.quantidade - 1;
                                setProduto(
                                    {
                                        ...produto!,
                                        quantidade: quantidade,
                                        precoByQuantidade: getPriceOfProduct() * quantidade,
                                    }
                                )
                            }
                        }}
                        sx={{
                            fontSize: '1.4rem',
                            height: '30px',
                            textAlign: 'center',
                            alignItems: 'center',
                            width: '20%',
                        }}
                    >
                        -
                    </Button>
                    <Typography sx={{
                        fontSize: '1.4rem',
                        marginLeft: '20px',
                        marginRight: '20px',
                        width: '30%',
                        textAlign: 'center',
                        alignItems: 'center',
                    }}>
                        {produto?.quantidade ?? 1}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            const quantidade = produto?.quantidade ? produto?.quantidade + 1 : 2;
                            setProduto({
                                ...produto!,
                                quantidade: quantidade,
                                precoByQuantidade: getPriceOfProduct() * quantidade,
                            })
                        }}
                        sx={{
                            fontSize: '1.4rem',
                            height: '30px',
                            textAlign: 'center',
                            alignItems: 'center',
                            width: '20%',
                        }}
                    >
                        +
                    </Button>
                </Box>
            </Box>
        </Box>;
    }

    return (
        isLoadingProduto ?
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}>
                <Typography gutterBottom component="h2" style={{
                    textAlign: 'center',
                    marginTop: '10px',
                    marginBottom: '20px',
                    fontSize: '1.3rem',
                }}>
                    Carregando produto...
                </Typography>
                <CircularProgress sx={{
                    color: '#F9146B',
                    marginBottom: '30px',
                }} />
            </Box>
            :
            <Box>
                {
                    produto ?
                        (
                            <Box>
                                <ModalCustom
                                    open={open}
                                    title='Imagem'
                                    onClose={handleClose}
                                    actions={null}
                                    isMobile={size.x < 700}
                                    slug={''}
                                >
                                    {
                                        OpenImage({
                                            imagem: produto.foto_path === null ? FALLBACK_IMAGE : produto.foto,
                                            onClose: handleClose,
                                        })
                                    }
                                </ModalCustom>
                                {
                                    produto.especificacoes && produto.especificacoes.length > 0 ?
                                        getProdutoMontado({
                                            produto: produto,
                                            stateFunction: props.stateFunction,
                                            empresaIsOpen: props.empresaIsOpen,
                                            open: open,
                                            handleClose: handleClose,
                                            handleOpen: handleOpen,
                                            setOpen: setOpen,
                                            isAllDisabled: isAllDisabled,
                                            onMediaFallback: onMediaFallback,
                                            setProduto: (p: ProdutoModel) => setProduto(p),
                                            onlyView: props.onlyView,
                                            btnEscolherQuantidade: btnEscolherQuantidade,
                                            handleSelectItem: handleSelectItem,
                                            FALLBACK_IMAGE: FALLBACK_IMAGE,
                                            handleCloseModal: props.handleClose,
                                            isOnCarrinho: props.isOnCarrinho,
                                        }) :
                                        getProdutoNaoMontado({
                                            produto: produto,
                                            setProduto: setProduto,
                                            handleSelectItem: handleSelectItem,
                                            btnEscolherQuantidade: btnEscolherQuantidade,
                                            onlyView: props.onlyView,
                                            empresaIsOpen: props.empresaIsOpen,
                                            verifyIfBusinessProductIsInCart: (p: ProdutoModel) => verifyIfBusinessProductIsInCart(p),
                                            handleClose: props.handleClose,
                                            isOnCarrinho: props.isOnCarrinho,
                                        })
                                }
                            </Box>
                        ) : <>Erro ao encontrar produto</>
                }
            </Box>
    );
}

export default ModalProdutos
