import React, { createContext, useContext, useEffect, useState } from 'react';

import { Root as ProdutoModel } from '../model/produto.model';

const CartContext = createContext({} as any);

interface useCartProps {
    cart: ProdutoModel[];
    setCart: React.Dispatch<React.SetStateAction<ProdutoModel[]>>;
    setCartAndSave: (produto: ProdutoModel[]) => void;
}


export const CartProvider = ({ children }: any) => {
    const [cart, setCart] = useState<ProdutoModel[]>([]);
    
    function setCartAndSave(produtos: ProdutoModel[]) {
        setCart(produtos);
        localStorage.setItem('cart', JSON.stringify(produtos));
    }

    function getDate() {
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        let hour = data.getHours().toString();
        let minute = data.getMinutes().toString();
        let second = data.getSeconds().toString();
        if (hour.length < 2) {
            hour = '0' + hour
        }
        if (minute.length < 2) {
            minute = '0' + minute
        }
        if (second.length < 2) {
            second = '0' + second
        }

        return ano + '/' + mes + '/' + dia + ' ' + hour + ':' + minute + ':' + second;
    }

    function getMinutesBetweenDates(date1: string, date2: string) {
        const data1 = new Date(date1).getTime();
        const data2 = new Date(date2).getTime();
        const diff = Math.abs(data1 - data2);
        const minutes = Math.floor((diff / 1000) / 60);
        return minutes;
    }

    useEffect(() => {
        if (cart.length > 0) {
            let carrinhoVerificado = [] as ProdutoModel[];
            cart.forEach((produto) => {
                const diff = getMinutesBetweenDates(produto.hora_atual ?? '0', getDate());
                if (diff < 30) {
                    carrinhoVerificado.push(produto);
                }
            })
            if (carrinhoVerificado.length !== cart.length){
                setCartAndSave(carrinhoVerificado);
            }
        }
    }, [cart])

    return (
        <CartContext.Provider value={{ cart, setCart, setCartAndSave }}>
            {children}
        </CartContext.Provider>
    );
};

export function useCart(): useCartProps {
    const context = useContext(CartContext);
    const { cart, setCart, setCartAndSave } = context;
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }

    if (cart.length === 0) {
        const cartStorage = localStorage.getItem('cart');
        if (cartStorage && cartStorage.length > 3) {
            setCart(JSON.parse(cartStorage));
        }
    }

    return { cart, setCart, setCartAndSave } as useCartProps;
}