import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Button, Card, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { ModalCustom } from '../../../components/modal';
import Notificacao from '../../../components/notification';
import { versionWebSite } from '../api'
import { useCart } from '../context/cartcontext';
import { getFormaPagamentoEmpresa } from '../controllers/getFormaPagamentoEmpresa';
import getTaxaEntrega from '../controllers/getTaxaEntrega';
import { makePedido } from '../controllers/makePedidoController';
import { EnderecoCliente } from '../model/endereco.model';
import { EmpresasFormaPagamento, FormasPagamento } from '../model/fomaPagamentoEmpresa.model';
import { Root as MakePedidoModel } from '../model/makePedido.model';
import { Root as ProdutoModel } from '../model/produto.model';
import { Root as UserModel } from '../model/user.model';
import FALLBACK_IMAGE from '../onerror.png';
import { convertProductToJson, getPriceOfProduct } from '../utils/convertProductToJson';
import ModalEnderecos from './modal/detalhesEndereco/modalEnderecos';
import ModalCreditCard from './modal/makePaymentCreditCard/makePayment';
import { ModelCreditCard } from './modal/makePaymentCreditCard/modelCreditCard.model';
import ModalProdutos from './modal/produtos/modalProdutos';

const CarrinhoPage = () => {


    const { cart, setCartAndSave } = useCart()
    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    const [produtoFirst, setProdutoFirst] = useState<ProdutoModel | null>(null);
    const [enderecoSelecionado, setEnderecoSelecionado] = useState<EnderecoCliente | null>(null);
    const [openModalEnderecos, setOpenModalEnderecos] = useState(false);
    const [handleOnReload, setHandleOnReload] = useState(false);
    const handleOpenModalEnderecos = () => setOpenModalEnderecos(true);
    const handleCloseModalEnderecos = () => setOpenModalEnderecos(false);
    const [isLoadingEndereco, setIsLoadingEndereco] = useState(true);
    const [observacao, setObservacao] = useState('');
    const [isRetiradaNoLocal, setIsRetiradaNoLocal] = useState(false);
    const [valorDoFrete, setValorDoFrete] = useState<number | null>(0);
    const [creditCardValue, setCreditCardValue] = useState<ModelCreditCard | null>(null);

    const [addressInString, setAddressInString] = useState<string>('');
    const [address, setAddress] = useState<EnderecoCliente | null>(null);
    const [formasDePagamento, setFormasDePagamento] = useState<FormasPagamento[]>([]);
    const [isLoadingFormasPagamento, setIsLoadingFormasPagamento] = useState(false);
    const [openModalCreditCard, setOpenModalCreditCard] = useState(false);
    const handleCloseModalCreditCard = () => setOpenModalCreditCard(false);

    async function loadFormasPagamento() {
        if (cart.length === 0) return;
        setIsLoadingFormasPagamento(true);
        const formasPagamento = await getFormaPagamentoEmpresa(cart[0].empresas_id);
        setIsLoadingFormasPagamento(false);
        if (formasPagamento) {
            setFormasDePagamento(formasPagamento)
        }
    }

    function verifyIfUserSelectFormaPagamento(): boolean {
        let flag = false;
        if (formasDePagamento.length === 0) return false;
        formasDePagamento[0].empresas_forma_pagamentos.forEach((forma) => {
            if (forma.selecionado) {
                flag = true;
            }
        })
        return flag;
    }

    function returnFormaPagamentoSelecionada(): EmpresasFormaPagamento {
        let formaSelecionada = {} as EmpresasFormaPagamento;
        if (formasDePagamento.length === 0) return {} as EmpresasFormaPagamento;
        formasDePagamento[0].empresas_forma_pagamentos.forEach((forma) => {
            if (forma.selecionado) {
                formaSelecionada = forma;
            }
        })
        return formaSelecionada;
    }

    interface FomaPagamentoPayload {
        id: number,
        nome: string,
        ativo: number,
    };

    function returnFormaPagamentoPayload(): FomaPagamentoPayload[] {
        let formasPagamentoPayload: FomaPagamentoPayload[] = [];
        if (formasDePagamento.length > 0) {
            formasDePagamento[0].empresas_forma_pagamentos.forEach((formaPagamento) => {
                formasPagamentoPayload.push({
                    id: formaPagamento.forma_pagamentos_id,
                    nome: formaPagamento.nome,
                    ativo: formaPagamento.ativo,
                });
            });
        }
        return formasPagamentoPayload;
    }

    useEffect(() => {
        loadFormasPagamento()
    }, [cart])

    const userInString = localStorage.getItem('user') || '{}'
    const user = JSON.parse(userInString) as UserModel

    async function loadAddresInLocalStorage() {
        const addressInStringFlag = await localStorage.getItem('address_user') || '{}'
        const addressFlag = await JSON.parse(addressInStringFlag) as EnderecoCliente
        if (addressInStringFlag.length > 3) {
            await cart
            setIsLoadingEndereco(true);
            setEnderecoSelecionado(addressFlag)
            setAddressInString(addressInStringFlag)
            setAddress(addressFlag)
            const valor = await getTaxaEntrega(
                addressFlag.bairro_id,
                cart[0].empresas_id,
                addressFlag.latitude,
                addressFlag.longitude,
                addressFlag.cidade === 'Palmas' ? true : false,
                user.token,
            );
            setValorDoFrete(valor);
            setIsLoadingEndereco(false);
        }
    }

    useEffect(() => {
        if (cart && cart.length > 0) {
            loadAddresInLocalStorage()
        }
    }, [handleOnReload])

    const [size, setSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
    });
    const updateSize = () =>
        setSize({
            x: window.innerWidth,
            y: window.innerHeight
        });
    useEffect(() => (window.onresize = updateSize), []);

    function btnRemoveProduct(index: number) {
        return <Button variant="outlined" color="error" onClick={() => {
            setCartAndSave(cart.filter((item, i) => i !== index))
        }} sx={{
            fontSize: '0.8rem',
            color: 'red',
        }}>
            Remover
        </Button>;
    }

    function getTotalValue(): number {
        let total = 0
        cart.forEach(item => {
            total += getPriceOfProduct(item) * (item.quantidade ? item.quantidade : 1)
        })
        return total
    }

    function getBntVerMais(item: ProdutoModel): JSX.Element {
        return <Button sx={{
            color: '#F9146B',
            backgroundColor: '#FFF',
            height: '30px',
        }} onClick={() => {
            setProdutoFirst(item);
            handleOpen2();
        }}>
            <Typography sx={{
                fontSize: '0.8rem',
            }}>
                Mais detalhes
            </Typography>
        </Button>;
    }

    function btnOpenAddress() {
        return <Card elevation={2} sx={{
            width: '100%',
            marginTop: '20px',
            marginBottom: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
        }}>
            <Button sx={{
                display: 'flex',
                flexDirection: 'row',
                paddingLeft: '10px',
                paddingRight: '10px',
                fontSize: '0.8rem',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }} onClick={() => {
                setOpenModalEnderecos(true);
                handleOpenModalEnderecos();
            }}>
                <LocationOnIcon sx={{
                    color: '#F9146B',
                }} />
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginLeft: '10px',
                }}>
                    <Typography sx={{
                        fontSize: '0.9rem',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        textOverflow: 'ellipsis',
                        maxLines: 1,
                    }}>
                        {!enderecoSelecionado ? 'Clique aqui para selecionar um endereço' : `${enderecoSelecionado?.logradouro}, ${enderecoSelecionado?.numero}, ${enderecoSelecionado?.bairro}, ${enderecoSelecionado?.cidade}`},
                    </Typography>
                </div>
                <div></div>
            </Button>
        </Card>;
    }

    function convertProdutosObjToStringItIsAGambiarraOfHollic(carrinho: ProdutoModel[]) {
        let produtos_entrega = '';
        let produtoSemEspecificacoes = '';
        let produtosComEspecificacoes = '';
        for (let i = 0; i <= carrinho.length - 1; i++) {
            if (carrinho[i].especificacoes) {
                if (carrinho[i].especificacoes!.length === 0) {
                    produtoSemEspecificacoes +=
                        `${carrinho[i].quantidade ?? 1} x ${carrinho[i].nome} - ${getPriceOfProduct(carrinho[i]).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}\n`;
                } else {
                    produtosComEspecificacoes +=
                        `${carrinho[i].quantidade ?? 1} x ${carrinho[i].nome} - ${carrinho[i].descricao} - ${getPriceOfProduct(carrinho[i]).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}\n`;
                }
            } else {
                produtoSemEspecificacoes +=
                    `${carrinho[i].quantidade ?? 1} x ${carrinho[i].nome} - ${getPriceOfProduct(carrinho[i]).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}\n`;
            }
        }

        produtos_entrega = produtoSemEspecificacoes + produtosComEspecificacoes;
        return produtos_entrega;
    }

    function getRandomInt(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
    }

    function getTotalPromocional(): number {
        let total = 0;
        cart.forEach(item => {
            if (item.product_promotion_id) {
                total += item.precoByQuantidade ?? item.preco
            }
        })
        return total;
    }

    function getLoginOrShowMensage(): JSX.Element {
        return <Box sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            marginTop: 5,
        }}>
            <Box style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}>
                <Typography sx={{
                    fontSize: '25px',
                }}>
                    {!user.user ? 'Você não está logado, faça login para continuar.' : 'Seu carrinho está vazio'}
                </Typography>
                <br></br>
                <br></br>
                <Link to="/login" style={{
                    textDecoration: 'none',
                }}>
                    <Button variant="contained" color="primary" style={{
                        margin: '10px 0px',
                    }}>
                        {!user.user ? 'Fazer login' : 'Ir para a página de empresas'}
                    </Button>
                </Link>
            </Box>
        </Box>
    }

    function getCreditCardNumberFormatter(): string {

        if (!creditCardValue) {
            return '';
        }

        let numberFormated = '';
        for (let i = 0; i < creditCardValue.number.length; i++) {
            if (i < 12) {
                numberFormated += '*';
            } else {
                numberFormated += creditCardValue.number[i];
            }
        }
        return numberFormated;
    }

    return (
        <div>
            <Grid sx={{
                marginTop: '80px',
            }}>
                <ModalCustom
                    open={openModalCreditCard}
                    title='Realizar pagamento com o cartão de crédito'
                    onClose={handleCloseModalCreditCard}
                    actions={null}
                    isMobile={size.x < 700}
                    slug={''}
                >
                    <ModalCreditCard
                        onClose={handleCloseModalCreditCard}
                        setData={setCreditCardValue}
                        dados={creditCardValue}
                    />
                </ModalCustom>
                <div style={{
                    marginLeft: size.x > 900 ? '20%' : '0px',
                    marginRight: size.x > 900 ? '20%' : '0px',
                    display: 'flex',
                    justifyContent: cart.length === 0 ? 'center' : 'start',
                    alignItems: cart.length === 0 ? 'center' : 'start',
                    flexDirection: 'column',
                }}>
                    {
                        cart.length > 0 && <ModalCustom
                            open={open2}
                            title={produtoFirst === null ? '' : produtoFirst.nome}
                            onClose={handleClose2}
                            actions={null}
                            isMobile={size.x < 700}
                            slug={!produtoFirst ? null : produtoFirst.slug}
                        >
                            <ModalProdutos
                                idProduto={null}
                                empresaIsOpen={true}
                                onlyView={true}
                                produto={produtoFirst!}
                                stateFunction={
                                    () => {
                                        setOpen2(false)
                                    }
                                }
                                isRetirada={false}
                                handleClose={handleClose2}
                                isOnCarrinho={true}
                                cidade_nome={''}
                                isPromotion={false}
                                slugEmpresa={cart[0].slugEmpresa ?? ''}
                            />
                        </ModalCustom>
                    }
                    {
                        user.user ?
                            <ModalCustom
                                open={openModalEnderecos}
                                title='Endereços'
                                onClose={handleCloseModalEnderecos}
                                actions={null}
                                isMobile={size.x < 700}
                                slug={''}
                            >
                                <ModalEnderecos
                                    onClose={handleCloseModalEnderecos}
                                    onReload={() => setHandleOnReload(!handleOnReload)}
                                    entity_id={user.user.entity_id}
                                    token={user.token}
                                />
                            </ModalCustom> : null
                    }
                    {
                        <Box sx={{
                            position: 'fixed',
                            right: size.x > 700 ? 20 : 10,
                            bottom: size.x > 700 ? 20 : 15,
                            zIndex: 100,
                        }}>
                            <Box
                                onClick={() => {
                                    window.history.back();
                                }}
                                sx={{
                                    backgroundColor: '#FAE600',
                                    padding: '10px',
                                    borderRadius: '40px',
                                    height: size.x > 700 ? 50 : 40,
                                    width: size.x > 700 ? 50 : 40,
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    boxShadow: '1px 2px #ababab',
                                }}>
                                <ArrowBackIosIcon sx={{
                                    marginLeft: '10px',
                                }} />
                            </Box>
                            <Box
                                onClick={() => {
                                    window.location.href = '/login';
                                }}
                                sx={{
                                    backgroundColor: '#FAE600',
                                    padding: '10px',
                                    borderRadius: '40px',
                                    height: size.x > 700 ? 50 : 40,
                                    width: size.x > 700 ? 50 : 40,
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    boxShadow: '1px 2px #ababab',
                                    marginTop: '10px',
                                }}>
                                <HomeIcon sx={{
                                    marginLeft: '2.5px',
                                    fontSize: '1.9rem',
                                }} />
                            </Box>
                        </Box>
                    }
                    <Typography sx={{
                        marginBottom: '10px',
                        fontSize: '1.5rem',
                    }}>
                        Carrinho
                    </Typography>
                    {
                        userInString.length < 5 || cart.length === 0 ? getLoginOrShowMensage() :
                            <Box sx={{
                                margin: 0,
                                padding: 0,
                                width: '100%',
                            }}>
                                {
                                    cart.map((item, index) => {
                                        return (
                                            <Card key={index} style={{
                                                width: '100%',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }} elevation={2}>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    paddingLeft: size.x > 700 ? '20px' : '2px',
                                                    paddingRight: size.x > 700 ? '20px' : '2px',
                                                }}>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'start',
                                                        textAlign: 'start',
                                                        flexDirection: 'row',
                                                    }}>
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'start',
                                                            alignItems: 'start',
                                                            textAlign: 'start',
                                                            flexDirection: 'column',
                                                            marginLeft: 10,
                                                            marginBottom: 30,
                                                        }}>
                                                            <Typography sx={{
                                                                fontSize: '1.1rem',
                                                                marginTop: '20px',
                                                            }}>
                                                                {item.nome}
                                                            </Typography>
                                                            <Typography sx={{
                                                                fontSize: '0.8rem',
                                                                margin: 0,
                                                                textAlign: 'justify',
                                                                display: '-webkit-box',
                                                                overflow: 'hidden',
                                                                WebkitBoxOrient: 'vertical',
                                                                WebkitLineClamp: 4,
                                                                marginRight: '20px',
                                                            }}>
                                                                {item.descricao}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'center',
                                                        flexDirection: 'column',
                                                    }}>
                                                        {size.x > 700 ? btnRemoveProduct(index) : null}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                    marginTop: '10px',
                                                    marginBottom: '20px',
                                                    marginLeft: size.x > 700 ? '15px' : '10px',
                                                    marginRight: size.x > 700 ? '15px' : '10px',
                                                }}>
                                                    <Typography sx={{
                                                        fontSize: '1rem',
                                                        fontWeight: 550,
                                                        marginLeft: '8px',
                                                    }}>
                                                        {(getPriceOfProduct(item) * (item.quantidade ? item.quantidade : 1)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                    </Typography>
                                                    {
                                                        size.x > 700 ? getBntVerMais(item) : null
                                                    }
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                        paddingLeft: size.x > 700 ? '10px' : '5px',
                                                        paddingRight: size.x > 700 ? '10px' : '5px',
                                                        paddingTop: '4px',
                                                        paddingBottom: '4px',
                                                    }}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => {
                                                                if (item.quantidade > 1) {
                                                                    const quantidade = cart[index].quantidade - 1;
                                                                    const ProdutoFlaf = {
                                                                        ...cart[index],
                                                                        quantidade: quantidade,
                                                                        precoByQuantidade: getPriceOfProduct(item) * quantidade,
                                                                    } as ProdutoModel;
                                                                    const newCart = cart.map((item, i) => {
                                                                        if (i === index) {
                                                                            return ProdutoFlaf;
                                                                        }
                                                                        return item;
                                                                    });
                                                                    setCartAndSave(newCart);
                                                                }
                                                            }}
                                                            sx={{
                                                                fontSize: size.x > 700 ? '1rem' : '0.8rem',
                                                                height: size.x > 700 ? '30px' : '27px',

                                                                textAlign: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            -
                                                        </Button>
                                                        <Typography sx={{
                                                            fontSize: '1.3rem',
                                                            marginLeft: '20px',
                                                            marginRight: '20px',
                                                        }}>
                                                            {item.quantidade ?? 1}
                                                        </Typography>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => {

                                                                if (cart[index].max_use) {
                                                                    if (cart[index].max_use <= cart[index].quantidade) {
                                                                        Notificacao('Error', 'Você já atingiu o limite de produtos por compra deste item', 'danger');
                                                                        return;
                                                                    }
                                                                }

                                                                const quantidade = cart[index].quantidade ? cart[index].quantidade + 1 : 2;
                                                                const ProdutoFlaf = {
                                                                    ...cart[index],
                                                                    quantidade: quantidade,
                                                                    precoByQuantidade: getPriceOfProduct(item) * quantidade,
                                                                } as ProdutoModel;
                                                                const newCart = cart.map((item, i) => {
                                                                    if (i === index) {
                                                                        return ProdutoFlaf;
                                                                    }
                                                                    return item;
                                                                });
                                                                setCartAndSave(newCart);
                                                            }}
                                                            sx={{
                                                                fontSize: size.x > 700 ? '1rem' : '0.8rem',
                                                                height: size.x > 700 ? '30px' : '27px',
                                                                textAlign: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            +
                                                        </Button>
                                                    </Box>
                                                </div>
                                                {
                                                    size.x > 700 ? null :
                                                        <div style={{
                                                            marginBottom: '20px',
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'space-between',
                                                            marginLeft: size.x > 700 ? '0px' : '15px',
                                                            marginRight: size.x > 700 ? '0px' : '15px',
                                                        }}>

                                                            {btnRemoveProduct(index)}
                                                            {
                                                                size.x > 700 ?
                                                                    null :
                                                                    <div style={{
                                                                        marginTop: '10px',
                                                                    }}>{getBntVerMais(item)}</div>
                                                            }
                                                        </div>
                                                }
                                            </Card>
                                        )
                                    })
                                }
                            </Box>
                    }
                    {
                        userInString.length > 5 &&
                            cart.length > 0 ?
                            <Box sx={{
                                margin: 0,
                                padding: 0,
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <Typography sx={{
                                    marginBottom: '20px',
                                    marginTop: '30px',
                                    fontSize: '1.5rem',
                                }}>
                                    Endereço
                                </Typography>
                                <div style={{
                                    width: '100%',
                                }}>
                                    {btnOpenAddress()}
                                </div>
                                <Typography sx={{
                                    marginTop: '40px',
                                    fontSize: '1.5rem',
                                }}>
                                    Valores
                                </Typography>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'start',
                                        alignItems: 'start',
                                        marginBottom: '20px',
                                        marginTop: '20px',
                                        width: '100%',
                                    }}>
                                        <Typography sx={{
                                            fontSize: '1rem',
                                        }}>
                                            Valor do frete:
                                        </Typography>
                                        <Typography sx={{
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            marginLeft: '10px',
                                        }}>
                                            {
                                                !enderecoSelecionado ? 'Selecione um endereço'
                                                    : isLoadingEndereco ? 'Carregando endereço...' :
                                                        !valorDoFrete ? 'Erro ao carregar endereço' :
                                                            valorDoFrete < 0 ? 'Não atendemos o endereço selecionado' :
                                                                cart[0].cidade_nome?.toUpperCase() !== enderecoSelecionado?.cidade.toUpperCase() ? 'A cidade do seu endereço é diferente da cidade da empresa' :
                                                                    valorDoFrete.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                            }
                                        </Typography>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'start',
                                        alignItems: 'start',
                                        marginBottom: '20px',
                                        width: '100%',
                                    }}>
                                        <Typography sx={{
                                            fontSize: '1rem',
                                        }}>
                                            Valor dos produtos:
                                        </Typography>
                                        <Typography sx={{
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            marginLeft: '10px',
                                        }}>
                                            {getTotalValue().toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </Typography>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'start',
                                        alignItems: 'start',
                                        marginBottom: '30px',
                                        width: '100%',
                                    }}>
                                        <Typography sx={{
                                            fontSize: '1rem',
                                        }}>
                                            Valor total:
                                        </Typography>
                                        <Typography sx={{
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            marginLeft: '10px',
                                        }}>
                                            {
                                                (!valorDoFrete || valorDoFrete < 0 || !cart[0].cidade_nome || cart[0].cidade_nome.toUpperCase() !== enderecoSelecionado?.cidade.toUpperCase()) ? '----' : (getTotalValue() + (valorDoFrete ?? 0)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </Typography>
                                    </div>
                                </div>
                                <Typography sx={{
                                    fontSize: '1.5rem',
                                    marginBottom: '10px',
                                }}>
                                    Métodos de pagamento
                                </Typography>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '40px',
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'start',
                                        alignItems: 'start',
                                        width: '100%',
                                    }}>
                                        {
                                            formasDePagamento.length > 0 ?
                                                formasDePagamento[0].empresas_forma_pagamentos.map((item, index) => {
                                                    return (
                                                        (
                                                            item.forma_pagamentos_id === 0 ||
                                                            item.forma_pagamentos_id === 1 ||
                                                            item.forma_pagamentos_id === 2 ||
                                                            item.forma_pagamentos_id === 3
                                                        ) && <Button
                                                            inlist={index}
                                                            sx={{
                                                                color: item.forma_pagamentos_id === 1 ?
                                                                    (item.selecionado && creditCardValue ? '#fff' : '#F9146B')
                                                                    : item.selecionado ? '#fff' : '#F9146B',
                                                                border: '1px solid #F9146B',
                                                                backgroundColor: item.forma_pagamentos_id === 1 ?
                                                                    (item.selecionado && creditCardValue ? '#F9146B' : '#FFF')
                                                                    : item.selecionado ? '#F9146B' : '#fff',
                                                                marginRight: '10px',
                                                                marginTop: '10px',
                                                                '&:hover': {
                                                                    color: item.forma_pagamentos_id === 1 ?
                                                                        (item.selecionado && creditCardValue ? '#fff' : '#F9146B')
                                                                        : item.selecionado ? '#fff' : '#F9146B',
                                                                    border: '1px solid #F9146B',
                                                                    backgroundColor: item.forma_pagamentos_id === 1 ?
                                                                        (item.selecionado && creditCardValue ? '#F9146B' : '#FFF')
                                                                        : item.selecionado ? '#F9146B' : '#fff',
                                                                },
                                                                maxHeight: '50px',
                                                            }} onClick={() => {
                                                                let formasDePagamentoCopy = [...formasDePagamento];
                                                                for (let i = 0; i < formasDePagamentoCopy[0].empresas_forma_pagamentos.length; i++) {
                                                                    if (i === index) {
                                                                        formasDePagamentoCopy[0].empresas_forma_pagamentos[i].selecionado = true;
                                                                    } else {
                                                                        formasDePagamentoCopy[0].empresas_forma_pagamentos[i].selecionado = false;
                                                                    }
                                                                }
                                                                setFormasDePagamento(formasDePagamentoCopy);
                                                                if (item.forma_pagamentos_id === 1) {
                                                                    setOpenModalCreditCard(true);
                                                                }
                                                            }}>
                                                            {
                                                                item.forma_pagamentos_id === 1 &&
                                                                    item.selecionado && creditCardValue ?
                                                                    <Box>
                                                                        {item.nome} - {getCreditCardNumberFormatter()}
                                                                    </Box> : <Box>
                                                                        {item.nome}
                                                                    </Box>
                                                            }
                                                        </Button>
                                                    );
                                                }) : isLoadingFormasPagamento ? <CircularProgress sx={{
                                                    color: '#F9146B',
                                                }} /> : <Box>
                                                    <Typography variant='h6'>
                                                        Nenhum método de pagamento disponível, tente novamente
                                                    </Typography>
                                                    <Button sx={{
                                                        border: '1px solid #F9146B',
                                                        color: '#F9146B',
                                                        marginTop: '10px',
                                                    }}
                                                        onClick={async () => {
                                                            await loadFormasPagamento();
                                                        }}>
                                                        Recarregar métodos de pagamento
                                                    </Button>
                                                </Box>
                                        }
                                    </div>
                                </div>
                                {
                                    cart[0].isRetirada ?
                                        <>
                                            <Typography sx={{
                                                fontSize: '1.5rem',
                                                marginBottom: '10px',
                                            }}>
                                                Deseja retirar o pedido no local?
                                            </Typography>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '100%',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginBottom: '40px',
                                            }}>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'start',
                                                    alignItems: 'start',
                                                    width: '100%',
                                                }}>
                                                    <Button sx={{
                                                        color: !isRetiradaNoLocal ? '#fff' : '#F9146B',
                                                        backgroundColor: !isRetiradaNoLocal ? '#F9146B' : '#fff',
                                                        border: '1px solid #F9146B',
                                                        marginRight: '10px',
                                                        '&:hover': {
                                                            color: !isRetiradaNoLocal ? '#fff' : '#F9146B',
                                                            backgroundColor: !isRetiradaNoLocal ? '#F9146B' : '#fff',
                                                            border: '1px solid #F9146B',
                                                        }
                                                    }} onClick={() => {
                                                        setIsRetiradaNoLocal(false)
                                                    }}>
                                                        Não
                                                    </Button>
                                                    <Button sx={{
                                                        color: isRetiradaNoLocal ? '#fff' : '#F9146B',
                                                        border: '1px solid #F9146B',
                                                        backgroundColor: isRetiradaNoLocal ? '#F9146B' : '#fff',
                                                        '&:hover': {
                                                            color: isRetiradaNoLocal ? '#fff' : '#F9146B',
                                                            border: '1px solid #F9146B',
                                                            backgroundColor: isRetiradaNoLocal ? '#F9146B' : '#fff',
                                                        }
                                                    }} onClick={() => {
                                                        setIsRetiradaNoLocal(true)
                                                    }}>
                                                        Sim
                                                    </Button>
                                                </div>
                                            </div>
                                            <Typography sx={{
                                                fontSize: '0.8rem',
                                            }}>
                                                A retirada de produtos é feita diretamente no estabelecimento, e as informações de pagamento aceitas são apenas pix e pagamento on-line.
                                            </Typography>
                                        </> :
                                        null
                                }
                                <TextField
                                    placeholder="Digite aqui uma observação para o pedido"
                                    multiline
                                    rows={2}
                                    maxRows={4}
                                    sx={{
                                        width: '100%',
                                        marginBottom: '50px',
                                    }}
                                    onChange={(e) => {
                                        setObservacao(e.target.value)
                                    }}
                                />
                                <Button variant="contained" style={{
                                    margin: '0px 0px 20px 0px',
                                    backgroundColor: (!verifyIfUserSelectFormaPagamento() || !enderecoSelecionado || (valorDoFrete && valorDoFrete < 0) || cart[0].cidade_nome !== enderecoSelecionado?.cidade)
                                        ? '#616161'
                                        : '#28b300',
                                    width: '100%',
                                }} onClick={async () => {

                                    if (!cart[0].cidade_nome || cart[0].cidade_nome.toUpperCase() !== enderecoSelecionado?.cidade.toUpperCase()) {
                                        Notificacao('Atenção!', `O endereço de entrega deve ser na mesma cidade que o estabelecimento`, 'danger');
                                        return
                                    }

                                    if (!verifyIfUserSelectFormaPagamento()) {
                                        Notificacao('Atenção!', `Selecione um método de pagamento`, 'danger');
                                        return
                                    }
                                    if (!enderecoSelecionado) {
                                        Notificacao('Atenção!', `Selecione um endereço de entrega`, 'danger');
                                        return
                                    }
                                    if (valorDoFrete && valorDoFrete < 0) {
                                        Notificacao('Atenção!', `Selecione um endereço de entrega válido`, 'danger');
                                        return
                                    }

                                    let formaPagamentoSelecionada = 0;

                                    for (let formaPag of formasDePagamento[0].empresas_forma_pagamentos) {
                                        if (formaPag.selecionado) {
                                            formaPagamentoSelecionada = formaPag.forma_pagamentos_id;
                                            break;
                                        }
                                    }

                                    if (formaPagamentoSelecionada === 1) {
                                        if (!creditCardValue) {
                                            Notificacao('Atenção!', `Selecione um método de pagamento`, 'danger');
                                            return
                                        }
                                        if (
                                            !creditCardValue.name ||
                                            !creditCardValue.cpf ||
                                            !creditCardValue.expiry ||
                                            !creditCardValue.number
                                        ) {
                                            Notificacao('Atenção!', `Preencha todos os dados do cartão`, 'danger');
                                            return
                                        }
                                    }

                                    const device = window.navigator.userAgent;
                                    const pedido = {
                                        "name": user.user.name,
                                        "clientes_id": user.user.entity_id.toString(),
                                        "user_id": user.user.id,
                                        "enderecos_id": enderecoSelecionado?.id,
                                        "endereco_entrega": `${enderecoSelecionado?.logradouro}, ${enderecoSelecionado?.numero}, ${enderecoSelecionado?.bairro}`,
                                        "status": 0,
                                        "opcionais": null,
                                        "observacao": observacao,
                                        "produtos": JSON.stringify(convertProductToJson(cart)),
                                        "produtos_entrega": convertProdutosObjToStringItIsAGambiarraOfHollic(cart),
                                        "tem_entrega": isRetiradaNoLocal ? 0 : 1,
                                        "forma_pagamento": returnFormaPagamentoSelecionada().forma_pagamentos_id,
                                        "formasPagamento": JSON.stringify(returnFormaPagamentoPayload()),
                                        "valorTotal": getTotalValue().toString(),
                                        "valorFinal": (getTotalValue() + (valorDoFrete ?? 0)).toString(),
                                        "taxaEntrega": (valorDoFrete ?? 0),
                                        "desconto": 0,
                                        "troco": 0,
                                        "cupom": null,
                                        "paymentId": returnFormaPagamentoSelecionada().forma_pagamentos_id,
                                        "empresas_id": cart[0].empresas_id,
                                        "fabricante": cart[0].fabricante,
                                        "bandeira_cartao": null,
                                        "numero_cartao": creditCardValue && creditCardValue.number,
                                        "nome_titular": creditCardValue && creditCardValue.name,
                                        "validade_cartao": creditCardValue && creditCardValue.expiry.replaceAll('/', ''),
                                        "valor_promocional": "0.00",
                                        "cvv_cartao": creditCardValue && creditCardValue.cvc.replaceAll('_', ''),
                                        "cpf_cartao": creditCardValue && creditCardValue.cpf,
                                        "device_os": device,
                                        "device_model": 'VOKERE WEB',
                                        "device_manufacturer": 'VOKERE WEB',
                                        "version_app": versionWebSite,
                                        "build_number": versionWebSite,
                                        "random_number": getRandomInt(1, 999),
                                        "bairrosTaxa": isRetiradaNoLocal ? 0 : valorDoFrete,
                                        "total_promocional": getTotalPromocional(),
                                    } as MakePedidoModel

                                    console.log('O payload do pedido é: ', pedido)

                                    Notificacao('Aguarde', `Realizando pedido...`, 'default');
                                    const response = await makePedido(pedido, user.token ?? '');
                                    if (response) {
                                        setCartAndSave([]);
                                        window.location.href = '/pedidos';
                                    }
                                }}>
                                    Comprar os itens
                                </Button>
                                <Link to={`/empresa/${cart[0].slugEmpresa}`} style={{
                                    width: '100%',
                                    margin: '10px 0px',
                                }}>
                                    <Button variant="contained" color="primary" sx={{
                                        width: '100%',
                                    }}>
                                        Ir para a empresa
                                    </Button>
                                </Link>
                            </Box> : null
                    }

                </div>
            </Grid>
            <div style={{
                height: '80px',
            }}></div>
        </div>
    )
}

export default CarrinhoPage
