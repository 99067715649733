import { Box, Button, Card, CardMedia, CircularProgress, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useParams } from 'react-router-dom';

import { ModalCustom } from '../../../components/modal';
import Notificacao from '../../../components/notification';
import { urlWebSite } from '../api';
import { useCart } from '../context/cartcontext';
import { getImageProfile } from '../controllers/getImageProfile';
import { getProductsBySlug } from '../controllers/getProdBySlug';
import { ProdBySlug } from '../model/prodBySlug';
import { Root as UserModel } from '../model/user.model';
import FALLBACK_IMAGE from '../onerror.png';
import OpenImage from './modal/abrirImagem/modalOpenImage';
import ModalProdutos from './modal/produtos/modalProdutos';
import { getInfoShopingCart } from './widgets/shopingCartFloating';

const ProdutoDetail = () => {

    const onMediaFallback = (event: any) => event.target.src = FALLBACK_IMAGE;

    const { idProduto } = useParams();
    const [produto, setProduto] = useState<ProdBySlug | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const userInString = localStorage.getItem('user') || '{}'
    const { cart, setCartAndSave } = useCart();

    const [open, setOpen] = useState(false);
    const [openInfoPerson, setOpenInfoPerson] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openImage, setOpenImage] = useState(false);
    const handleOpenImage = () => setOpenImage(true);
    const handleCloseImage = () => setOpenImage(false);


    const [imageUrl, setImageUrl] = useState(FALLBACK_IMAGE)
    const [image, setImage] = useState(FALLBACK_IMAGE)

    async function getImage() {
        const userInString = await localStorage.getItem('user') || '{}'
        if (userInString.length < 5) return
        const user = JSON.parse(userInString) as UserModel
        const image = await getImageProfile(user.user.id)
        if (image) {
            setImageUrl(image)
        }
    }

    const [size, setSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
    });
    const updateSize = () =>
        setSize({
            x: window.innerWidth,
            y: window.innerHeight
        });
    useEffect(() => (window.onresize = updateSize), []);

    useEffect(() => {
        getDetailProduct();
    }, []);

    async function getDetailProduct(): Promise<ProdBySlug | null> {
        const response = await getProductsBySlug(idProduto ?? '');
        await getImage();
        setProduto(response);
        setIsLoading(false);
        return response;
    }

    function verifyIfProductsAreEspecificacoesEnable(): boolean {
        let espDisable = 0;
        if (produto) {
            if (produto.produto.especificacoes) {

                if (produto.produto.especificacoes.length === 0) {
                    return false;
                }

                produto.produto.especificacoes?.forEach((esp) => {
                    if (esp.ativo === 0) {
                        espDisable++;
                    }
                });
            }
            return espDisable === produto.produto.especificacoes?.length;
        } else {
            return false;
        }
    }

    return (
        <div>
            <Box sx={{
                marginTop: '80px',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
            }}>
                {
                    isLoading ? <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}>
                        <Typography gutterBottom component="h2" style={{
                            textAlign: 'center',
                            marginTop: '20px',
                            marginBottom: '20px',
                            fontSize: '1.3rem',
                        }}>
                            Carregando produto...
                        </Typography>
                        <CircularProgress sx={{
                            color: '#F9146B',
                        }} />
                    </Box>
                        : produto === null ?
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <Typography gutterBottom component="h2" style={{
                                    textAlign: 'center',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    fontSize: '1.3rem',
                                }}>
                                    Produto não encontrado!
                                </Typography>
                                <Button
                                    onClick={() => window.location.href = '/login'}
                                    sx={{
                                        backgroundColor: '#F9146B',
                                        color: '#fff',
                                        width: '100%',
                                        height: '50px',
                                        borderRadius: '5px',
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                        ':hover': {
                                            backgroundColor: '#F9146B',
                                            color: '#fff',
                                        }
                                    }}>
                                    Voltar para a página de empresas
                                </Button>
                            </Box>
                            : <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '90%',
                                marginTop: '15px',
                            }}>
                                {
                                    <ModalCustom
                                        open={openImage}
                                        title='Imagem'
                                        onClose={handleCloseImage}
                                        actions={null}
                                        isMobile={size.x < 700}
                                        slug={''}
                                    >
                                        {
                                            OpenImage({
                                                imagem: image.length < 5 ? FALLBACK_IMAGE : image,
                                                onClose: handleCloseImage,
                                            })
                                        }
                                    </ModalCustom>
                                }
                                <ModalCustom
                                    open={open}
                                    title={produto.produto.nome}
                                    onClose={handleClose}
                                    actions={null}
                                    isMobile={size.x < 700}
                                    slug={produto.produto.slug}
                                >
                                    <ModalProdutos
                                        idProduto={null}
                                        empresaIsOpen={produto.empresa.aberta === 1}
                                        onlyView={userInString.length < 3 ? true : false}
                                        produto={{
                                            ...produto.produto,
                                            especificacoes: produto.produto.especificacoes ?? [],
                                        }}
                                        stateFunction={
                                            () => {
                                                setOpen(false)
                                            }
                                        }
                                        isRetirada={produto.empresa.retirar_local === 1}
                                        handleClose={handleClose}
                                        isOnCarrinho={false}
                                        cidade_nome={''}
                                        isPromotion={false}
                                        slugEmpresa={produto.empresa.slug}
                                    />
                                </ModalCustom>
                                {
                                    getInfoShopingCart(size.x, cart, openInfoPerson, setOpenInfoPerson, imageUrl, setCartAndSave)
                                }
                                {
                                    verifyIfProductsAreEspecificacoesEnable() &&
                                    <Box
                                        sx={{
                                            backgroundColor: 'red',
                                            color: '#fff',
                                            width: '100%',
                                            height: '50px',
                                            borderRadius: '5px',
                                            marginTop: '20px',
                                            marginBottom: '30px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                        Os opcionais do produto estão desativados!
                                    </Box>
                                }
                                {
                                    produto.produto.ativo === 0 &&
                                    <Box
                                        sx={{
                                            backgroundColor: 'red',
                                            color: '#fff',
                                            width: '100%',
                                            height: '50px',
                                            borderRadius: '5px',
                                            marginBottom: '30px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                        O produto está desativado!
                                    </Box>
                                }
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    marginBottom: '15px',
                                }}>
                                    <Typography
                                        onClick={() => window.location.href = '/login'}
                                        sx={{
                                            fontSize: size.x >= 900 ? '1rem' : '0.8rem',
                                            ':hover': {
                                                color: '#F9146B',
                                                cursor: 'pointer',
                                            }
                                        }}>
                                        EMPRESAS
                                    </Typography>
                                    <Typography sx={{
                                        fontSize: size.x >= 900 ? '1rem' : '0.8rem',
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                    }}>
                                        &gt;
                                    </Typography>
                                    <Typography
                                        onClick={() => window.location.href = '/empresa/' + produto?.empresa.slug}
                                        sx={{
                                            fontSize: size.x >= 900 ? '1rem' : '0.8rem',
                                            ':hover': {
                                                color: '#F9146B',
                                                cursor: 'pointer',
                                            }
                                        }}>
                                        {produto?.empresa.nome.toUpperCase()}
                                    </Typography>
                                    <Typography sx={{
                                        fontSize: size.x >= 900 ? '1rem' : '0.8rem',
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                    }}>
                                        &gt;
                                    </Typography>
                                    <Typography sx={{
                                        fontSize: size.x >= 900 ? '1rem' : '0.8rem',
                                        ':hover': {
                                            color: '#F9146B',
                                            cursor: 'pointer',
                                        }
                                    }}>
                                        {produto?.produto.nome.toUpperCase()}
                                    </Typography>
                                </Box>
                                <Card
                                    elevation={3}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-around',
                                        alignItems: 'center',
                                        width: '100%',
                                        backgroundColor: '#fDfDfD',
                                        borderRadius: '5px',
                                    }}>
                                    <Grid container spacing={0} sx={{
                                        paddingTop: '15px',
                                        paddingBottom: '15px',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <Grid
                                            item xs={12} sm={12} md={6} lg={6}
                                            sx={{
                                                height: '300px',
                                                width: '55%',
                                                borderRadius: '5px',
                                                display: 'flex',
                                            }}>
                                            {/* <Box sx={{
                                        height: '100%',
                                        width: '90px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginLeft: '10px',
                                    }}>
                                        <Box sx={{
                                            height: '100px',
                                            backgroundColor: '#C4C4C4',
                                            borderRadius: '5px',
                                            marginBottom: '5px',
                                        }}></Box>
                                        <Box sx={{
                                            height: '100px',
                                            backgroundColor: '#C4C4C4',
                                            borderRadius: '5px',
                                            marginBottom: '5px',
                                        }}></Box>
                                        <Box sx={{
                                            height: '100px',
                                            backgroundColor: '#C4C4C4',
                                            borderRadius: '5px',
                                            marginBottom: '5px',
                                        }}></Box>
                                        <Box sx={{
                                            height: '100px',
                                            backgroundColor: '#C4C4C4',
                                            borderRadius: '5px',
                                            marginBottom: '5px',
                                        }}></Box>
                                        <Box sx={{
                                            height: '100px',
                                            backgroundColor: '#C4C4C4',
                                            borderRadius: '5px',
                                            marginBottom: '5px',
                                        }}></Box>
                                        <Box sx={{
                                            height: '100px',
                                            backgroundColor: '#C4C4C4',
                                            borderRadius: '5px',
                                            marginBottom: '5px',
                                        }}></Box>
                                    </Box> */}
                                            <Box sx={{
                                                position: 'relative',
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                <CardMedia
                                                    component="img"
                                                    image={produto.produto.foto === null ? FALLBACK_IMAGE : produto.produto.foto}
                                                    onError={onMediaFallback}
                                                    sx={{
                                                        height: produto?.produto.foto === null ? '70%' : '100%',
                                                        width: produto?.produto.foto === null ? '70%' : '100%',
                                                        borderRadius: '5px',
                                                        objectFit: 'contain',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        setImage(produto?.produto.foto === null ? '' : produto?.produto.foto);
                                                        handleOpenImage();
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item xs={12} sm={12} md={6} lg={6}
                                            sx={{
                                                height: '300px',
                                                width: '42%',
                                                borderLeft: size.x >= 900 ? '0.5px solid #C4C4C4' : null,
                                                borderTop: size.x < 900 ? '0.5px solid #C4C4C4' : null,
                                                marginTop: size.x < 900 ? '15px' : null,
                                            }}>
                                            <Box sx={{
                                                padding: '3%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                height: '90%',
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                }}>
                                                    <Typography sx={{
                                                        fontWeight: 'bold',
                                                        fontSize: size.x >= 900 ? '1.4rem' : '1.2rem',
                                                    }}>
                                                        {produto?.produto.nome}
                                                    </Typography>
                                                    <Typography sx={{
                                                        marginTop: '7px',
                                                        marginBottom: '15px',
                                                        fontSize: size.x >= 900 ? '2rem' : '1.8rem',
                                                        fontWeight: 'normal',
                                                    }}>
                                                        {produto?.produto.preco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} / UNIDADE
                                                    </Typography>
                                                </Box>
                                                <Grid container spacing={1} sx={{
                                                    width: '100%',
                                                    alignItems: 'space-between',
                                                    textAlign: 'space-between',
                                                    justifyContent: 'space-between',
                                                }}>
                                                    {
                                                        !verifyIfProductsAreEspecificacoesEnable() && produto.produto.ativo === 1 &&
                                                        <Grid item xs={12} sm={12} md={12} lg={6}>
                                                            <Button
                                                                variant='contained'
                                                                sx={{
                                                                    width: '100%',
                                                                    color: '#FFFF',
                                                                    backgroundColor: '#F9146B',
                                                                    '&:hover': {
                                                                        backgroundColor: '#4dbd74',
                                                                    }
                                                                }}
                                                                onClick={() => {
                                                                    handleOpen();
                                                                }}
                                                            >
                                                                Adicionar ao carrinho
                                                            </Button>
                                                        </Grid>
                                                    }
                                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                                        <CopyToClipboard text={`${urlWebSite}/produto/${produto?.produto.slug}`} onCopy={() => {
                                                            Notificacao('Sucesso!', `Link do produto copiado para área de transferência!`, 'success');
                                                        }}>
                                                            <Button sx={{
                                                                color: '#FFF',
                                                                backgroundColor: '#f9146b9c',
                                                                width: '100%',
                                                                '&:hover': {
                                                                    backgroundColor: '#F9146B',
                                                                },
                                                            }}>
                                                                Compartilhar link
                                                            </Button>
                                                        </CopyToClipboard>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Card>
                                <Card
                                    elevation={3}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-around',
                                        alignItems: 'start',
                                        width: '100%',
                                        backgroundColor: '#fDfDfD',
                                        borderRadius: '5px',
                                        marginTop: '20px',
                                    }}>
                                    <Grid container spacing={0} sx={{
                                        padding: size.x > 900 ? '10px' : '5px',
                                    }}>
                                        <Grid
                                            item xs={12} sm={12} md={6} lg={6}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-around',
                                                alignItems: 'start',
                                                marginTop: '15px',
                                                marginBottom: '10px',
                                                width: '100%',
                                            }}>
                                            <Typography variant="h6" sx={{
                                                fontWeight: 'bold',
                                                paddingLeft: '15px',
                                                paddingRight: '15px',
                                            }}>
                                                Descrição
                                            </Typography>
                                            <Typography sx={{
                                                marginTop: '7px',
                                                marginBottom: '15px',
                                                fontSize: size.x > 900 ? '1.2rem' : '1.0rem',
                                                fontWeight: 'normal',
                                                textAlign: 'justify',
                                                paddingLeft: '15px',
                                                paddingRight: '15px',
                                            }}>
                                                {produto.produto.descricao ? produto.produto.descricao : 'Não há descrição para este produto.'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                textAlign: 'center',
                                                alignItems: 'center',
                                                height: '100%',
                                                width: '100%',
                                                paddingLeft: '3%',
                                                paddingRight: '3%',
                                                borderLeft: size.x >= 900 ? '0.5px solid #C4C4C4' : null,
                                                borderTop: size.x < 900 ? '0.5px solid #C4C4C4' : null,
                                            }}>
                                                <CardMedia
                                                    component="img"
                                                    image={'https://devvokere.s3.us-east-2.amazonaws.com/' + produto?.empresa?.new_logo}
                                                    onError={onMediaFallback}
                                                    sx={{
                                                        height: size.x > 900 ? '140px' : size.x > 600 ? '120px' : '100px',
                                                        width: size.x > 900 ? '140px' : size.x > 600 ? '120px' : '100px',
                                                        borderRadius: '5px',
                                                        marginBottom: size.x < 900 ? '15px' : null,
                                                        marginTop: size.x < 900 ? '15px' : null,
                                                        objectFit: (produto?.empresa === null || !produto?.empresa) ? 'contain' : 'cover',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        setImage('https://devvokere.s3.us-east-2.amazonaws.com/' + produto?.empresa?.new_logo);
                                                        handleOpenImage();
                                                    }}
                                                />
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%',
                                                    paddingLeft: '15px',
                                                    paddingRight: '15px',
                                                    alignItems: 'start',
                                                    justifyContent: 'space-between',
                                                    textAlign: 'start',
                                                }}>
                                                    <Box sx={{
                                                        marginBottom: '15px',
                                                    }}>
                                                        <Typography sx={{
                                                            fontSize: '1rem',
                                                        }}>
                                                            {produto?.empresa?.nome}
                                                        </Typography>
                                                        <Typography sx={{
                                                            fontSize: '0.95rem',
                                                        }}>
                                                            {produto?.empresa.aberta ? 'Aberta 🟢' : 'Fechada 🔴'}
                                                        </Typography>
                                                    </Box>
                                                    <Grid container spacing={1} sx={{
                                                        width: '100%',
                                                        alignItems: 'space-between',
                                                        textAlign: 'space-between',
                                                        justifyContent: 'space-between',
                                                    }}>
                                                        <Grid item xs={12} sm={12} md={12} lg={6}>
                                                            <Button sx={{
                                                                fontSize: size.x > 900 ? '0.9rem' : '0.7',
                                                            }} onClick={() => window.location.href = '/empresa/' + produto?.empresa.slug}>
                                                                Entrar na empresa
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={6}>
                                                            <CopyToClipboard text={`${urlWebSite}/empresa/${produto?.empresa.slug}`} onCopy={() => {
                                                                Notificacao('Sucesso!', `Link da empresa copiado para área de transferência!`, 'success');
                                                            }}>
                                                                <Button sx={{
                                                                    fontSize: size.x > 900 ? '0.9rem' : '0.7',
                                                                }}>
                                                                    Compartilhar link
                                                                </Button>
                                                            </CopyToClipboard>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Box>
                }
            </Box>
            <div style={{
                height: '80px',
            }}></div>
        </div>
    )
}

export default ProdutoDetail
