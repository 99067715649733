import { Box, Card, Grid, Typography } from "@mui/material"

import { NewEmpresaModel } from "../../../model/newEmpresas"

interface Props {
    onClose: Function,
    empresa: NewEmpresaModel,
}

const ModalHorarios = (props: Props) => {

    return (
        <Box sx={{
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            width: '100%',
        }}>
            <Grid container spacing={1} sx={{
                textAlign: 'center',
                alignItems: 'start',
                justifyContent: 'start',
                width: '100%',
            }}>
                {props.empresa.horarios.map((horario, index) => {
                    return (
                        <Grid item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            key={index}
                        >
                            <Card key={index} style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                justifyContent: 'space-between',
                                backgroundColor: '#fff',
                                borderRadius: '5px',
                            }} elevation={4}>
                                <Typography style={{
                                    marginRight: '30px',
                                    textAlign: 'center',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    fontSize: '1.1rem',
                                    marginBottom: '10px',
                                    width: '100%',
                                    backgroundColor: '#F9146B',
                                    paddingTop: '5px',
                                    paddingBottom: '5px',
                                }}>
                                    {horario.dia}
                                </Typography>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    padding: '10px',
                                }}>
                                    {
                                        horario.t1_ini && horario.t1_fim ?
                                            <>
                                                <Typography>
                                                    <strong style={{
                                                        marginTop: '10px',
                                                    }}>Abertura 1:</strong>  {horario.t1_ini}
                                                </Typography>
                                                <Typography>
                                                    <strong style={{
                                                        marginTop: '20px',
                                                    }}>Fechamento 1:</strong>  {horario.t1_ini}
                                                </Typography>
                                            </> :
                                            null
                                    }
                                    <div style={{
                                        height: '10px',
                                    }} />
                                    {
                                        horario.t2_ini && horario.t2_fim ?
                                            <>
                                                <Typography>
                                                    <strong style={{
                                                        marginTop: '10px',
                                                    }}>Abertura 2:</strong>  {horario.t2_ini}
                                                </Typography>
                                                <Typography>
                                                    <strong style={{
                                                        marginTop: '20px',
                                                    }}>Fechamento 2:</strong>  {horario.t2_ini}
                                                </Typography>
                                            </> :
                                            null
                                    }
                                    <div style={{
                                        height: '10px',
                                    }} />
                                    {
                                        horario.t3_ini && horario.t3_fim ?
                                            <>
                                                <Typography>
                                                    <strong style={{
                                                        marginTop: '10px',
                                                    }}>Abertura 3:</strong>  {horario.t3_ini}
                                                </Typography>
                                                <Typography>
                                                    <strong style={{
                                                        marginTop: '20px',
                                                    }}>Fechamento 3:</strong>  {horario.t3_ini}
                                                </Typography>
                                            </> :
                                            null
                                    }
                                </div>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}

export default ModalHorarios