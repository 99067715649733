import { CardMedia } from '@mui/material';

import FALLBACK_IMAGE from '../../../onerror.png';

interface Props {
    imagem: string,
    onClose: Function,
}

const OpenImage = (props: Props) => {

    const onMediaFallback = (event: any) => event.target.src = FALLBACK_IMAGE;

    return (
        <CardMedia
            component="img"
            image={props.imagem.includes('devvokere.s3') || props.imagem.includes('vkadm.vokere') ?
                props.imagem :
                `https://devvokere.s3.us-east-2.amazonaws.com/${props.imagem}`}
            onError={onMediaFallback}
            sx={{
                objectFit: 'contain',
            }}
        />
    )
}

export default OpenImage