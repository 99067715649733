import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'
import Slider from "react-slick";

import { ModalCustom } from '../../../../components/modal';
import Notificacao from '../../../../components/notification';
import { Root as BannerModel } from '../../model/banners.model';
import FALLBACK_IMAGE from '../../onerror.png';
import Promotion from '../modal/promocao/promotion';

import "../../../../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../../../../node_modules/slick-carousel/slick/slick.css";
import "./styles.css";

interface Props {
    banners: BannerModel[],
    setBannersFirst: (banner: BannerModel) => void,
    setOpenModalPromocoes: (open: boolean) => void,
    handleOpenModalPromocoes: () => void,
    handleCloseModalPromocoes: () => void,
    openModalPromocoes: boolean,
    bannerFirst: BannerModel,
    size: number,
    empresaHasRetirada: boolean,
    cidade_nome: string,
    convertIDempresaToSlug: (id: number) => string,
}

export function Carrocel(props: Props) {

    const onMediaFallback = (event: any) => event.target.src = FALLBACK_IMAGE;

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    function getPointers(index: number, length: number): JSX.Element[] {
        let pointers: JSX.Element[] = [];
        for(let i = 0; i < length; i++){
            pointers.push(
                <div
                    key={i}
                    className={i === index ? "dot active" : "dot"}
                    style={{
                        width: props.size > 700 ? '15px' : '10px',
                        height: props.size > 700 ? '15px' : '10px',
                        borderRadius: '50%',
                        margin: '0 5px',
                        backgroundColor: i === index ? '#F9146B' : '#c4c4c4',
                    }}
                />
            )
        }
        return pointers
    }

    return (
        <div className="container" style={{
            width: props.size > 700 ? '80%' : '95%',
            marginTop: props.banners.length > 0 ? '40px' : '5px',
            marginBottom: '30px',
        }}>
            <ModalCustom
                open={props.openModalPromocoes}
                title='Promoções'
                onClose={props.handleCloseModalPromocoes}
                actions={null}
                isMobile={props.size < 700}
                slug={''}
            >
                <Promotion
                    onClose={props.handleCloseModalPromocoes}
                    banner={props.bannerFirst!}
                    empresaHasRetirada={props.empresaHasRetirada}
                    cidade_nome={props.cidade_nome}
                    convertIDempresaToSlug={props.convertIDempresaToSlug}
                />
            </ModalCustom>
            <Card elevation={6} sx={{
                borderRadius: '12px',
            }}>
                <Slider {...settings}>
                    {
                        props.banners.map((banner, index) => {
                            return <Box
                                onClick={() => {
                                    Notificacao('Atenção!', `No momento não é possível realizar pedidos com promoções, baixe o APP para utilizar todos os recursos.`, 'danger');
                                    props.setBannersFirst(banner)
                                    props.setOpenModalPromocoes(true)
                                    props.handleOpenModalPromocoes()
                                }}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minWidth: '300px',
                                    cursor: 'pointer',
                                    backgroundColor: 'white',
                                }}>
                                <CardMedia
                                    component="img"
                                    image={banner.logo ? banner.logo : FALLBACK_IMAGE}
                                    alt={banner.name}
                                    onError={onMediaFallback}
                                    sx={{
                                        maxHeight: '230px',
                                        objectFit: 'cover',
                                    }}
                                />
                                <CardContent sx={{
                                    flexGrow: 1,
                                    display: 'flex',
                                    alignItems: 'start',
                                    textAlign: 'start',
                                    flexDirection: 'column',
                                    paddingLeft: props.size * 0.004,
                                    paddingRight: props.size * 0.004,
                                }}>
                                    <Typography gutterBottom variant="h5" component="h5" sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxLines: 1,
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                    }}>
                                        {banner.name}
                                    </Typography>
                                    <Typography sx={{
                                        fontSize: '14px',
                                    }}>
                                        {banner.description}
                                    </Typography>
                                </CardContent>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '30px',
                                    marginTop: '5px',
                                }}>
                                    {getPointers(index, props.banners.length)}
                                </Box>
                            </Box>
                        })
                    }
                </Slider>
            </Card>
        </div>
    );
}