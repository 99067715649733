import { getUrlAPIv2 } from '../api';
import { Root as ProdutoPesquisa } from '../model/pesquisaProduto.model';

const getProductsSearch = async (search: string, empresasId: number[], take: number): Promise<ProdutoPesquisa[] | null> => {

    let url = `${getUrlAPIv2}/produtos/searchProdutos`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                search: search,
                empresas: empresasId,
                take: take,
             })
        });
        if (response.status === 200) {
            const data = await response.json();
            return data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export default getProductsSearch;