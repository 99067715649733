import { Box, Button, Card, CardMedia, Link, Typography } from "@mui/material"
import { useEffect, useState } from "react";

import { ModalCustom } from "../../../../../components/modal";
import { convertBannerProductToProduct } from '../../../controllers/getBanners'
import { Root as Banner, Product } from "../../../model/banners.model"
import FALLBACK_IMAGE from '../../../onerror.png';
import ModalProdutos from "../produtos/modalProdutos";

interface Props {
    onClose: Function,
    banner: Banner,
    empresaHasRetirada: boolean,
    cidade_nome: string,
    convertIDempresaToSlug: (id: number) => string,
}

const Promotion = (props: Props) => {
    const startDate = new Date(props.banner.start_date).toLocaleDateString('pt-BR');
    const endDate = new Date(props.banner.end_date).toLocaleDateString('pt-BR');
    const onMediaFallback = (event: any) => event.target.src = FALLBACK_IMAGE;

    const [idProduto, setIdProduto] = useState(-1);
    const [open, setOpen] = useState(false);
    const [productPromotionsIndex, setProductPromotionsIndex] = useState(0);
    const [retiradaLocal, setRetiradaLocal] = useState(false);
    const [indexProd, setIndexProd] = useState(0);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [size, setSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight
    });
    const updateSize = () =>
        setSize({
            x: window.innerWidth,
            y: window.innerHeight
        });
    useEffect(() => (window.onresize = updateSize), []);

    function btnIrParaLoja(company_id: number) {
        return <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: '5px',
            width: '150px',
            marginBottom: '5px',
            marginRight: '20px',
        }}>
            <Link href={`/empresa/${props.convertIDempresaToSlug(company_id)}`} underline="none">
                <Box sx={{
                    color: '#fff',
                    padding: '0px',
                    backgroundColor: '#F9146B',
                    borderRadius: '5px',
                    textAlign: 'center',
                }}>
                    <Typography sx={{
                        padding: size.x > 700 ? '10px' : '5px',
                        borderRadius: '5px',
                        fontWeight: 'bold',
                        textDecoration: 'none',
                        fontSize: '0.8rem',
                    }}>
                        Ir para loja
                    </Typography>
                </Box>
            </Link>
        </div>;
    }

    function cardPrieceOfProduct(produto: Product): JSX.Element {
        return <Card elevation={1}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: size.x > 700 ? '10px' : '5px',
                color: '#fff',
                backgroundColor: '#F9146B',
                borderRadius: '5px',
            }}>
            <Typography id="modal-modal-title" variant="body1" component="h2" sx={{
                fontWeight: 'bold',
                marginBottom: '2px',
                size: size.x > 700 ? '15px' : '13px',
            }}>
                {produto.preco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </Typography>
        </Card>;
    }

    return (
        <Box sx={{
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'start',
        }}>
            <ModalCustom
                open={open}
                title={props.banner.product_promotions[productPromotionsIndex].products[indexProd].rotulo}
                onClose={handleClose}
                actions={null}
                isMobile={size.x < 700}
                slug={''}
            >
                <ModalProdutos
                    idProduto={idProduto}
                    empresaIsOpen={true}
                    onlyView={false}
                    produto={convertBannerProductToProduct(
                        props.banner.product_promotions[productPromotionsIndex].products[indexProd], 
                        retiradaLocal,
                        props.banner.id,
                        props.cidade_nome,
                        props.convertIDempresaToSlug(props.banner.product_promotions[productPromotionsIndex].products[indexProd].company_id),
                    )}
                    stateFunction={
                        () => {
                            setOpen(false)
                        }
                    }
                    isRetirada={props.empresaHasRetirada}
                    handleClose={handleClose}
                    isOnCarrinho={false}
                    cidade_nome={props.cidade_nome}
                    isPromotion={true}
                    slugEmpresa={props.convertIDempresaToSlug(props.banner.product_promotions[productPromotionsIndex].products[indexProd].company_id)}
                />
            </ModalCustom>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
                flexDirection: 'column',
                textAlign: 'start',
                alignItems: 'start',
                marginRight: '10px',
                marginLeft: '10px',
            }}>
                <CardMedia
                    component="img"
                    image={props.banner.logo}
                    alt="green iguana"
                />
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'start',
                    textAlign: 'start',
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{
                        fontWeight: 'bold',
                        marginTop: '30px',
                        fontSize: size.x ? '18px' : '14px',
                    }}>
                        {props.banner.name}
                    </Typography>
                    <Typography id="modal-modal-title" variant="body1" component="h2">
                        {props.banner.description}
                    </Typography>
                    <Typography id="modal-modal-title" variant="body1" component="h2">
                        Válida de: {startDate} - {props.banner.start_time} até {endDate} - {props.banner.end_time}
                    </Typography>
                </div>
            </div>
            <div style={{
                marginRight: '10px',
                marginLeft: '10px',
            }}>
                <Typography sx={{
                    fontWeight: 'bold',
                    marginTop: '30px',
                    fontSize: size.x ? '18px' : '14px',
                }}>
                    Produtos promocionais
                </Typography>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '20px',
                }}>

                    {props.banner.product_promotions.map((empresa, indexProdPromoction) => (
                        <Card elevation={5} key={indexProdPromoction}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '5px',
                                borderRadius: '5px',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'row',
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}>
                                        <img src={empresa.company_logo ? empresa.company_logo : FALLBACK_IMAGE}
                                            alt="green iguana" style={{
                                                objectFit: 'contain',
                                                padding: '0px',
                                                width: size.x > 700 ? '35%' : '30%',
                                                maxWidth: '130px',
                                                marginRight: '10px',
                                            }}
                                            onError={onMediaFallback}
                                        />
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                            }}>
                                                <Typography id="modal-modal-title" variant="body1" component="h2" sx={{
                                                    fontWeight: 'bold',
                                                    marginBottom: '2px',
                                                    fontSize: size.x > 700 ? '16px' : '14px',
                                                }}>
                                                    {empresa.company_name}
                                                </Typography>
                                                <Typography sx={{
                                                    fontSize: size.x > 700 ? '15px' : '13px',
                                                }}>
                                                    {empresa.aberta > 0 ? 'Aberta 🟢' : 'Fechada 🔴'}
                                                </Typography>
                                            </div>
                                            <Typography sx={{
                                                margingBottom: '5px',
                                                fontSize: size.x > 700 ? '15px' : '13px',
                                            }}>
                                                {empresa.products.length} Itens
                                            </Typography>
                                            {size.x > 700 ? null : btnIrParaLoja(empresa.products[0].company_id)}
                                        </div>

                                    </div>
                                    {size.x > 700 ? btnIrParaLoja(empresa.products[0].company_id) : null}

                                </Box>

                                {empresa.products.map((produto, indexProducts) => (
                                    <Button
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            marginTop: '10px',
                                            backgroundColor: '#f0f0f0',
                                            color: '#000',
                                            '&:hover': {
                                                backgroundColor: '#fff',
                                            }
                                        }}
                                        onClick={() => {
                                            setProductPromotionsIndex(indexProdPromoction)
                                            setIndexProd(indexProducts)
                                            setIdProduto(produto.id)
                                            setRetiradaLocal(empresa.retirar_local === 1)
                                            setOpen(true)
                                            handleOpen();
                                        }}>
                                        {
                                            size.x > 700 ? <div style={{
                                                marginRight: '10px',
                                            }}>
                                                {cardPrieceOfProduct(produto)}
                                            </div> : null
                                        }
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            alignItems: 'start',
                                        }}>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'start',
                                                alignItems: 'start',
                                                textAlign: 'start',
                                            }}>
                                                <Typography id="modal-modal-title" variant="body1" component="h2" sx={{
                                                    fontWeight: 'bold',
                                                    marginBottom: '2px',
                                                    fontSize: size.x > 700 ? '18x' : '14px',
                                                }}>
                                                    {produto.rotulo}
                                                </Typography>
                                                <Typography id="modal-modal-title" variant="body1" component="h2" sx={{
                                                    marginBottom: '2px',
                                                    justifyContent: 'justify',
                                                    alignItems: 'start',
                                                    fontSize: size.x > 700 ? '15px' : '12px',
                                                }}>
                                                    {produto.descricao}
                                                </Typography>
                                                {
                                                    size.x < 700 ? <div style={{
                                                        marginTop: '5px',
                                                        marginBottom: '3px',
                                                    }}>
                                                        {cardPrieceOfProduct(produto)}
                                                    </div> : null
                                                }
                                            </Box>
                                        </Box>
                                    </Button>
                                ))}
                            </Box>
                        </Card>
                    ))}
                </div>
            </div>
        </Box>
    )
}

export default Promotion