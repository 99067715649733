import { getUrlAPIv1 } from "../api";

export interface Bairro {
    id: number
    nome: string
}

const getBairros = async (id: number): Promise<Bairro[] | null> => {

    try {
        let url = `${getUrlAPIv1}/bairros/bairro/cidade/${id}`;
        const response = await fetch(url);
        if (response.status === 200) {
            const data = await response.json();
            return data;
        }
        return null
    } catch (error) {
        console.log(error);
        return null;
    }

};

export default getBairros;