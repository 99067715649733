//MUI
import { Grid } from '@mui/material'

import CityPage from './subpage/city'

const Empresas = () => {
  return (
    <div>
      <Grid>
        <div className='mt-10 mx-0'>
            <CityPage/>
        </div>
      </Grid>
    </div>
  )
}

export default Empresas
