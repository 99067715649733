import { Box, Button, Typography } from "@mui/material"

interface Props {
    onClose: Function,
}

const ModalInfoCode = (props: Props) => {

    return (
        <Box sx={{
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        }}>
                <Typography sx={{
                    textAlign: 'justify',
                    margin: '10px',
                    whiteSpace: "pre-wrap",
                }}>
                    {
                     `Ao realizar um pedido conosco, você recebeu um código de 4 dígitos. Este código garante a segurança do pedido. Por favor, não compartilhe esse código com o entregador até que você tenha recebido seu pedido.`
                    }
                </Typography>
        </Box>
    )
}

export default ModalInfoCode