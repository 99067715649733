import { getUrlAPIv1 } from '../api';
import { Root as Produto } from '../model/produto.model';
import { ProdutoGrouped } from '../model/produtoGrouped';

const getProducts = async (empresaId: number): Promise<Produto[] | null> => {
    
    let url = `${getUrlAPIv1}/produtos/empresa/${empresaId}`;
    try {
        const response = await fetch ( url ) ;
        if (response.status === 200) {
            const data = await response.json ( );
            return data.data ;
        }
        return null;
    } catch ( error ) {
        console.log ( error ) ;
        return null ;
    }
};

const getProductsGrouped = async (empresaId: number): Promise<ProdutoGrouped[] | null> => {
    
    let url = `${getUrlAPIv1}/produtos/empresa/${empresaId}/grouped`;
    try {
        const response = await fetch ( url ) ;
        if (response.status === 200) {
            const data = await response.json ( );
            let produtosGrouped: ProdutoGrouped[] = [];

            for (let valor in data.data) {
                const produtoGrouped: ProdutoGrouped = {
                    nome: valor,
                    produto: data.data[valor]
                }
                produtosGrouped.push(produtoGrouped);
            }

            return produtosGrouped
        }
        return null;
    } catch ( error ) {
        console.log ( error ) ;
        return null ;
    }
};

export {getProducts, getProductsGrouped};